import { Switch, Table } from "antd";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FlapperSpinner } from "react-spinners-kit";
import { infoBlue, searchCollection } from "../../../core/assets/icons";
import { ErrorToast, SuccessToast } from "../../componets/toast";
import { activeService, useAllService } from "../auth/hooks/useUser";

const ServiceActivation = () => {
  const navigate = useNavigate();
  const { isLoading, data: allService, mutate } = useAllService();
  const [activationLoading, setActivationLoading] = useState<boolean>(false);
  const [revenueCode, setRevenueCode] = useState<string>("");
  const [currentRecord, setCurrentRecord] = useState<any>({});
  const [wModal, setWModal] = useState<number>(1);
  const [showConfirm, setConfrim] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [serviceModal, setServiceModal] = useState<boolean>(false);

  useEffect(() => {
    mutate();
  }, []);

  // const { data: bankPayment, mutate: bankMutate } = useActiveService();
  const onChange = (record: any) => {
    setCurrentRecord(record);
    if (record?.revenueCode) {
      setRevenueCode(record?.revenueCode);
    } else {
      setRevenueCode("");
    }

    if (record?.active) {
      setWModal(2);
    } else {
      setWModal(1);
    }
    setConfrim(true);
  };

  const filteredData = allService?.filter(
    (item: any) =>
      item?.id?.toLowerCase().includes(search?.toLowerCase()) ||
      item?.orgName?.toLowerCase().includes(search?.toLowerCase()) ||
      item?.revCode?.toLowerCase().includes(search?.toLowerCase()) ||
      item?.serviceType?.description
        ?.toLowerCase()
        .includes(search?.toLowerCase())
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const serviceDetails = () => {};

  const dataSource: any[] = [];
  for (let i = 0; i <= filteredData?.length; i++) {
    // console.log(filteredData);

    if (filteredData[i]) {
      const datad = {
        key: i.toString(),
        id: filteredData[i].id,
        sn: i + 1,
        mdaName: filteredData[i]?.orgName,
        service: filteredData[i]?.description,
        orgId: filteredData[i]?.orgId,
        active: filteredData[i]?.active,
        revenueCode: filteredData[i]?.revCode,
        allData: filteredData[i],
      };
      dataSource.push(datad);
    }
  }

  const columns = [
    {
      title: "S/N",
      dataIndex: "sn",
      key: "sn",
      width: "40",
      sorter: (a, b) => a.sn - b.sn,
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "MDA Name",
      dataIndex: "mdaName",
      key: "mdaName",
    },
    {
      title: "Revenue Code",
      dataIndex: "revenueCode",
      key: "revenueCode",
    },
    {
      title: "Service",
      dataIndex: "service",
      key: "service",
    },

    {
      title: "Activate Status",
      dataIndex: "status",
      render: (_, record: any) => (
        <Switch
          size={"small"}
          //bg-unchecked
          className={` d-flex center-all ${record?.active ? "bg-success" : ""}`}
          checked={record?.active}
          onChange={() => onChange(record)}
        />
      ),
    },

    {
      title: " ",
      dataIndex: "status",
      render: (_, record: any) => (
        <div
          onClick={() =>
            navigate(
              `/admin/oagf-overview/view-service/${record.orgId}/${record.id}`
            )
          }
        >
          <span className=" cursor-pointer text-success fw-bold fs-9">
            View Details
          </span>
        </div>
      ),
    },
  ];

  const cancelModal = () => {
    setConfrim(false);
    if (wModal === 2) {
      setWModal(1);
    } else {
      setRevenueCode("");
    }
  };

  const Submit = () => {
    setActivationLoading(true);

    const revCode = {
      revCode: revenueCode,
    };

    activeService(currentRecord?.id, currentRecord?.orgId, revCode)
      .then(() => {
        mutate();
        SuccessToast(
          `Service has been ${
            currentRecord?.active ? "deactivated" : "activated"
          } `
        );
        setConfrim(false);
      })
      .catch((err: any) => {
        ErrorToast("");
      })
      .finally(() => {
        setActivationLoading(false);
      });
  };

  const ConfrimModal = () => {
    return (
      <Modal
        dialogClassName="  m-auto px-5 modal-card"
        aria-hidden="true"
        show={showConfirm} //showConfirm
        onHide={() => setConfrim(false)}
        animation
        centered
        size="lg"
        backdrop={"static"}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="m-6 mx-11">
            {wModal === 1 ? (
              <>
                <div className="mt-1">
                  <div className="col-form-label input-height d-flex justify-content-center">
                    <span className=" fw-bolder fs-8e text-dark">
                      Revenue Code
                    </span>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <input
                      type="text"
                      placeholder="Please enter revenue code"
                      className={"no-outline ps-0 text-dark fs-8"}
                      value={revenueCode}
                      onChange={(e) => setRevenueCode(e.target.value)}
                      maxLength={50}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-around my-5">
                  <button
                    id="kt_password_cancel "
                    type="button"
                    className=" btn btn-white px-7 py-2 h-44 w-110 rounded-1 border-success "
                    onClick={() => cancelModal()}
                  >
                    <span className="indicator-label fs-base text-success ">
                      Back
                    </span>
                  </button>

                  <button
                    type="button"
                    className={` btn ${
                      revenueCode.length < 16 ? "btn-secondary" : "btn-success"
                    } h-44 w-110 rounded-1  center-all  `}
                    onClick={() => setWModal(2)}
                    disabled={revenueCode.length < 16}
                  >
                    <span className="indicator-label fs-base text-white ">
                      Proceed
                    </span>
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className=" d-flex flex-column align-items-center ">
                  <img src={infoBlue} alt="" className="w-50px h-50px my-5" />

                  <span className="text-center fs-base text-dark">
                    Are you sure you want to{" "}
                    {currentRecord?.active ? "deactivate" : "activate"} this
                    service
                  </span>
                </div>
                <div className="d-flex justify-content-around mt-10">
                  <button
                    id="kt_password_cancel "
                    type="button"
                    className="btn btn-danger px-7 py-2 text-alt-red h-44 w-110 center-all "
                    onClick={() => cancelModal()}
                  >
                    <span className="indicator-label fs-base ">Cancel</span>
                  </button>
                  <button
                    className=" btn btn-success h-44 w-110 center-all"
                    type="button"
                    onClick={() => Submit()}
                  >
                    <span className="indicator-label fs-base ">Submit</span>
                  </button>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  return (
    <>
      <>
        {isLoading || activationLoading ? (
          <div className="loading-container">
            <FlapperSpinner />
          </div>
        ) : (
          <div className={"adminBody"}>
            <div className={"breadcrumb"}>
              <div className="setup-administration">
                <span className="text-muted fs-8 fw-bold"> Overview</span>
              </div>
              <div>
                <svg
                  width="10"
                  height="13"
                  viewBox="0 0 10 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                    fill="#1F8505"
                  />
                </svg>
              </div>
              <div className="corporate-profile cursor-pointer">
                <span className="text-dark fs-8 fw-bolder">
                  Service Activation
                </span>
              </div>
            </div>

            <div className={"py-10 px-5 my-0 bg-white mt-10"}>
              <span className="fs-8 text-dark fw-bolder">
                Service Activation
              </span>
              <div className="form-control form-control-solid pe-1 collection-serach ">
                <input
                  type="text"
                  placeholder="Search by Id, MDA Name, Revenue Code, Service"
                  className={"no-outline text-muted fs-8 w-90"}
                  value={search}
                  onChange={handleSearchChange}
                />

                <img
                  src={searchCollection}
                  alt="search"
                  className="icon-style"
                />
              </div>

              <div>
                <Table dataSource={dataSource} columns={columns} />
              </div>
            </div>

            {ConfrimModal()}
          </div>
        )}
      </>
    </>
  );
};

export default ServiceActivation;
