import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { HashLink, NavHashLink } from 'react-router-hash-link';
import Multiselect from 'multiselect-react-dropdown';
import { NumericFormat } from 'react-number-format';
import { infoBlue, phone } from '../../../../core/assets/icons';
import { phoneRegExp } from '../../../../core/helpers/RegexHelpers';


import {
  useGetUsersDetails,
  useRequestOnboardingStepUserCreationDetails,
  useRequestOnboardingStepUserCreationDetailsNew,
} from '../hooks/useUser';

const Schema = Yup.object().shape({
  firstname: Yup.string().required('First Name is required').max(20, 'Text field must be at most 20 characters long'),
  // login: Yup.string().max(20, 'Text field must be at most 20 characters long')
  //   .required('Username is required')
  //   .matches(/^\S*$/, 'Username should not contain spaces'),
  lastname: Yup.string().required('Last Name is required').max(20, 'Text field must be at most 20 characters long'),
    phoneNumber: Yup.string()
    .required('Phone Number is required')
    .matches(phoneRegExp, 'Phone number is not valid'),
  email: Yup.string().required('Email is required').email('Invalid Email'),
});

const initialValues = {
  firstname: '',
  lastname: '',
  phoneNumber: '',
  email: '',
  login: '',
};

interface Step1Props {
  handleNextStep: () => void;
  handlePreviousStep: () => void;
  setFormData2: React.Dispatch<React.SetStateAction<any>>;
  setTable2: React.Dispatch<React.SetStateAction<any>>;
  setStepTable2: React.Dispatch<React.SetStateAction<any>>;
  stepTable2: any;
  userData: any
}

const StepFive: React.FC<Step1Props> = ({
  handleNextStep,
  handlePreviousStep,
  setFormData2,
  setTable2,
  setStepTable2,
  stepTable2,
  userData
}) => {
  const [proceed, setProceed] = useState<Boolean>(false);

  const formRef = useRef<any>(null);

  const { data, mutate, isLoading } =
    useRequestOnboardingStepUserCreationDetailsNew();



  useEffect(() => {
    if (data) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
      formik.resetForm();
      setProceed(true);

      //  mutateBankDetails()
    }
  }, [mutate, data]);

  const onNext = () => {
setShow(false)
    
    if (userData?.length < 3) {
      console.log("You need to create at leasdt three users to proceed");
      setShowError(true)
      
    } else {
     
      
       handleNextStep();
    }
    
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values) => {
      setConfrim(false)
      mutate(
        {
          firstName: values.firstname,
          lastName: values.lastname,
          email: values.email,
          password: '12345',
          phoneNumber: values.phoneNumber,
          login: values.email,

        },

        {
          onSuccess: (res: any) => {
            setShow(true);
            setTable2(true);
            formik.resetForm();
            setStepTable2(stepTable2 + 1);
          },
        }
      );
    },
  });

  const [show, setShow] = useState<boolean>(false);

  const SuccessModal = () => {
    return (
      <Modal
        dialogClassName=" m-auto px-7 modal-card-success"
        aria-hidden="true"
        show={show}
        onHide={() => setShow(false)}
        animation
        centered
        size="lg"
        backdrop={'static'}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="">
            <div className=" d-flex flex-column align-items-center ">
              <div className="mt-5 mb-3">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.1562 12.1875L13.2812 18.75L9.84375 15.4688M26.25 15C26.25 21.2132 21.2132 26.25 15 26.25C8.7868 26.25 3.75 21.2132 3.75 15C3.75 8.7868 8.7868 3.75 15 3.75C21.2132 3.75 26.25 8.7868 26.25 15Z"
                    stroke="#61CD85"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <div className="email-verification-container mb-3">
                <span className="email-verification-text">Successful!</span>
              </div>

              <div className="email-verification-container mb-5">
                <span className="email-verification-body">
                  You have successfully added a new user to your organisation.
                  To add more users, please click on the “add more” button
                </span>
              </div>
            </div>

            <div className=" d-flex justify-content-between align-items-center mt-3 pe-9 ps-9 ">
              <button
                className="otp-button-addmore"
                onClick={() => setShow(false)}
              >
                <span className="">Add more</span>
              </button>

              <button className="otp-button " onClick={() => onNext()}>
                <span className="otp-button-text">Proceed</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const [showError, setShowError] = useState<boolean>(false);

    const ErrorModal = () => {
    return (
      <Modal
        dialogClassName=" m-auto px-7 modal-card-success"
        aria-hidden="true"
        show={showError}
        onHide={() => setShowError(false)}
        animation
        centered
        size="lg"
        backdrop={'static'}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="">
            <div className=" d-flex flex-column align-items-center ">
              <div className="mt-5 mb-3">
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z" fill="#FFE4E6"/>
<path d="M16.7626 15L20.3001 18.5375C20.5049 18.7766 20.6119 19.0842 20.5998 19.3988C20.5876 19.7134 20.4572 20.0118 20.2346 20.2344C20.0119 20.4571 19.7135 20.5875 19.3989 20.5996C19.0843 20.6118 18.7767 20.5048 18.5376 20.3L15.0001 16.7625L11.4626 20.3C11.3506 20.4308 11.2127 20.5371 11.0576 20.6121C10.9025 20.6872 10.7336 20.7293 10.5614 20.736C10.3893 20.7426 10.2176 20.7136 10.0572 20.6508C9.8968 20.5879 9.75112 20.4926 9.6293 20.3708C9.50748 20.249 9.41216 20.1033 9.34932 19.9429C9.28647 19.7825 9.25747 19.6108 9.26412 19.4387C9.27076 19.2665 9.31293 19.0976 9.38796 18.9425C9.46299 18.7874 9.56926 18.6495 9.70011 18.5375L13.2376 15L9.70011 11.4625C9.49533 11.2234 9.38832 10.9158 9.40047 10.6012C9.41263 10.2866 9.54304 9.98815 9.76566 9.76553C9.98828 9.54291 10.2867 9.4125 10.6013 9.40035C10.9159 9.38819 11.2235 9.4952 11.4626 9.69999L15.0001 13.2375L18.5376 9.69999C18.7767 9.4952 19.0843 9.38819 19.3989 9.40035C19.7135 9.4125 20.0119 9.54291 20.2346 9.76553C20.4572 9.98815 20.5876 10.2866 20.5998 10.6012C20.6119 10.9158 20.5049 11.2234 20.3001 11.4625L16.7626 15Z" fill="#E11D48"/>
</svg>

              </div>

              <div className="email-verification-container mb-3">
                <span className="email-verification-text">Error!</span>
              </div>

              <div className="email-verification-container mb-5">
                <span className="email-verification-body">
                  You have to add at least 3 users to your organisation.
                  To add more users, please click on the “add more” button
                </span>
              </div>
            </div>

            <div className=" d-flex justify-content-between align-items-center mt-3 pe-9 ps-9 ">
              <button
                className="otp-button-addmore"
                onClick={() => setShowError(false)}
              >
                <span className="">Add more</span>
              </button>


            </div>

            
          </div>
        </Modal.Body>
      </Modal>
    );
    };
  
          const [showConfirm, setConfrim] = useState<any>(false);


      const confrimModal = () => {
    return (
      <Modal
        dialogClassName="  m-auto px-5 modal-card"
        aria-hidden="true"
        show={showConfirm}
        onHide={() => setConfrim(false)}
        animation
        centered
        size="lg"
        backdrop={"static"}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <>
              <div className="m-3 mx-15">
                <div className=" d-flex flex-column align-items-center ">
                  <img src={infoBlue} alt="" className="w-50px h-50px my-5" />

                  <span className="text-center fs-base text-dark">
                    Are you sure you want to submit?
                  </span>
                </div>
                <div className="d-flex justify-content-between mt-10">
                  <button
                    id="kt_password_cancel "
                    type="button"
                    className="btn btn-danger px-7 py-2 text-alt-red h-44 w-110 center-all "
                    onClick={() => setConfrim(false)}
                  >
                    <span className="indicator-label fs-base ">Cancel</span>
                  </button>
                  <button
                    className=" btn btn-success h-44 w-110 center-all"
                    type="button"
                    onClick={() => formik.handleSubmit()}
                  >
                    <span className="indicator-label fs-base ">Submit</span>
                  </button>
                </div>
              </div>

         
          </>
        </Modal.Body>
      </Modal>
    );
  };


  return (
    <>
      <div>
        <div>
          <div>
            <div>
              <div className="user-creation-header mb-3">User Details</div>
              <div className="user-creation-text mb-9">
                This section requires you to specify the details of users that
                wil operate on this profile
              </div>
            </div>
          </div>

            <div ref={formRef} className="row">
              <div className="col-lg-4 col-form-label py-0 input-height">
                {formik.values.firstname.length !== 0 && (
                  <label className="required fw-bold fs-9">First Name</label>
                )}
              </div>

              <div className="form-control form-control-solid  ">
                <input
                  type="text"
                  placeholder="First Name *  "
                  className={'no-outline ps-4 text-dark fs-8'}
                  {...formik.getFieldProps('firstname')}
                />
              </div>

              <div className="fv-plugins-message-container input-height">
                <div className="fv-help-block fs-9" style={{ color: 'red' }}>
                  {formik.touched.firstname &&
                    formik.errors.firstname &&
                    formik.errors.firstname}
                </div>
              </div>
            </div>

            <div className="row mt-7">
              <div className="col-lg-4 col-form-label py-0 input-height">
                {formik.values.lastname.length !== 0 && (
                  <label className="required fw-bold fs-9">Last Name</label>
                )}
              </div>

              <div className="form-control form-control-solid  ">
                <input
                  type="text"
                  placeholder="Last Name *   "
                  className={'no-outline ps-4 text-dark fs-8'}
                  {...formik.getFieldProps('lastname')}
                />
              </div>

              <div className="fv-plugins-message-container input-height">
                <div className="fv-help-block fs-9" style={{ color: 'red' }}>
                  {formik.touched.lastname &&
                    formik.errors.lastname &&
                    formik.errors.lastname}
                </div>
              </div>
            </div>

            {/* <div className="row mt-7">
              <div className="col-lg-4 col-form-label py-0 input-height">
                {formik.values.login.length !== 0 && (
                  <label className="required fw-bold fs-9">User Name</label>
                )}
              </div>

              <div className="form-control form-control-solid  ">
                <input
                  type="text"
                  placeholder="User Name *  "
                  className={'no-outline ps-4 text-dark fs-8'}
                  {...formik.getFieldProps('login')}
                />
              </div>

              <div className="fv-plugins-message-container input-height">
                <div className="fv-help-block fs-9" style={{ color: 'red' }}>
                  {formik.touched.login &&
                    formik.errors.login &&
                    formik.errors.login}
                </div>
              </div>
            </div> */}

            {/* <div className="row mt-7">
              <div className="col-lg-4 col-form-label py-0 input-height">
                {formik.values.phoneNumber.length !== 0 && (
                  <label className="required fw-bold fs-9">Phone Number</label>
                )}
              </div>

              <div className="form-control form-control-solid  ">
                <input
                  type="number"
                  placeholder="Phone number * "
                  className={'no-outline ps-4 text-dark fs-8'}
                  {...formik.getFieldProps('phoneNumber')}
                />
              </div>

              <div className="fv-plugins-message-container input-height">
                <div className="fv-help-block fs-9" style={{ color: 'red' }}>
                  {formik.touched.phoneNumber &&
                    formik.errors.phoneNumber &&
                    formik.errors.phoneNumber}
                </div>
              </div>
            </div> */}


               <div className="row mt-7">
                <div className="col-lg-4 col-form-label py-0 input-height">
                  {formik.values.phoneNumber.length !== 0 && (
                    <label className="required fw-bold fs-9">
                      Phone Number
                    </label>
                  )}
                </div>

                <div className={`form-control form-control-solid `}>
                  <img src={phone} alt="fullName" className="icon-style" />

                  <NumericFormat
                    type="text"
                    className="no-outline ps-2 text-dark fs-8"
                    {...formik.getFieldProps('phoneNumber')}
                    placeholder=" Phone Number*"
                    allowNegative={false}
                    allowLeadingZeros={true}
                  />
                </div>

                <div
                  className="fv-plugins-message-container input-height"
                  style={{ color: 'red' }}
                >
                  <div className="fv-help-block fs-9">
                    {formik.touched.phoneNumber &&
                      formik.errors.phoneNumber &&
                      formik.errors.phoneNumber}
                  </div>
                </div>
              </div>

            <div className="row mt-7">
              <div className="col-lg-4 col-form-label py-0 input-height">
                {formik.values?.email.length !== 0 && (
                  <label className="required fw-bold fs-9">Official Email</label>
                )}
              </div>

              <div className="form-control form-control-solid  ">
                <input
                  type="text"
                  placeholder="Official Email * "
                  className={'no-outline ps-4 text-dark fs-8'}
                  {...formik.getFieldProps('email')}
                />
              </div>

              <div className="fv-plugins-message-container input-height">
                <div className="fv-help-block fs-9" style={{ color: 'red' }}>
                  {formik.touched.email &&
                    formik.errors.email &&
                    formik.errors.email}
                </div>
              </div>
            </div>

            {/* <div
            className="row mt-9 add-more-account-root"
            onClick={userHandleSubmit}
          >
            <span>
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  y="0.15686"
                  width="24"
                  height="24"
                  rx="4"
                  fill="#40A060"
                />
                <path
                  d="M5.75 11.9069H17.75M11.75 5.90686V17.9069"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="ms-6 ">
                <span className="add-more-account">
                  {userEditIndex === -1 ? '  Create more Users' : 'Update'}
                </span>
              </span>
            </span>
          </div> */}

            <button
              type="submit"
              // className="row mt-9 add-more-account-root user-btn"
              disabled={!(formik.isValid && formik.dirty)}
              className={`row mt-9  otp-button user-btn ${
                !(formik.isValid && formik.dirty)
                  ? 'add-more-account-root-cancle'
                  : 'add-more-account-root '
                } text-white px-7 py-2`}
                onClick={(e) => setConfrim(true)}
            >
              Save
            </button>

          <nav className={`nav navbar-fixed-signup-bottom `} id="nav">
            <div className="nav-center-signup">
              {/* Nav header */}
              <div className="nav-header-signup">
                <button
                  className="nav-btn-back nav-back-text"
                  id="nav-btn"
                  onClick={handlePreviousStep}
                >
                  <i className="fa fa-solid fa-arrow-left"></i>{' '}
                  <span style={{ marginLeft: '0.2rem' }}>back</span>
                </button>{' '}
                {/* <button className="nav-btn-Proceed" id="nav-btn">
                          Proceed
                        </button> */}
                <button
                  id="kt_password_cancel "
                  className={`btn nav-btn-Proceed ${
                    !proceed ? 'btn-secondary' : 'btn-success'
                  } text-white px-7 py-2`}
                  disabled={!proceed}
                  onClick={onNext}
                >
                  {!isLoading && (
                    <span className="indicator-label">Proceed</span>
                  )}
                  {isLoading && (
                    <span
                      className="indicator-progress"
                      style={{ display: 'block' }}
                    >
                      Please wait...{' '}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </nav>
        </div>
      </div>

      {SuccessModal()}
      {ErrorModal()}
      {confrimModal()}
    </>
  );
};

export { StepFive };
