import React, {useState} from 'react';
import '../../../../../../App.css';
import {Modal} from "antd";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";


const Schema = Yup.object().shape({
    fundingAccount: Yup.string().required('Funding Account is required'),
});

const initialValues = {
    fundingAccount: '',
    beneficiaryFirstName: '',
    beneficiaryLastName: '',
    beneficiaryTin: '',
    vat: '',
    taxAuthority: '',
    taxOffice: '',
    officeType: '',
    payPeriod: '',
};

const PayeSingleKeyIn = () => {
    const [successModal, setSuccessModal] = useState(false);


    const navigate = useNavigate();


    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: async (values) => {

        },
    });

    let fundingAccount = [
        {
            id: '',
            description: 'Account number',
        },
        {
            id: '1',
            description: 'First Bank of Nigeria PLC (099***5545)',
        },
    ];

    return (
        <>
            <div className={'vatPayment'}>
                <div className={'vatPaymentBreadcrumb'}>
                    <div>Payments</div>
                    <svg
                        width="10"
                        height="13"
                        viewBox="0 0 10 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                            fill="#1F8505"
                        />
                    </svg>
                    <div>Tax Payments</div>

                    <svg
                        width="10"
                        height="13"
                        viewBox="0 0 10 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                            fill="#1F8505"
                        />
                    </svg>
                    <div>Value Added Tax</div>

                    <svg
                        width="10"
                        height="13"
                        viewBox="0 0 10 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                            fill="#1F8505"
                        />
                    </svg>

                    <div>Key-In</div>
                </div>

                <div className={'vatSingleKeyIn'}>
                    <div className={'vatSingleKeyInRow1'}>
                        <div onClick={() =>  navigate("/admin/payment/tax-payments/vat")}>
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_7037_76293)">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M18 11.8125H9.63312L13.4763 7.96937L12.5 7L7 12.5L12.5 18L13.4694 17.0306L9.63312 13.1875H18V11.8125Z"
                                          fill="#1F8505"/>
                                </g>
                                <rect x="0.75" y="1.25" width="22.5" height="22.5" rx="11.25" stroke="#1F8505"
                                      stroke-width="1.5"/>
                                <defs>
                                    <clipPath id="clip0_7037_76293">
                                        <rect y="0.5" width="24" height="24" rx="12" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>

                        <div>Value Added Tax (VAT)</div>
                    </div>

                    <div className={'vatSingleKeyInForm'}>
                        <div className={'selectFundingDiv'}>
                            <div>Select funding account</div>

                            <div>
                                <div className={`mt-1`}>
                                    <div className={`form-control form-control-solid`}>
                                        <select
                                            className={`no-outline fs-8 ${
                                                formik.values.fundingAccount !== ''
                                                    ? 'text-dark'
                                                    : 'text-muted'
                                            } `}
                                            {...formik.getFieldProps('fundingAccount')}
                                        >
                                             <option value="">Select Account number</option>
                                            {fundingAccount &&
                                                fundingAccount?.map((array: any, i: any) => {
                                                    return (
                                                        <option value={array?.description} key={i}>
                                                            {array?.description}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                    </div>
                                </div>

                                <div></div>
                            </div>
                        </div>

                        <div className={'beneficiaryDetails'}>
                            <div>Beneficiary Details</div>

                            <div className={'detailsRow'}>
                                <div className="mt-1">
                                    <div className="col-form-label py-0 input-height">
                                        {formik.values.beneficiaryFirstName.length !== 0 && (
                                            <label className="required fw-bold fs-9">
                                                Beneficiary first name
                                            </label>
                                        )}
                                    </div>
                                    <div className={`form-control form-control-solid`}>
                                        <input
                                            type="text"
                                            placeholder="Beneficiary first name"
                                            className={"no-outline text-dark fs-8"}
                                            {...formik.getFieldProps("beneficiaryFirstName")}
                                        />
                                    </div>
                                    <div className="fv-plugins-message-container input-height">
                                        <div className="fv-help-block fs-9 text-danger">
                                            {formik.touched.beneficiaryFirstName &&
                                                formik.errors.beneficiaryFirstName &&
                                                formik.errors.beneficiaryFirstName}
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-1">
                                    <div className="col-form-label py-0 input-height">
                                        {formik.values.beneficiaryLastName.length !== 0 && (
                                            <label className="required fw-bold fs-9">
                                                Beneficiary last name
                                            </label>
                                        )}
                                    </div>
                                    <div className={`form-control form-control-solid`}>
                                        <input
                                            type="text"
                                            placeholder="Beneficiary last name"
                                            className={"no-outline text-dark fs-8"}
                                            {...formik.getFieldProps("beneficiaryLastName")}
                                        />
                                    </div>
                                    <div className="fv-plugins-message-container input-height">
                                        <div className="fv-help-block fs-9 text-danger">
                                            {formik.touched.beneficiaryLastName &&
                                                formik.errors.beneficiaryLastName &&
                                                formik.errors.beneficiaryLastName}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={'detailsRow'}>
                                <div className="mt-1">
                                    <div className="col-form-label py-0 input-height">
                                        {formik.values.beneficiaryTin.length !== 0 && (
                                            <label className="required fw-bold fs-9">
                                                Beneficiary TIN
                                            </label>
                                        )}
                                    </div>
                                    <div className={`form-control form-control-solid`}>
                                        <input
                                            type="text"
                                            placeholder="Beneficiary TIN"
                                            className={"no-outline text-dark fs-8"}
                                            {...formik.getFieldProps("beneficiaryTin")}
                                        />
                                    </div>
                                    <div className="fv-plugins-message-container input-height">
                                        <div className="fv-help-block fs-9 text-danger">
                                            {formik.touched.beneficiaryTin &&
                                                formik.errors.beneficiaryTin &&
                                                formik.errors.beneficiaryTin}
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-1">
                                    <div className="col-form-label py-0 input-height">
                                        {formik.values.vat.length !== 0 && (
                                            <label className="required fw-bold fs-9">
                                                VAT
                                            </label>
                                        )}
                                    </div>
                                    <div className={`form-control form-control-solid`}>
                                        <input
                                            type="text"
                                            placeholder="VAT"
                                            className={"no-outline text-dark fs-8"}
                                            {...formik.getFieldProps("vat")}
                                        />
                                    </div>
                                    <div className="fv-plugins-message-container input-height">
                                        <div className="fv-help-block fs-9 text-danger">
                                            {formik.touched.vat &&
                                                formik.errors.vat &&
                                                formik.errors.vat}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'beneficiaryDetails'}>
                            <div>Additional information</div>

                            <div className={'detailsRow'}>
                                <div className="mt-1">
                                    <div className="col-form-label py-0 input-height">
                                        {formik.values.taxAuthority.length !== 0 && (
                                            <label className="required fw-bold fs-9">
                                                Tax authority
                                            </label>
                                        )}
                                    </div>
                                    <div className={`form-control form-control-solid`}>
                                        <input
                                            type="text"
                                            placeholder="Tax authority"
                                            className={"no-outline text-dark fs-8"}
                                            {...formik.getFieldProps("taxAuthority")}
                                        />
                                    </div>
                                    <div className="fv-plugins-message-container input-height">
                                        <div className="fv-help-block fs-9 text-danger">
                                            {formik.touched.taxAuthority &&
                                                formik.errors.taxAuthority &&
                                                formik.errors.taxAuthority}
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-1">
                                    <div className="col-form-label py-0 input-height">
                                        {formik.values.taxOffice.length !== 0 && (
                                            <label className="required fw-bold fs-9">
                                                Tax office
                                            </label>
                                        )}
                                    </div>
                                    <div className={`form-control form-control-solid`}>
                                        <input
                                            type="text"
                                            placeholder="Tax office"
                                            className={"no-outline text-dark fs-8"}
                                            {...formik.getFieldProps("taxOffice")}
                                        />
                                    </div>
                                    <div className="fv-plugins-message-container input-height">
                                        <div className="fv-help-block fs-9 text-danger">
                                            {formik.touched.taxOffice &&
                                                formik.errors.taxOffice &&
                                                formik.errors.taxOffice}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={'detailsRow'}>
                                <div className="mt-1">
                                    <div className="col-lg-4 col-form-label py-0 input-height">
                                        {formik.values.officeType.length !== 0 && (
                                            <label className="required fw-bold fs-9">
                                                Office type
                                            </label>
                                        )}
                                    </div>
                                    <div className={`form-control form-control-solid`}>
                                        <input
                                            type="text"
                                            placeholder="Office type"
                                            className={"no-outline text-dark fs-8"}
                                            {...formik.getFieldProps("officeType")}
                                        />
                                    </div>
                                    <div className="fv-plugins-message-container input-height">
                                        <div className="fv-help-block fs-9 text-danger">
                                            {formik.touched.officeType &&
                                                formik.errors.officeType &&
                                                formik.errors.officeType}
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-1">
                                    <div className="col-form-label py-0 input-height">
                                        {formik.values.payPeriod.length !== 0 && (
                                            <label className="required fw-bold fs-9">
                                                Pay period
                                            </label>
                                        )}
                                    </div>
                                    <div className={`form-control form-control-solid`}>
                                        <input
                                            type="text"
                                            placeholder="Pay period"
                                            className={"no-outline text-dark fs-8"}
                                            {...formik.getFieldProps("payPeriod")}
                                        />
                                    </div>
                                    <div className="fv-plugins-message-container input-height">
                                        <div className="fv-help-block fs-9 text-danger">
                                            {formik.touched.payPeriod &&
                                                formik.errors.payPeriod &&
                                                formik.errors.payPeriod}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {formik.isValid && formik.dirty ? (
                        <div className={"vatSingleKeyInSubmitButton"}>
                            <button
                                onClick={() => setSuccessModal(true)}
                                style={{ background: "#1F8505" }}
                            >
                                Submit
                            </button>
                        </div>
                    ) : (
                        <div className={"vatSingleKeyInSubmitButton"}>
                            <button
                                disabled
                                onClick={() => setSuccessModal(false)}
                                style={{ background: "#CCCCCC" }}
                            >
                                Submit
                            </button>
                        </div>
                    )}
                </div>


                <Modal
                    centered
                    style={{ width: "500px", maxWidth: "100%" }}
                    open={successModal}
                    onOk={() => setSuccessModal(false)}
                    onCancel={() => setSuccessModal(false)}
                    footer={null}
                >
                    <div className={"ppSuccessModal"}>
                        <svg style={{ justifySelf: "center" }} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M33.5937 20.3125L22.1354 31.25L16.4062 25.7812M43.75 25C43.75 35.3553 35.3553 43.75 25 43.75C14.6447 43.75 6.25 35.3553 6.25 25C6.25 14.6447 14.6447 6.25 25 6.25C35.3553 6.25 43.75 14.6447 43.75 25Z" stroke="#FBCB38" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                        <div className={"ppSuccessModalTitle"}>Successful!</div>

                        <div className={'ppSuccessModalDesc'}>
                            Your transaction has been sent to the reviewer group for action.
                        </div>

                        <div className={"ppSuccessModalActionButton"}>
                            <button
                                onClick={() => {
                                    navigate("/admin/payment/tax-payments");
                                    setSuccessModal(false);
                                }}
                            >
                                Done
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default PayeSingleKeyIn;
