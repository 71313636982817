import { useEffect, useState } from "react";
import "../../../../App.css";
import { receipt } from "../../../../core/assets/imgs";
import { ErrorToast } from "../../../componets/toast";
import {
  getAllInvoiceInfo,
  // getInvoice,
  getPaymentDetails,
} from "../../payments/hooks/usePayment";
import moment from "moment";

const PrintInvoice = () => {
  const [refNo, setRefNo] = useState<any>("");
  const [findBy, setFindBy] = useState<string>("invoice");
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState<number>(1);
  const [paymentData, setPaymentData] = useState<any>([]);
  const [balanceDue, setBalanceDue] = useState<number>(0);

  useEffect(() => {
    let total: number = 0;

    paymentData?.paymentHistory?.map((item: any, index: any) => {
      if (item?.status.toLowerCase() === "paid") {
        total += item?.amountInfo?.amount;
      }
      return;
    });
    setBalanceDue(Number(total));
  }, [paymentData]);

  const handleSubmit = () => {
    setIsLoading(true);

    if (findBy === "invoice") {
      getAllInvoiceInfo(refNo)
        .then((response) => {
          setPaymentData(response);
          setStep(2);
        })
        .catch((err) => {
          if (err.response) {
            ErrorToast(err.response.statusText);
          } else {
            ErrorToast(err.message);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    if (findBy === "payment") {
      getPaymentDetails(refNo)
        .then((response) => {
          setPaymentData(response);
          setStep(2);
        })
        .catch((err) => {
          if (err.response) {
            ErrorToast(err.response.statusText);
          } else {
            ErrorToast(err.message);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const goBack = () => {
    setStep(1);
    setPaymentData([]);
    setRefNo("");
  };

  useEffect(() => {
    setRefNo("");
  }, [findBy]);

  const handleChange = (event: any) => {
    const { value } = event.target;
    // Limit the input length to 10 characters
    const limitedValue = value.slice(0, 10);
    setRefNo(limitedValue);
  };

  const [statusText, setStatusText] = useState<string>("");
  const [statusColor, setStatusColor] = useState<string>("");

  useEffect(() => {
    switch (paymentData?.status?.toLowerCase()) {
      case "new":
        setStatusText("Unpaid");
        setStatusColor("danger");
        break;
      case "paid":
        setStatusText("Paid");
        setStatusColor("success");
        break;
      case "partly_paid":
        setStatusText("Partly Paid");
        setStatusColor("pending");
        break;
      default:
        setStatusText("Unpaid");
        break;
    }
  }, [paymentData]);

  return (
    <>
      <div className={"businessPreference bg-admin-body"}>
        <div className={"breadcrumb"}>
          <div>Collections</div>
          <svg
            width="10"
            height="13"
            viewBox="0 0 10 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
              fill="#1F8505"
            />
          </svg>
          <div>Verify Receipt</div>
        </div>

        <div className="bg-white px-5 py-2">
          <div className="pb-10 pt-2 my-10">
            {step === 1 && (
              <div className="  m-auto p-11 responsive-card">
                {/* <span className="text-dark fs-3 fw-bolder  text-center">
                    Invoice & Receipt
                  </span> */}
                <div className="form align-middle  gx-0 gy-5 mt-5">
                  {/*<div className="d-flex  mt-1  col-12">*/}
                  {/*  <div*/}
                  {/*    className="row min-w-25  cursor-pointer col-6"*/}
                  {/*    onClick={() => {*/}
                  {/*      setFindBy("invoice");*/}
                  {/*    }}*/}
                  {/*  >*/}
                  {/*    <span className="form-check form-check-custom form-check-alt">*/}
                  {/*      <img*/}
                  {/*        src={`${*/}
                  {/*          findBy === "invoice" ? checkedFilled : checked*/}
                  {/*        }`}*/}
                  {/*        className="w-1r1 h-1r1"*/}
                  {/*      />*/}
                  {/*      <span className="text-dark fs-8 ms-2">*/}
                  {/*        Get by invoice id*/}
                  {/*      </span>*/}
                  {/*    </span>*/}
                  {/*  </div>*/}
                  {/*  <div*/}
                  {/*    className="row min-w-25  cursor-pointer col-6"*/}
                  {/*    onClick={() => {*/}
                  {/*      setFindBy("payment");*/}
                  {/*    }}*/}
                  {/*  >*/}
                  {/*    <span className="form-check form-check-custom form-check-alt">*/}
                  {/*      <img*/}
                  {/*        src={`${*/}
                  {/*          findBy === "payment" ? checkedFilled : checked*/}
                  {/*        }`}*/}
                  {/*        className="w-1r1 h-1r1"*/}
                  {/*      />*/}
                  {/*      <span className="text-dark fs-8 ms-2">*/}
                  {/*        Get by payment id*/}
                  {/*      </span>*/}
                  {/*    </span>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  <div className="mt-2">
                    <div className="">
                      <div className="col-lg-12 col-form-label py-0 input-height mb-1">
                        {refNo.length !== 0 && (
                          <label className="required fw-bold fs-8">
                            {/* Enter{" "}
                            {` ${findBy === "invoice" ? "invoice" : "payment"}`}{" "}
                            id */}
                            Enter GRR
                          </label>
                        )}
                      </div>

                      <div className={`form-control form-control-solid`}>
                        <img
                          src={receipt}
                          alt="invoice"
                          className="icon-style"
                        />

                        <input
                          type="number"
                          placeholder={`${
                            findBy === "invoice"
                              ? "Enter GRR"
                              : "Enter payment id"
                          }*`}
                          className={"no-outline ps-4 text-dark fs-8 w-90"}
                          // onChange={(e) => setRefNo(e.target.value)}
                          // maxLength={10}

                          onChange={handleChange}
                          value={refNo}
                        />
                      </div>

                      <div className="fv-plugins-message-container input-height my-3">
                        <div className="fv-help-block fs-8 text-danger">
                          {refNo.length === 0 &&
                            findBy === "invoice" &&
                            "Government Revenue Reference (GRR) is required"}
                          {refNo.length === 0 &&
                            findBy === "payment" &&
                            "Payment id number is required"}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" d-flex  align-items-center justify-content-center mt-10">
                    <button
                      id="kt_password_cancel "
                      type="submit"
                      className={`btn ${
                        refNo?.length < 10 || isLoading
                          ? "btn-secondary"
                          : "btn-success"
                      } text-white px-7 py-2 h-44 w-min-110 rounded-1`}
                      disabled={refNo?.length < 10}
                      onClick={handleSubmit}
                    >
                      {/* !isLoading */}
                      {!isLoading && (
                        <span className="indicator-label fs-base">
                          Check Record
                        </span>
                      )}
                      {/* isLoading */}
                      {isLoading && (
                        <span className="indicator-progress d-flex justify-content-center  align-items-center">
                          <span className="d-none d-md-block">
                            Please wait...{" "}
                          </span>
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            )}

            {step === 2 && (
              <>
                <div className=" m-auto p-11 responsive-card-large py-0">
                  <div className="d-flex justify-content-center align-items-center ">
                    <div className="d-flex flex-column justify-content-center align-items-center pb-3">
                      <span className="text-dark fs-8 lh-lg fw-bold  py-1 px-1 rounded-sm">
                        Government Revenue Reference (GRR)
                      </span>

                      <span className="text-success fs-8 lh-lg fw-bold bg-light-success py-1 px-1 rounded-sm">
                        {paymentData?.invoiceNumber}
                      </span>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center align-items-center ">
                    <div className="d-flex justify-content-center align-items-center ">
                      <span className="text-dark fs-5 fw-bolder lh-lg">
                        Payment Summary
                      </span>
                    </div>
                  </div>

                  <hr className="text-muted h-1px" />
                  <div>
                    <div className="d-flex justify-content-between py-3">
                      <span className="text-muted fs-8 fw-bold text-start min-w-25">
                        MDA
                      </span>
                      <span className="text-dark fs-8 fw-bold  overflow-hidden">
                        {paymentData?.orgName}
                      </span>
                    </div>

                    <div className="d-flex justify-content-between py-3">
                      <span className="text-muted fs-8 fw-bold text-start min-w-25">
                        Service
                      </span>
                      <span className="text-dark fs-8 fw-bold  overflow-hidden">
                        {paymentData?.serviceName}
                      </span>
                    </div>

                    {paymentData?.amountInfo?.description.length > 0 && (
                      <div className="d-flex justify-content-between py-3">
                        <span className="text-muted fs-8 fw-bold text-start min-w-25">
                          Description
                        </span>
                        <span className="text-dark fs-8 fw-bold  overflow-hidden">
                          {paymentData?.amountInfo?.description}
                        </span>
                      </div>
                    )}

                    <div className="d-flex justify-content-between py-3">
                      <span className="text-muted fs-8 fw-bold text-start min-w-25">
                        Full Name
                      </span>
                      <span className="text-dark fs-8 fw-bold  overflow-hidden">
                        {paymentData?.payer?.fullName}
                      </span>
                    </div>

                    <div className="d-flex justify-content-between py-3">
                      <span className="text-muted fs-8 fw-bold text-start min-w-25">
                        Email
                      </span>
                      <span className="text-dark fs-8 fw-bold  overflow-hidden">
                        {paymentData?.payer?.email}
                      </span>
                    </div>

                    <div className="d-flex justify-content-between py-3">
                      <span className="text-muted fs-8 fw-bold text-start min-w-25">
                        Phone Number
                      </span>
                      <span className="text-dark fs-8 fw-bold  overflow-hidden">
                        {paymentData?.payer?.phone}
                      </span>
                    </div>

                    {paymentData?.payer?.customValues?.map(
                      (customData: any, index: any) => {
                        if (
                          customData?.value === null &&
                          customData?.selectOptions?.length === 0
                        ) {
                          return;
                        }
                        return (
                          <div
                            className="d-flex justify-content-between py-3"
                            key={index}
                          >
                            <span className="text-muted fs-8 fw-bold text-start min-w-35">
                              {customData?.field}
                            </span>

                            {customData?.selectOptions?.length === 0 ? (
                              <span className="text-dark fs-8 fw-bold  overflow-hidden">
                                {customData?.value}
                              </span>
                            ) : (
                              <span className="text-dark fs-8 fw-bold  overflow-hidden">
                                {customData?.selectOptions
                                  ?.map((item: any) => item.label)
                                  .join(", ")}
                              </span>
                            )}
                          </div>
                        );
                      }
                    )}
                  </div>

                  <div className="d-flex justify-content-between py-3">
                    <span className="text-muted fs-8 fw-bold text-start min-w-25">
                      Status
                    </span>

                    {/* {paymentData?.invoice?.status.toLowerCase() === "paid" ? (
                      <span className="text-success fs-8 fw-bold text-end">
                        Paid
                      </span>
                    ) : (
                      <span className="text-danger fs-8 fw-bold text-end">
                        Not Paid
                      </span>
                    )} */}
                    <span
                      className={`text-${statusColor} fs-8 fw-bold text-end`}
                    >
                      {statusText}
                    </span>
                  </div>

                  <div className="d-flex justify-content-between py-3">
                    <span className="text-muted fs-8 fw-bold text-start min-w-25">
                      Service Delivered
                    </span>

                    {paymentData?.delivered ? (
                      <span className="text-success fs-8 fw-bold text-end">
                        Delivered
                      </span>
                    ) : (
                      <span className="text-danger fs-8 fw-bold text-end">
                        Not Delivered
                      </span>
                    )}
                  </div>

                  <div className="d-flex justify-content-between py-3">
                    <span className="text-muted fs-8 fw-bold text-start min-w-25">
                      Service Delivered By
                    </span>
                    <span className="text-dark fs-8 fw-bold text-end">
                      {paymentData?.validatorInfo?.fullName || "N/A"}
                    </span>
                  </div>

                  <div className="d-flex justify-content-between py-3">
                    <span className="text-muted fs-8 fw-bold text-start min-w-25">
                      MDA Location
                    </span>
                    <span className="text-dark fs-8 fw-bold text-end">
                      {paymentData?.validatorInfo?.orgLocation || "N/A"}
                    </span>
                  </div>


                    <div className="d-flex justify-content-between py-3">
                    <span className="text-muted fs-8 fw-bold text-start min-w-25">
                     Issue Date
                    </span>
                    <span className="text-dark fs-8 fw-bold text-end">
                      {/* {paymentData?.issueDate || "N/A"} */}

                     { moment(paymentData?.issueDate).format('MMMM Do YYYY, h:mm:ss a') || "N/A"}
                    </span>
                  </div>

                  {paymentData?.payer?.paymentComponentList?.length >= 1 &&
                    paymentData?.payer?.paymentComponentList[0]?.name !==
                      paymentData?.serviceName && (
                      <>
                        <hr className="text-muted h-1px" />
                        <div className="d-flex justify-content-between">
                          <span className="text-dark fs-8 fw-bolder text-start min-w-25">
                            Service Item(s)
                          </span>
                        </div>
                        {paymentData?.payer?.paymentComponentList?.map(
                          (customData: any, index: any) => {
                            if (paymentData?.serviceName === customData?.name) {
                              return;
                            }
                            return (
                              <div
                                className="d-flex justify-content-between pt-3"
                                key={index}
                              >
                                <span className="text-muted fs-9 fw-bold text-start min-w-25">
                                  {customData?.name}
                                </span>
                                <span className="text-dark fs-9 fw-bold ">
                                  N{customData?.amount.toLocaleString()}
                                </span>
                              </div>
                            );
                          }
                        )}
                      </>
                    )}

                  <div className="d-flex justify-content-between mt-3 py-3  border-gray-800 border-top-dashed ">
                    <span className="text-muted fs-9 fw-bold text-start min-w-25">
                      {paymentData?.payer?.paymentComponentList?.length > 1 ||
                      paymentData?.installPayment
                        ? "Total"
                        : ""}{" "}
                      Amount {paymentData?.installPayment ? "Payable" : ""}
                    </span>
                    <span className="text-dark fs-9 fw-bold  overflow-hidden">
                      N{Number(paymentData?.amount).toLocaleString()}
                    </span>
                  </div>

                  {paymentData?.installPayment && (
                    <>
                      <div className="d-flex justify-content-between py-3  border-gray-800 border-top-dashed ">
                        <span className="text-muted fs-9 fw-bold text-start min-w-25">
                          Instalment Paid
                        </span>
                        <span className="text-dark fs-9 fw-bold  overflow-hidden">
                          N{Number(balanceDue).toLocaleString()}
                        </span>
                      </div>

                      <div className="d-flex justify-content-between py-3  border-gray-800 border-top-dashed ">
                        <span className="text-muted fs-9 fw-bold text-start min-w-25">
                          Balance Due
                        </span>
                        <span className="text-dark fs-9 fw-bold  overflow-hidden">
                          N{Number(paymentData?.balanceDue).toLocaleString()}
                        </span>
                      </div>
                    </>
                  )}

                  <div className="d-flex   center-all mt-5">
                    <div>
                      <button
                        id="kt_password_cancel "
                        type="button"
                        className="btn btn-white px-7 py-2 h-44 w-110 rounded-1 border-success"
                        onClick={() => goBack()}
                      >
                        <span className="indicator-label fs-base text-success">
                          Back
                        </span>
                      </button>
                    </div>

                    {/* <div>
                      <button
                        disabled={
                          paymentData?.invoice?.status.toLowerCase() !== "paid"
                        }
                        className={`btn ${
                          paymentData?.invoice?.status.toLowerCase() !== "paid"
                            ? "btn-secondary"
                            : "btn-success"
                        } text-white px-7 py-2 h-44 w-min-110 rounded-1`}
                      >
                        <PDFDownloadLink
                          document={QuickRecipets(paymentData)}
                          fileName={`${refNo}.pdf`}
                        >
                          <span className="indicator-label fs-base text-white">
                            Download Receipt
                          </span>
                        </PDFDownloadLink>
                      </button>
                    </div> */}

                    {/* <div>
                    <button
                      className="btn btn-success text-white px-7 py-2 h-44 w-110 rounded-1"
                      onClick={() => setShow(true)}
                    >
                      <span className="indicator-label fs-base">Pay</span>
                    </button>
                  </div> */}
                  </div>
                </div>

                {/* {paymentData?.paymentHistory?.length > 0 && (
                  <div className="table-responsive  m-auto">
                    <table className="table align-middle gs-0  ">
                      <thead className="bg-white">
                        <tr>
                          <th className="min-w-70px text-center text-dark fs-10 bg-white border-0 fw-bold p-2 align-middle">
                            Amount Paid
                          </th>
                          <th className="min-w-70px text-center text-dark fs-10 bg-white border-0 fw-bold p-2 align-middle">
                            Payment Reference
                          </th>
                          <th className="min-w-70px text-center text-dark fs-10 bg-white border-0 fw-bold p-2 align-middle">
                            Date
                          </th>
                          <th className="min-w-70px text-center text-dark fs-10 bg-white border-0 fw-bold p-2 align-middle">
                            Balance Due
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {paymentData?.paymentHistory?.map(
                          (payData: any, index: any) => {
                            let balanceCurrentDue = 0;

                            if (payData.status.toLowerCase() === "new") {
                              return;
                            }
                            return (
                              <tr key={index}>
                                <td className="text-dark fs-10  text-center border-0 capitaliseText p-2">
                                  ₦
                                  {Number(
                                    payData?.amountInfo?.amount
                                  ).toLocaleString()}
                                </td>
                                <td className="text-dark fs-10  text-center capitaliseText border-0 p-2">
                                  {payData?.paymentReference}
                                </td>
                                <td className="text-dark fs-10  text-center border-0 p-2">
                                  {moment(payData?.paymentDate).format(
                                    "Do-MMMM-YYYY"
                                  )}
                                </td>
                                <td className="text-dark fs-10  text-center border-0 p-2">
                                  ₦{Number(payData?.currentBalanceDue).toLocaleString()}
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                )} */}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintInvoice;
