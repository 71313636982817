import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  MDAdirectory,
  arrowRight,
  arrowUpCircle,
  einvoice,
  playCircle,
  receiptgeneration,
  revenuepayment,
  welcomeImg,
} from "../../../core/assets/imgs";

import { Modal } from "react-bootstrap";
import Typewriter from "typewriter-effect";

const Hero: React.FC = () => {
  const [show, setShow] = useState<any>(false);
  const navigate = useNavigate();

  const done = () => {
    navigate("/auth/register");
  };
  const PageModal = () => {
    return (
      <Modal
        dialogClassName="home-modal"
        aria-hidden="true"
        show={show}
        onHide={() => setShow(false)}
        animation
        centered
        size="lg"
      >
        <div className=" d-flex flex-cloumn justify-content-center align-items-center w-536e h-326e bg-black rounded-1">
          <article className="w-100 h-100">
            <iframe
              className="rounded-1"
              width="100%"
              height="326"
              src="https://www.youtube-nocookie.com/embed/zH1HtWW_3-Q?rel=0&showinfo=0"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
              allowFullScreen
            ></iframe>
          </article>
        </div>
      </Modal>
    );
  };
  return (
    <div>
      <header className="hero">
        <div className="section-center hero-center">
          <div className="d-flex align-items-center  justify-content-center py-6  d-lg-none">
            <div className="welcome-body">
              <div className="getStarted-root  welcome-text d-flex align-items-center">
                <span className="welcome-text">
                  <Typewriter
                    onInit={(typewriter) => {
                      typewriter
                        .start()
                        .typeString(
                          "Welcome to the Federal Government of Nigeria Treasury Portal."
                        )
                        .pauseFor(1000)
                        .deleteAll(50)
                        .typeString(
                          "Supporting the Treasury in the management of functions related to the receipts and payments of the Federal Government and the Funds of the Federation."
                        )
                        .pauseFor(1000)
                        .deleteAll(50);
                    }}
                    options={{
                      cursor: "",
                      loop: true,
                      delay: 50,
                    }}
                  />
                </span>
              </div>
            </div>
          </div>

          {/* <div className="hero-info">
            <div className="quick-links">
              <Link to={"/revenue"}>
                <div className="img-div d-flex flex-column align-items-center  justify-content-center">
                  <img src={MDAdirectory} alt="MDAdirectory" />
                  <span>
                    Revenue Payments
                    <img
                      src={arrowUpCircle}
                      alt="MDAdirectory"
                      className="arrow-style ms-2"
                    />
                  </span>
                </div>
              </Link>
              <Link to={"/invoice"}>
                <div className="img-div d-flex flex-column align-items-center  justify-content-center">
                  <img src={receiptgeneration} alt="receiptgeneration" />
                  <span>
                    Electronic Invoice Payments
                    <img
                      src={arrowUpCircle}
                      alt="MDAdirectory"
                      className="arrow-style ms-2"
                    />
                  </span>
                </div>
              </Link>
              <Link to={"/mda-directory"}>
                <div className="img-div d-flex flex-column align-items-center  justify-content-center">
                  <img src={revenuepayment} alt="revenuepayment" />
                  <span>
                    MDA Directory
                    <img
                      src={arrowUpCircle}
                      alt="MDAdirectory"
                      className="arrow-style ms-2"
                    />
                  </span>
                </div>
              </Link>

              <Link to={"/reciepts"}>
                <div className="img-div d-flex flex-column align-items-center  justify-content-center">
                  <img src={einvoice} alt="einvoice" />
                  <span>
                    e-Invoice/Receipt Generation
                    <img
                      src={arrowUpCircle}
                      alt="MDAdirectory"
                      className="arrow-style ms-2"
                    />
                  </span>
                </div>
              </Link>
            </div>
          </div> */}

          <div className="d-flex align-items-center  justify-content-center py-6  ">
            <div className="welcome-body">
              <div className="getStarted-root  welcome-text d-flex align-items-center">
                <span className="welcome-text d-none d-lg-block">
                  <Typewriter
                    onInit={(typewriter) => {
                      typewriter
                        .start()
                        .typeString(
                          "Welcome to the Federal Government of Nigeria Treasury Portal."
                        )
                        .pauseFor(1000)
                        .deleteAll(50)
                        .typeString(
                          "Supporting the Treasury in the payment request, collection, and reconciliation for the Federal Government."
                        )
                        .pauseFor(1000)
                        .deleteAll(50);
                    }}
                    options={{
                      cursor: "",
                      loop: true,
                      delay: 50,
                    }}
                  />
                </span>
              </div>
              
         
            </div>
            <div className="hero-img">
              <img src={welcomeImg} className="hero-photo" alt="TMS" />
            </div>
          </div>

          <div className="section-center d-flex-alt align-items-center  justify-content-center">
            <button
              className="btn btn-white border-success rounded-1 home-btn mx-8 my-3"
              onClick={() => setShow(true)}
            >
              <span className="fs-6 text-success ">
                Learn More
                <img
                  src={playCircle}
                  className="icon-style ms-2"
                  alt="arrowRight"
                />
              </span>
            </button>

            <button
              className="btn btn-success rounded-1 home-btn  mx-8 my-3"
              onClick={done}
            >
              <span className="fs-6">
                Get Started
                <img
                  src={arrowRight}
                  className="icon-style ms-2"
                  alt="arrowRight"
                />
              </span>
            </button>
          </div>
        </div>
      </header>

      {PageModal()}
    </div>
  );
};

export { Hero };
