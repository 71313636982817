import ExcelJS from "exceljs";

export function convertToExcel(excelData: any, fileTitle: string, pageTitle?: string) {
    const workbook: ExcelJS.Workbook = new ExcelJS.Workbook();

    const worksheet: ExcelJS.Worksheet = workbook.addWorksheet(pageTitle || 'Sheet 1');

    console.log({excelData})

    const modifiedData = excelData.map((row: any[]) => row.map(item => (item || '').toString().startsWith('0') ? "'" + item : item));

    worksheet.addRows(modifiedData);

    workbook.xlsx.writeBuffer().then((buffer: ExcelJS.Buffer): void => {
        saveAsExcelFile(buffer, `${fileTitle}.xlsx`);
    });
}

export function convertToCSV(excelData: any, fileTitle: string, pageTitle?: string) {
    const workbook: ExcelJS.Workbook = new ExcelJS.Workbook();

    const worksheet: ExcelJS.Worksheet = workbook.addWorksheet(pageTitle || 'Sheet 1');

    console.log({excelData})

    const modifiedData = excelData.map((row: any[]) => row.map(item => (item || '').toString().startsWith('0') ? "'" + item : item));

    worksheet.addRows(modifiedData);

    workbook.csv.writeBuffer().then((buffer: ExcelJS.Buffer): void => {
        saveAsExcelFile(buffer, `${fileTitle}.csv`);
    });
}

function saveAsExcelFile(buffer, fileName): void {
    const data: Blob = new Blob([buffer], { type: "application/octet-stream" });
    const url: string = URL.createObjectURL(data);
    const link: HTMLAnchorElement = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.click();
    setTimeout(() => {
        URL.revokeObjectURL(url);
    }, 100);
}

