import { DatePicker, Select, Table, Tag } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FlapperSpinner } from "react-spinners-kit";
import "../../../App.css";
import { filter, goBack } from "../../../core/assets/icons";
import { useGetAllMda, useGetAllMdaFilter } from "../payments/hooks/usePayment";
import { Layout } from "./layout";
import moment from "moment";

const dateFormat = "YYYY/MM/DD";
const MdaDirectory = () => {
  // const { mdaList, isLoading } = useGetAllMda();

  const navigate = useNavigate();
  const [allData, setAllData] = useState<any>([]);

  const {
    data: mdaList,
    mutate: mdaMutate,
    isLoading: isLoading,
  } = useGetAllMdaFilter();

  const {
    data: mdaListAll,
    mutate: mdaMutateAll,
    isLoading: isLoadingAll,
  } = useGetAllMda();

  useEffect(() => {
    mdaMutate();
    mdaMutateAll();
  }, []);

  useEffect(() => {
    setAllData(mdaListAll);
  }, [mdaListAll]);

  // console.log(mdaList);
  //new

  // navigate("/revenue", {
  //     state: {
  //       ...proceedData,
  //       mdaName: proceedData?.mdaName,
  //       mdaId: proceedData?.mdaId,
  //       serviceType: proceedData?.serviceType,
  //       serviceId: proceedData?.serviceId,
  //       serviceName: proceedData?.serviceName,
  //       amount: proceedData?.amount,
  //       customFields: proceedData?.customFields,
  //       payComps: proceedData?.paymentComponents,
  //     },
  //   });

  const dataSource: any[] = [];

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: "30",
    },
    {
      title: "MDA Name",
      dataIndex: "mdaName",
      key: "mdaName",
      width: "180",
    },
    {
      title: "MDA Code",
      dataIndex: "mdaAdminCode",
      key: "mdaAdminCode",
      width: "60",
    },
    {
      title: "MDA Sector",
      dataIndex: "sector",
      key: "sector",
      width: "60",
    },
    {
      title: "Initiation Date",
      dataIndex: "initiationDate",
      key: "initiationDate",
      width: "80",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      width: "42",
    },
    {
      title: "Display Status",
      dataIndex: "displayStatus",
      key: "displayStatus",
      width: "100",
      render: (displayStatus) => {
        switch (displayStatus) {
          case false: {
            return (
              <Tag
                color="#F2F2F2"
                style={{ color: "#666666" }}
                key={displayStatus}
              >
                InActive
              </Tag>
            );
          }
          case true: {
            return (
              <Tag
                color="#D2E7CD"
                style={{ color: "#1F8505" }}
                key={displayStatus}
              >
                Active
              </Tag>
            );
          }
          // case 'Rejected': {
          //     return (
          //         <Tag color="#FBD0D1" style={{color: '#EE151D'}} key={displayStatus}>
          //             {displayStatus}
          //         </Tag>
          //     )
          // }
        }
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "65",
      render: (_, record: any) => (
        <>
          <Link
            to={`/admin/mda-directory/mda-details/${record.allData.id}`}
            state={record.allData}
          >
            {record.action}
          </Link>
        </>
      ),
    },
  ];

  for (let i = 0; i < mdaList?.length; i++) {
   
    
    const row = {
      id: i + 1,
      key: i.toString(),
      mdaName: mdaList[i]?.registeredName,
      mdaAdminCode: mdaList[i]?.mdaCode,
      initiationDate: mdaList[i]?.creationDate && moment(mdaList[i]?.creationDate).format("MMM Do YY"),
      time: mdaList[i]?.creationDate && moment(mdaList[i]?.creationDate).format("LT"),
      displayStatus: mdaList[i]?.active,
      action: `View Detail`,
      allData: mdaList[i],
      sector: mdaList[i]?.sector?.code,
    };
    dataSource.push(row);
  }

  const MDAName = Array.from(
    new Set(allData?.map((obj) => obj.registeredName))
  ).map((registeredName) => ({
    value: registeredName,
    label: registeredName,
  }));

  // const DisplayStatus = Array.from(
  //   new Set(allData?.map((obj: { active: boolean }) => obj.active))
  // ).map((active) => ({
  //   value: active,
  //   label: active ? "Active " : "Inactive",
  // }));

  const goBackHome = () => {
    navigate("/admin/oagf-overview");
  };

  const [account, setAccount] = useState<string>("");
  const [orgName, setOrgName] = useState<string>("");
  const [status, setStatus] = useState<any>("");
  const [orgId, setOrgID] = useState<string>("");

  const fetchFilter = () => {
    let filter = `org-name=${orgName}&org-id=${orgId}&status=${status}`;

    mdaMutate(filter, {
      onSuccess: (response: any) => {
        // setOrgName("");
        // setStatus("");
        // setOrgID("");
      },
    });
  };

  const resetFilter = () => {
    mdaMutate({
      onSuccess: (response: any) => {
        setOrgName("");
        setStatus("");
        setOrgID("");
      },
    });
  };

  return (
    <>
      <>
        {isLoading || isLoadingAll ? (
          <div className="loading-container">
            <FlapperSpinner />
          </div>
        ) : (
          <div className={"mdaDirectoryPage"}>
            <div className={"mdaDirectoryRow1"}>
              <div className={"mdaDirectoryBreadcrumb"}>
                <div className="cursor-pointer" onClick={goBackHome}>
                  <img src={goBack} alt=" " />
                </div>

                <div>Directory</div>
              </div>
              <div>
                <span className="data-range-text-overview">Date Range:</span>
                <span className="data-from-text-overview">From</span>
                <DatePicker
                  defaultValue={dayjs("2015/01/01", dateFormat)}
                  format={dateFormat}
                  className="datepicker"
                />
                <span className="data-from-text-overview">To</span>
                <DatePicker
                  defaultValue={dayjs("2015/01/01", dateFormat)}
                  format={dateFormat}
                  className="datepicker"
                />{" "}
              </div>
            </div>

            <div className={"mdaDirectoryRow2"}>MDA Review</div>

            <div className={"mdaDirectoryRow3 px-8"}>
              <div>Filter by:</div>

              <div className={"mdaDirectorySelectOptions"}>
                <Select
                  style={{ minWidth: 162 }}
                  options={[
                    { value: "", label: "Search MDA Name" },
                    ...MDAName,
                  ]}
                  showSearch
                  dropdownMatchSelectWidth={false}
                  onChange={(e) => setOrgName(e)}
                  placeholder="Search MDA Name"
                  value={orgName}
                />

                <Select
                  style={{ width: 150 }}
                  options={[
                    { value: "", label: "Display Status" },
                    { value: true, label: "Active" },
                    { value: false, label: "Inactive" },
                  ]}
                  dropdownMatchSelectWidth={false}
                  onChange={(e) => setStatus(e)}
                  showSearch
                  placeholder="Display Status"
                  value={status}
                  filterOption={(input, option: any) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />

                <button onClick={fetchFilter}>
                  <span>Apply Filter</span>
                  <img src={filter} alt="" />
                </button>

                <div onClick={resetFilter} className="cursor-pointer">
                  <span className="fs-9 text-primary fw-bold">
                    Reset Filter
                  </span>
                </div>
              </div>

              <div>
                <Table
                  dataSource={dataSource}
                  bordered
                  columns={columns}
                  scroll={{ x: "1000px" }}
                  />
                  
                  
              </div>
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default MdaDirectory;
