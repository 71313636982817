import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";

import { useFormik } from "formik";
import { Modal } from "react-bootstrap";

import { NumericFormat } from "react-number-format";
import { phoneRegExp } from "../../../../core/helpers/RegexHelpers";

import {
  useRequestOnboardingStepentitydetails,
  useVerificationcode,
  useRequestOnboardingSendVerificatoinCode,
  useGetSector,
  useGetMdas,
} from "../hooks/useUser";
import { infoBlue, phone } from "../../../../core/assets/icons";
import OTPInput from "react-otp-input";
import { FetchAndUnmask } from "../../../../core/helpers";

const Schema = Yup.object().shape({
  registeredEntityName: Yup.string()
    .required("Registered Entity Name is required")
    .max(50, "Text field must be at most 50 characters long"),
  mdaAdminCode: Yup.string()
    .required("MDA Code is required")
    .max(15, "Text field must be at most 12 characters long")
   .min(15, "Text field must be at most 12 characters long"),
  // tradingName: Yup.string().required('Trading Name is required'),
  mDASector: Yup.string()
    .required("MDA Sector is required")
    .max(50, "Text field must be at most 50 characters long"),
  // mDAParent: Yup.string().required('MDA Parent is required'),
  decription: Yup.string()
    .required("MDA Mandate is required")
    .min(5, "Text field must be longer than 5 characters"),
  entityPhysicalAddress: Yup.string()
    .required("Entity Physical Address is required")
    .max(50, "Text field must be at most 50 characters long"),
  entityCountry: Yup.string().required("Entity Country is required"),
  entityStateProvince: Yup.string()
    .required("Entity State/Province is required")
    .max(50, "Text field must be at most 50 characters long"),
  entityCityRegion: Yup.string()
    .required("Entity City Region is required")
    .max(50, "Text field must be at most 50 characters long"),
  entityphoneNumber: Yup.string()
    .required("Entity Phone Number is required")
    .max(50, "Text field must be at most 50 characters long"),
  entityEmailAddress: Yup.string()
    .required("Entity Email Address is required")
    .max(50, "Text field must be at most 50 characters long"),
  // websiteURL: Yup.string().required('Website URL is required'),
  // twitterHandle: Yup.string().required('Twitter Handle is required'),
  // facebokkHandle: Yup.string().required('Facebook Handle is required'),
  // instagramHandle: Yup.string().required('Instagram Handle is required'),
  // linkedInHandle: Yup.string().required('LinkedIn Handle is required'),
});

let stepJson: string;
let stepOneJson;

const initialValues = {
  registeredEntityName: "",
  mdaAdminCode: "",
  tradingName: "",
  mDASector: "",
  mDAParent: "",
  decription: "",
  entityPhysicalAddress: "",
  entityCountry: "",
  entityStateProvince: "",
  entityCityRegion: "",
  entityphoneNumber: "",
  entityEmailAddress: "",
  websiteURL: "",
  twitterHandle: "",
  facebokkHandle: "",
  instagramHandle: "",
  linkedInHandle: "",
};

interface Step1Props {
  handleNextStep: () => void;
  handlePreviousStep: () => void;
}

const StepThree: React.FC<Step1Props> = ({
  handleNextStep,
  handlePreviousStep,
}) => {
  const [show, setShow] = useState<boolean>(true);

  const { data, mutate, isLoading } = useRequestOnboardingStepentitydetails();
  const {
    data: verifyCode,
    mutate: mutateVerify,
    isLoading: loadingVerify,
  } = useVerificationcode();

  const {
    data: getSector,
    mutate: mutateGetSector,
    isLoading: loadingSector,
  } = useGetSector();

  const {
    data: getMda,
    mutate: mutateGetMda,
    isLoading: loadingGetMda,
  } = useGetMdas();

  useEffect(() => {
    mutateGetSector();
    mutateGetMda();
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values) => {
      mutate(
        {
          parentId: formik.values.mDAParent,
          registeredName: formik.values.registeredEntityName,
          tradingName: formik.values.tradingName,
          mdaAdminCode: formik.values.mdaAdminCode,
          description: formik.values.decription,
          contact: {
            physicalAddress: formik.values.entityPhysicalAddress,
            phoneNumber: formik.values.entityphoneNumber,
            websiteUrl: formik.values.websiteURL,
            email: formik.values.entityEmailAddress,
            twitterHandle: formik.values.twitterHandle,
            country: formik.values.entityCountry,
            state: formik.values.entityStateProvince,
            city: formik.values.entityCityRegion,
          },
          kycDocuments: {},
          sector: {
            code: formik.values.mDASector,
            description: formik.values.decription,
          },
        },
        {
          onSuccess: (res: any) => {
            // console.log(res);
            handleNextStep();
          },
        }
      );
    },
  });

  var names: any = [];
  let tt: any;

  stepJson = FetchAndUnmask("names") as string;

  stepOneJson = stepJson !== null ? JSON.parse(stepJson) : null;

  useEffect(() => {
    stepJson = FetchAndUnmask("names") as string;

    stepOneJson = stepJson !== null ? JSON.parse(stepJson) : null;

    tt = stepOneJson[1] ? stepOneJson[1] : "";

    names = stepOneJson;
  }, [handleNextStep, data, names, tt]);

  // const input1Ref = useRef(null);
  // const input2Ref = useRef(null);
  // const input3Ref = useRef(null);
  // const input4Ref = useRef(null);
  // const input5Ref = useRef(null);
  // const input6Ref = useRef(null);

  // const handleKeyDown = (event, currentRef, nextRef, prevRef) => {
  //   if (event.key === 'Backspace' && event.target.value === '') {
  //     prevRef.current.focus();
  //   } else if (event.key !== 'Tab' && event.target.value !== '') {
  //     nextRef.current.focus();
  //   } else if (event.key === 'Tab') {
  //     event.preventDefault();
  //     nextRef.current.focus();
  //   } else {

  // };

  const [otp, setOtp] = useState("");

  // const [formData, setFormData] = useState({
  //   input1: "",
  //   input2: "",
  //   input3: "",
  //   input4: "",
  //   input5: "",
  //   input6: "",
  // });

  // const handleChange = (event: any) => {
  //   event.preventDefault();
  //   const { name, value } = event.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

  const {
    data: sendVerifyData,
    mutate: sendVerifyMuute,
    isLoading: isVerfyLoading,
  } = useRequestOnboardingSendVerificatoinCode();

  const sendVerification = () => {
    sendVerifyMuute(stepOneJson["0"].email, {
      onSuccess: (res: any) => {
        // setShow(false);
      },
    });
  };

  const submitVerification = () => {
    // const combinedData = Object.values(formData).join("");
    // combinedData.toString();
    // console.log(combinedData);

    const payload = {
      password: tt?.password,
      confirmPassword: tt?.confirmPassword,
      code: otp,
      email: stepOneJson["0"].email,
    };

    console.log(payload);

    mutateVerify(payload, {
      onSuccess: (res: any) => {
        setShow(false);
      },
    });
  };
          const [showConfirm, setConfrim] = useState<any>(false);


      const confrimModal = () => {
    return (
      <Modal
        dialogClassName="  m-auto px-5 modal-card"
        aria-hidden="true"
        show={showConfirm}
        onHide={() => setConfrim(false)}
        animation
        centered
        size="lg"
        backdrop={"static"}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <>
              <div className="m-3 mx-15">
                <div className=" d-flex flex-column align-items-center ">
                  <img src={infoBlue} alt="" className="w-50px h-50px my-5" />

                  <span className="text-center fs-base text-dark">
                    Are you sure you want to submit?
                  </span>
                </div>
                <div className="d-flex justify-content-between mt-10">
                  <button
                    id="kt_password_cancel "
                    type="button"
                    className="btn btn-danger px-7 py-2 text-alt-red h-44 w-110 center-all "
                    onClick={() => setConfrim(false)}
                  >
                    <span className="indicator-label fs-base ">Cancel</span>
                  </button>
                  <button
                    className=" btn btn-success h-44 w-110 center-all"
                    type="button"
                    onClick={() => formik.handleSubmit()}
                  >
                    <span className="indicator-label fs-base ">Submit</span>
                  </button>
                </div>
              </div>

         
          </>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <>
        <div>
          <span className="form-text">Corporate Information</span>

          <div className="corporate-container-form">
            {/* <div className="mt-1">
              <div className="col-lg-6 col-form-label py-0 input-height">
                {formik.values.tradingName.length !== 0 && (
                  <label className="required fw-bold fs-9">Trading Name</label>
                )}
              </div>

              <div className="form-control form-control-solid  ">
                <input
                  type="text"
                  placeholder="Trading Name  "
                  className={'no-outline ps-4 text-dark fs-8'}
                  {...formik.getFieldProps('tradingName')}
                />
              </div>

              <div className="fv-plugins-message-container input-height">
                <div className="fv-help-block fs-9" style={{ color: 'red' }}>
                  {formik.touched.tradingName &&
                    formik.errors.tradingName &&
                    formik.errors.tradingName}
                </div>
              </div>
            </div> */}
            <div className="mt-1">
              <div className="col-lg-6 col-form-label py-0 input-height">
                {formik.values.mDAParent.length !== 0 && (
                  <label className="required fw-bold fs-9">
                    MDA Parent Organization
                  </label>
                )}
              </div>

              <div className="form-control form-control-solid  ">
                <select
                  {...formik.getFieldProps("mDAParent")}
                  className={`no-outline ps-4 fs-8 ${
                    formik.values.mDAParent !== "" ? "text-dark" : "text-muted"
                  } `}
                >
                  <option value="">MDA Parent Organization </option>
                  {getMda?.map((mda, i) => {
                    return (
                      <option value={mda.id} key={i}>
                        {mda.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="fv-plugins-message-container input-height">
                <div className="fv-help-block fs-9" style={{ color: "red" }}>
                  {formik.touched.mDAParent &&
                    formik.errors.mDAParent &&
                    formik.errors.mDAParent}
                </div>
              </div>
            </div>

            <div className="mt-1">
              <div className="col-lg-6 col-form-label py-0 input-height">
                {formik.values.mDASector.length !== 0 && (
                  <label className="required fw-bold fs-9">MDA Sector</label>
                )}
              </div>

              <div className="form-control form-control-solid  ">
                <select
                  {...formik.getFieldProps("mDASector")}
                  className={`no-outline ps-4 fs-8 ${
                    formik.values.mDASector !== "" ? "text-dark" : "text-muted"
                  } `}
                >
                  <option value="">Select MDA Sector *</option>
                  {getSector?.map((sector, i) => {
                    return (
                      <option value={sector.description}>
                        {sector.description}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="fv-plugins-message-container input-height">
                <div className="fv-help-block fs-9" style={{ color: "red" }}>
                  {formik.touched.mDASector &&
                    formik.errors.mDASector &&
                    formik.errors.mDASector}
                </div>
              </div>
            </div>
          </div>

          <div className="corporate-container-form">
            <div className="mt-1">
              <div className="col-lg-6 col-form-label py-0 input-height">
                {formik.values.registeredEntityName.length !== 0 && (
                  <label className="required fw-bold fs-9">
                    Registered Entity Name
                  </label>
                )}
              </div>

              <div className="form-control form-control-solid  ">
                <input
                  type="text"
                  placeholder="  Registered Entity Name  *  "
                  className={"no-outline ps-4 text-dark fs-8"}
                  {...formik.getFieldProps("registeredEntityName")}
                />
              </div>

              <div className="fv-plugins-message-container input-height">
                <div className="fv-help-block fs-9" style={{ color: "red" }}>
                  {formik.touched.registeredEntityName &&
                    formik.errors.registeredEntityName &&
                    formik.errors.registeredEntityName}
                </div>
              </div>
            </div>

            <div className="mt-1">
              <div className="col-lg-6 col-form-label py-0 input-height">
                {formik.values.mdaAdminCode.length !== 0 && (
                  <label className="required fw-bold fs-9">
                    Administrative Code
                  </label>
                )}
              </div>

              <div className="form-control form-control-solid  ">
                <input
                  type="text"
                  placeholder="Administrative Code  *  "
                  className={"no-outline ps-4 text-dark fs-8"}
                  {...formik.getFieldProps("mdaAdminCode")}
                />
              </div>

              <div className="fv-plugins-message-container input-height">
                <div className="fv-help-block fs-9" style={{ color: "red" }}>
                  {formik.touched.mdaAdminCode &&
                    formik.errors.mdaAdminCode &&
                    formik.errors.mdaAdminCode}
                </div>
              </div>
            </div>
          </div>

          <div
            className="corporate-container-form"
            style={{ marginBottom: "2rem" }}
          >
            <div className="mt-1">
              <div className="col-lg-12 col-form-label py-0 input-height">
                {formik.values.decription.length !== 0 && (
                  <label className="required fw-bold fs-9">Description</label>
                )}
              </div>

              <div className="form-control form-control-solid  ">
                <textarea
                  rows={6}
                  placeholder="MDA Mandate * &#10;"
                  className={"no-outline ps-2 text-dark fs-8 w-90"}
                  {...formik.getFieldProps("decription")}
                />
              </div>

              <div className="fv-plugins-message-container input-height">
                <div className="fv-help-block fs-9" style={{ color: "red" }}>
                  {formik.touched.decription &&
                    formik.errors.decription &&
                    formik.errors.decription}
                </div>
              </div>
            </div>
          </div>

          <hr style={{ padding: "1rem" }} />

          <div>
            <span className="form-text" style={{ marginBottom: "1rem" }}>
              Contact
            </span>

            <div className="corporate-container-form">
              <div className="mt-1">
                <div className="col-lg-12 col-form-label py-0 input-height">
                  {formik.values.entityPhysicalAddress.length !== 0 && (
                    <label className="required fw-bold fs-9">
                      Entity Physical Address
                    </label>
                  )}
                </div>

                <div className="form-control form-control-solid  ">
                  <input
                    type="text"
                    placeholder="Entity Physical Address  * "
                    className={"no-outline ps-4 text-dark fs-8"}
                    {...formik.getFieldProps("entityPhysicalAddress")}
                  />
                </div>

                <div className="fv-plugins-message-container input-height">
                  <div className="fv-help-block fs-9" style={{ color: "red" }}>
                    {formik.touched.entityPhysicalAddress &&
                      formik.errors.entityPhysicalAddress &&
                      formik.errors.entityPhysicalAddress}
                  </div>
                </div>
              </div>

              <div className="mt-1">
                <div className="col-lg-4 col-form-label py-0 input-height">
                  {formik.values.entityCountry.length !== 0 && (
                    <label className="required fw-bold fs-9">
                      Entity Country
                    </label>
                  )}
                </div>

                <div className="form-control form-control-solid  ">
                  <input
                    type="text"
                    placeholder="Entity Country *  "
                    className={"no-outline ps-4 text-dark fs-8"}
                    {...formik.getFieldProps("entityCountry")}
                  />
                </div>

                <div className="fv-plugins-message-container input-height">
                  <div className="fv-help-block fs-9" style={{ color: "red" }}>
                    {formik.touched.entityCountry &&
                      formik.errors.entityCountry &&
                      formik.errors.entityCountry}
                  </div>
                </div>
              </div>
            </div>

            <div className="corporate-container-form">
              <div className="mt-1">
                <div className="col-lg-12 col-form-label py-0 input-height">
                  {formik.values.entityStateProvince.length !== 0 && (
                    <label className="required fw-bold fs-9">
                      Entity State/Province
                    </label>
                  )}
                </div>

                <div className="form-control form-control-solid  ">
                  <input
                    type="text"
                    placeholder="Entity State/Province * "
                    className={"no-outline ps-4 text-dark fs-8"}
                    {...formik.getFieldProps("entityStateProvince")}
                  />
                </div>

                <div className="fv-plugins-message-container input-height">
                  <div className="fv-help-block fs-9" style={{ color: "red" }}>
                    {formik.touched.entityStateProvince &&
                      formik.errors.entityStateProvince &&
                      formik.errors.entityStateProvince}
                  </div>
                </div>
              </div>

              <div className="mt-1">
                <div className="col-lg-4 col-form-label py-0 input-height">
                  {formik.values.entityCityRegion.length !== 0 && (
                    <label className="required fw-bold fs-9">
                      Entity City/Region
                    </label>
                  )}
                </div>

                <div className="form-control form-control-solid  ">
                  <input
                    type="text"
                    placeholder="Entity City/Region * "
                    className={"no-outline ps-4 text-dark fs-8"}
                    {...formik.getFieldProps("entityCityRegion")}
                  />
                </div>

                <div className="fv-plugins-message-container input-height">
                  <div className="fv-help-block fs-9" style={{ color: "red" }}>
                    {formik.touched.entityCityRegion &&
                      formik.errors.entityCityRegion &&
                      formik.errors.entityCityRegion}
                  </div>
                </div>
              </div>
            </div>

            <div className="corporate-container-form">
              <div className="mt-1">
                <div className="col-lg-4 col-form-label py-0 input-height">
                  {formik.values.entityphoneNumber.length !== 0 && (
                    <label className="required fw-bold fs-9">
                      Phone Number
                    </label>
                  )}
                </div>

                <div className={`form-control form-control-solid `}>
                  <img src={phone} alt="fullName" className="icon-style" />

                  <NumericFormat
                    type="text"
                    className="no-outline ps-2 text-dark fs-8"
                    {...formik.getFieldProps("entityphoneNumber")}
                    placeholder=" Phone Number*"
                    allowNegative={false}
                    allowLeadingZeros={true}
                  />
                </div>

                <div
                  className="fv-plugins-message-container input-height"
                  style={{ color: "red" }}
                >
                  <div className="fv-help-block fs-9">
                    {formik.touched.entityphoneNumber &&
                      formik.errors.entityphoneNumber &&
                      formik.errors.entityphoneNumber}
                  </div>
                </div>
              </div>

              <div className="mt-1">
                <div className="col-lg-4 col-form-label py-0 input-height">
                  {formik.values.entityEmailAddress.length !== 0 && (
                    <label className="required fw-bold fs-9">
                      Entity Email Address
                    </label>
                  )}
                </div>

                <div className="form-control form-control-solid  ">
                  <input
                    type="text"
                    placeholder="Entity Email Address  * "
                    className={"no-outline ps-4 text-dark fs-8"}
                    {...formik.getFieldProps("entityEmailAddress")}
                  />
                </div>

                <div className="fv-plugins-message-container input-height">
                  <div className="fv-help-block fs-9" style={{ color: "red" }}>
                    {formik.touched.entityEmailAddress &&
                      formik.errors.entityEmailAddress &&
                      formik.errors.entityEmailAddress}
                  </div>
                </div>
              </div>
            </div>

            <div className="corporate-container-form">
              <div className="mt-1">
                <div className="col-lg-12 col-form-label py-0 input-height">
                  {formik.values.websiteURL.length !== 0 && (
                    <label className="required fw-bold fs-9">Website URL</label>
                  )}
                </div>

                <div className="form-control form-control-solid  ">
                  <input
                    type="text"
                    placeholder="Website URL "
                    className={"no-outline ps-4 text-dark fs-8"}
                    {...formik.getFieldProps("websiteURL")}
                  />
                </div>

                <div className="fv-plugins-message-container input-height">
                  <div className="fv-help-block fs-9" style={{ color: "red" }}>
                    {formik.touched.websiteURL &&
                      formik.errors.websiteURL &&
                      formik.errors.websiteURL}
                  </div>
                </div>
              </div>

              <div className="mt-1">
                <div className="col-lg-4 col-form-label py-0 input-height">
                  {formik.values.twitterHandle.length !== 0 && (
                    <label className="required fw-bold fs-9">
                      Twitter Handle
                    </label>
                  )}
                </div>

                <div className="form-control form-control-solid  ">
                  <input
                    type="text"
                    placeholder="Twitter Handle"
                    className={"no-outline ps-4 text-dark fs-8"}
                    {...formik.getFieldProps("twitterHandle")}
                  />
                </div>

                <div className="fv-plugins-message-container input-height">
                  <span>
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-9"
                    >
                      <path
                        d="M8 7.65698H8.5V11.157H9M14.5 8.15698C14.5 11.4707 11.8137 14.157 8.5 14.157C5.18629 14.157 2.5 11.4707 2.5 8.15698C2.5 4.84327 5.18629 2.15698 8.5 2.15698C11.8137 2.15698 14.5 4.84327 14.5 8.15698Z"
                        stroke="#0DA4C6"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.375 6.15698C8.78921 6.15698 9.125 5.8212 9.125 5.40698C9.125 4.99277 8.78921 4.65698 8.375 4.65698C7.96079 4.65698 7.625 4.99277 7.625 5.40698C7.625 5.8212 7.96079 6.15698 8.375 6.15698Z"
                        fill="#5E718D"
                      />
                    </svg>
                  </span>
                  <span className="twitter-text ml-3">
                    Social media handle format eg @tmsofficial
                  </span>
                  <div className="fv-help-block fs-9" style={{ color: "red" }}>
                    {formik.touched.twitterHandle &&
                      formik.errors.twitterHandle &&
                      formik.errors.twitterHandle}
                  </div>
                </div>
              </div>
            </div>

            <div className="corporate-container-form">
              <div className="mt-1">
                <div className="col-lg-12 col-form-label py-0 input-height">
                  {formik.values.facebokkHandle.length !== 0 && (
                    <label className="required fw-bold fs-9">
                      Facebook Handle
                    </label>
                  )}
                </div>

                <div className="form-control form-control-solid  ">
                  <input
                    type="text"
                    placeholder="Facebook Handle "
                    className={"no-outline ps-4 text-dark fs-8"}
                    {...formik.getFieldProps("facebokkHandle")}
                  />
                </div>

                <div className="fv-plugins-message-container input-height">
                  <span>
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-9"
                    >
                      <path
                        d="M8 7.65698H8.5V11.157H9M14.5 8.15698C14.5 11.4707 11.8137 14.157 8.5 14.157C5.18629 14.157 2.5 11.4707 2.5 8.15698C2.5 4.84327 5.18629 2.15698 8.5 2.15698C11.8137 2.15698 14.5 4.84327 14.5 8.15698Z"
                        stroke="#0DA4C6"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.375 6.15698C8.78921 6.15698 9.125 5.8212 9.125 5.40698C9.125 4.99277 8.78921 4.65698 8.375 4.65698C7.96079 4.65698 7.625 4.99277 7.625 5.40698C7.625 5.8212 7.96079 6.15698 8.375 6.15698Z"
                        fill="#5E718D"
                      />
                    </svg>
                  </span>
                  <span className="twitter-text ml-3">
                    Social media handle format eg @tmsofficial
                  </span>
                  <div className="fv-help-block fs-9" style={{ color: "red" }}>
                    {formik.touched.twitterHandle &&
                      formik.errors.twitterHandle &&
                      formik.errors.twitterHandle}
                  </div>
                </div>

                <div className="fv-plugins-message-container input-height">
                  <div className="fv-help-block fs-9" style={{ color: "red" }}>
                    {formik.touched.facebokkHandle &&
                      formik.errors.facebokkHandle &&
                      formik.errors.facebokkHandle}
                  </div>
                </div>
              </div>

              <div className="mt-1">
                <div className="col-lg-4 col-form-label py-0 input-height">
                  {formik.values.instagramHandle.length !== 0 && (
                    <label className="required fw-bold fs-9">
                      Instagram Handle
                    </label>
                  )}
                </div>

                <div className="form-control form-control-solid  ">
                  <input
                    type="text"
                    placeholder="Instagram Handle"
                    className={"no-outline ps-4 text-dark fs-8"}
                    {...formik.getFieldProps("instagramHandle")}
                  />
                </div>

                <div className="fv-plugins-message-container input-height">
                  <span>
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-9"
                    >
                      <path
                        d="M8 7.65698H8.5V11.157H9M14.5 8.15698C14.5 11.4707 11.8137 14.157 8.5 14.157C5.18629 14.157 2.5 11.4707 2.5 8.15698C2.5 4.84327 5.18629 2.15698 8.5 2.15698C11.8137 2.15698 14.5 4.84327 14.5 8.15698Z"
                        stroke="#0DA4C6"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.375 6.15698C8.78921 6.15698 9.125 5.8212 9.125 5.40698C9.125 4.99277 8.78921 4.65698 8.375 4.65698C7.96079 4.65698 7.625 4.99277 7.625 5.40698C7.625 5.8212 7.96079 6.15698 8.375 6.15698Z"
                        fill="#5E718D"
                      />
                    </svg>
                  </span>
                  <span className="twitter-text ml-3">
                    Social media handle format eg @tmsofficial
                  </span>
                  <div className="fv-help-block fs-9" style={{ color: "red" }}>
                    {formik.touched.instagramHandle &&
                      formik.errors.instagramHandle &&
                      formik.errors.instagramHandle}
                  </div>
                </div>
              </div>
            </div>

            <div className="corporate-container-form">
              <div className="mt-1">
                <div className="col-lg-12 col-form-label py-0 input-height">
                  {formik.values.linkedInHandle.length !== 0 && (
                    <label className="required fw-bold fs-9">
                      LinkedIn Handle
                    </label>
                  )}
                </div>

                <div className="form-control form-control-solid  ">
                  <input
                    type="text"
                    placeholder="LinkedIn Handle "
                    className={"no-outline ps-4 text-dark fs-8"}
                    {...formik.getFieldProps("linkedInHandle")}
                  />
                </div>

                <div className="fv-plugins-message-container input-height">
                  <span>
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-9"
                    >
                      <path
                        d="M8 7.65698H8.5V11.157H9M14.5 8.15698C14.5 11.4707 11.8137 14.157 8.5 14.157C5.18629 14.157 2.5 11.4707 2.5 8.15698C2.5 4.84327 5.18629 2.15698 8.5 2.15698C11.8137 2.15698 14.5 4.84327 14.5 8.15698Z"
                        stroke="#0DA4C6"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.375 6.15698C8.78921 6.15698 9.125 5.8212 9.125 5.40698C9.125 4.99277 8.78921 4.65698 8.375 4.65698C7.96079 4.65698 7.625 4.99277 7.625 5.40698C7.625 5.8212 7.96079 6.15698 8.375 6.15698Z"
                        fill="#5E718D"
                      />
                    </svg>
                  </span>
                  <span className="twitter-text ml-3">
                    Social media handle format eg @tmsofficial
                  </span>
                  <div className="fv-help-block fs-9" style={{ color: "red" }}>
                    {formik.touched.twitterHandle &&
                      formik.errors.twitterHandle &&
                      formik.errors.twitterHandle}
                  </div>
                </div>

                <div className="fv-plugins-message-container input-height">
                  <div className="fv-help-block fs-9" style={{ color: "red" }}>
                    {formik.touched.linkedInHandle &&
                      formik.errors.linkedInHandle &&
                      formik.errors.linkedInHandle}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <nav className={`nav navbar-fixed-signup-bottom `} id="nav">
            <div className="nav-center-signup">
              {/* Nav header */}
              <div className="nav-header-signup">
                <button
                  className="nav-btn-back nav-back-text"
                  id="nav-btn"
                  onClick={handlePreviousStep}
                >
                  <i className="fa fa-solid fa-arrow-left"></i>{" "}
                  <span style={{ marginLeft: "0.2rem" }}>back</span>
                </button>{" "}
                {/* <button className="nav-btn-Proceed" id="nav-btn">
                          Proceed
                        </button> */}
                <button
                  id="kt_password_cancel "
                  className={`btn nav-btn-Proceed ${
                    !(formik.isValid && formik.dirty)
                      ? "btn-secondary"
                      : "btn-success"
                  } text-white px-7 py-2`}
                disabled={!(formik.isValid && formik.dirty)}
                  onClick={(e) => setConfrim(true)}
                >
                  {!isLoading && (
                    <span className="indicator-label">Proceed</span>
                  )}
                  {isLoading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please wait...{" "}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </nav>
        </div>

      <Modal
        dialogClassName=" m-auto px-7 modal-card-signup"
        aria-hidden="true"
        show={show}
        onHide={() => setShow(false)}
        animation
        centered
        size="lg"
        backdrop={"static"}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="">
            <div className=" d-flex flex-column align-items-center ">
              <div className="mt-5">
                <svg
                  width="21"
                  height="19"
                  viewBox="0 0 21 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.5 7V16.75C1.5 16.9489 1.57902 17.1397 1.71967 17.2803C1.86032 17.421 2.05109 17.5 2.25 17.5H18.75C18.9489 17.5 19.1397 17.421 19.2803 17.2803C19.421 17.1397 19.5 16.9489 19.5 16.75V7M1.5 7L10.5 1L19.5 7M1.5 7L8.86363 12.25M19.5 7L12.1363 12.25M8.86363 12.25L1.73126 17.2881M8.86363 12.25L12.1363 12.25M19.2688 17.2881L12.1363 12.25"
                    stroke="#FBCB38"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <div className="email-verification-container">
                <span className="email-verification-text">
                  Email Verification
                </span>
              </div>

              <div className="email-verification-container">
                <div className="email-verification-body">
                  <div>Please enter the 6-digit code sent to</div>
                  <div>{stepOneJson[0]?.email}</div>
                </div>
              </div>

              {/* <div className="otp-box mt-5">
                <input
                  type="text"
                  maxLength={1}
                  name="input1"
                  onChange={handleChange}
                  value={formData.input1}
                  ref={input1Ref}
                  // onKeyDown={(event) => handleKeyDown(event, input1Ref, input2Ref, input6Ref)}
                />
                <input
                  type="text"
                  maxLength={1}
                  name="input2"
                  onChange={handleChange}
                  ref={input2Ref}
                  value={formData.input2}
                  // onKeyDown={(event) => handleKeyDown(event, input2Ref, input3Ref, input1Ref)}
                />
                <input
                  type="text"
                  maxLength={1}
                  name="input3"
                  onChange={handleChange}
                  value={formData.input3}
                  ref={input3Ref}
                  // onKeyDown={(event) => handleKeyDown(event, input3Ref, input4Ref, input2Ref)}
                />
                <input
                  type="text"
                  maxLength={1}
                  name="input4"
                  onChange={handleChange}
                  value={formData.input4}
                  ref={input4Ref}
                  // onKeyDown={(event) => handleKeyDown(event, input4Ref, input5Ref, input3Ref)}
                />

                <input
                  type="text"
                  maxLength={1}
                  name="input5"
                  onChange={handleChange}
                  value={formData.input5}
                  ref={input5Ref}
                  // onKeyDown={(event) => handleKeyDown(event, input5Ref, input6Ref, input4Ref)}
                />

                <input
                  type="text"
                  maxLength={1}
                  name="input6"
                  ref={input6Ref}
                  onChange={handleChange}
                  value={formData.input6}
                  // onKeyDown={(event) => handleKeyDown(event, input6Ref, null, input5Ref)}
                />
              </div> */}

              <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderInput={(props) => <input {...props} />}
                containerStyle={"otp-box mt-5"}
                inputStyle={"w-100"}
                placeholder="------"
              />

              <div className="email-verification-container mt-2">
                <span className="resend-code-text" onClick={sendVerification}>
                  Resend Code
                </span>
              </div>
            </div>

            <div className=" d-flex flex-column align-items-center mt-5 ">
              <button
                className={`otp-button center-all ${
                  otp.length !== 6 ? "btn bg-gray-500" : ""
                } `}
                onClick={() => submitVerification()}
                disabled={
                  otp.length !== 6
                    ? true
                    : isVerfyLoading || loadingVerify
                    ? true
                    : false
                }
              >
                {isVerfyLoading || loadingVerify ? (
                  <span className="otp-button-text">Loading..</span>
                ) : (
                  <span className="otp-button-text">Verify</span>
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {confrimModal()}
    </>
  );
};

export { StepThree };
