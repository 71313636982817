import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FlapperSpinner } from "react-spinners-kit";
import { receipt } from "../../../../core/assets/imgs";
import { ErrorToast } from "../../../componets/toast";
import PaymentSummary from "../components/PaymentSummary";
import SuccessModal from "../components/SuccessModal";
import {
  getInvoice,
  useConfirmPayment,
  usePaymentList,
  useRevenuePaymentRequesRefid,
} from "../hooks/usePayment";
import { ErrorHandler } from "../../../../core/helpers";

const initialValues = {
  invoiceNo: "",
};
const InvoicePayments: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);
  const [show, setShow] = useState<boolean>(false);
  const [paramsData, setParamsData] = useState<boolean>(false);
  const [invoiceData, setInvoiceData] = useState<any>([]);
  const { data, mutate, isLoading } = useRevenuePaymentRequesRefid();
  const [invoiceNo, setInvoiceNo] = useState<any>("");
  const {
    data: dataPayment,
    mutate: paymentMutate,
    isLoading: isLoadingConfirmPayment,
  } = useConfirmPayment();

  const {
    data: paymentList,
    mutate: mutatePayList,
    isLoading: isLoadingPayList,
  } = usePaymentList();

  useEffect(() => {
    mutatePayList();
  }, []);

  const [invoiceParamsData, setInvoiceParamsData] = useState<boolean>(false);

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [step, isLoadingConfirmPayment]);

  useEffect(() => {
    if (location?.state) {
      setInvoiceData(location?.state);
      setInvoiceParamsData(true);
      setParamsData(true);
      setStep(2);
    }
  }, []);

  useEffect(() => {
    if (data) {
      setStep(2);
    }
  }, [mutate, data]);

  const handleSubmit = () => {
    setLoading(true);
    getInvoice(invoiceNo)
      .then((response) => {
        if (response?.status.toLowerCase() === "paid") {
          ErrorToast("Invoice Already Paid");
          return;
        }
        setInvoiceData(response);
        setStep(2);
      })
      .catch((err) => {
        ErrorHandler(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const done = () => {
    navigate("/");
  };

  const handleChange = (event: any) => {
    const { value } = event.target;
    // Limit the input length to 10 characters
    const limitedValue = value.slice(0, 10);
    setInvoiceNo(limitedValue);
  };

  return (
    <div
      className={`m-vh-${
        isLoadingConfirmPayment || isLoadingPayList ? 100 : 15
      }`}
    >
      {isLoadingConfirmPayment || isLoadingPayList ? (
        <div className="loading-container">
          <FlapperSpinner />
        </div>
      ) : (
        <div>
          <div className="pb-15 pt-2">
            <div className="m-auto my-7e responsive-card overflow-none d-flex  flex-column">
              <div className="d-flex align-items-center justify-content-center">
                <span
                  className={`fw-bold fs-9 ${
                    step >= 1 ? "touched-step" : "unTouched-step"
                  }`}
                >
                  1
                </span>

                <span
                  className={`mx-1 ${
                    step >= 1 ? "touched-line" : "unTouched-line"
                  }`}
                />

                <span
                  className={`fw-bold fs-9 ${
                    step >= 2 ? "touched-step" : "unTouched-step"
                  }`}
                >
                  2
                </span>
              </div>

              <div className="d-flex align-items-center justify-content-center my-4 ms-6 me-4">
                <span className="fs-9 ">Step 1 of 2</span>

                <span className="w-152" />

                <span className="fs-9 ">Step 2 of 2</span>
              </div>
            </div>

            {step === 1 && (
              <div className="card   m-auto p-11 responsive-card">
                <span className="text-dark fs-3 fw-bolder  text-center">
                  Electronic Invoice Payments
                </span>
                <div className="form align-middle  gx-0 gy-5 mt-5">
                  <div className="mt-5">
                    <div className=" mb-3">
                      <div className="col-lg-6 col-form-label py-0 input-height">
                        {invoiceNo.length !== 0 && (
                          <label className="required fw-bold fs-9">
                            Enter invoice number
                          </label>
                        )}
                      </div>

                      <div className={`form-control form-control-solid`}>
                        <img
                          src={receipt}
                          alt="invoice"
                          className="icon-style"
                        />

                        <input
                          type="number"
                          placeholder="Enter invoice number*"
                          className={"no-outline ps-4 text-dark fs-8"}
                          onChange={handleChange}
                          value={invoiceNo}
                        />
                      </div>

                      <div className="fv-plugins-message-container input-height">
                        <div className="fv-help-block fs-9 text-danger">
                          {invoiceNo.length > 1 &&
                            invoiceNo.length < 10 &&
                            "Invoice number is required"}
                          {invoiceNo.length > 10 && "Maximum Number Exceeded"}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex  justify-content-between mt-11">
                    <div className="">
                      <button
                        id="kt_password_cancel "
                        type="button"
                        className="btn btn-danger px-7 py-2  text-alt-red "
                        onClick={done}
                      >
                        <span className="indicator-label fs-base ">Cancel</span>
                      </button>
                    </div>

                    <div className="">
                      <button
                        id="kt_password_cancel "
                        type="submit"
                        className={`btn ${
                          invoiceNo.length !== 10 || loading
                            ? "btn-secondary"
                            : "btn-success"
                        } text-white px-9 py-2 h-44 w-min-110 rounded-1`}
                        disabled={invoiceNo.length !== 10 || loading}
                        onClick={handleSubmit}
                      >
                        {/*!isLoading */}
                        {!loading && (
                          <span className="indicator-label fs-base">Next</span>
                        )}
                        {/*isLoading */}
                        {loading && (
                          <span className="indicator-progress d-flex justify-content-center  align-items-center">
                            <span className="d-none d-md-block">
                              Please wait...{" "}
                            </span>
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {step === 2 && (
              <PaymentSummary
                invoiceData={invoiceData}
                setStep={() => setStep(1)}
                setShow={() => setShow(true)}
                paymentMutate={paymentMutate}
                setInvoiceData={(e: any) => setInvoiceData(e)}
                paramsData={paramsData}
                payListMutate={mutatePayList}
                payListData={paymentList}
              />
            )}
          </div>

          <SuccessModal
            show={show}
            setShow={() => setShow(true)}
            invoiceData={invoiceData}
            done={() => done()}
          />
        </div>
      )}
    </div>
  );
};

export { InvoicePayments };
