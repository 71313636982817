import { Dropdown, MenuProps, Space } from "antd";
import {  SmileOutlined} from "@ant-design/icons";

import React from "react";
import "../../../App.css";





const Drop = (Data: any) => {

  const items: MenuProps['items'] = [
  {
    key: '1',
    label: (
      <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
        1st menu item 1st menu item 1st menu item
      </a>
    ),
    },
    
      {
    key: '2',
    label: (
      <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
        1st menu item 1st menu item 1st menu item
      </a>
    ),
    },
      
        {
    key: '3',
    label: (
      <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
        1st menu item 1st menu item 1st menu item
      </a>
    ),
    },
        

];


  return (
    <>
             <Dropdown menu={{ items }}>
    <a onClick={(e) => e.preventDefault()}>
      <Space>
         <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect y="0.578125" width="24" height="24" rx="2" fill="#FEF5D7" />
          <path
            d="M9.00001 18.5781V19.3281C9.00001 20.1237 9.31608 20.8868 9.87868 21.4494C10.4413 22.012 11.2044 22.3281 12 22.3281C12.7957 22.3281 13.5587 22.012 14.1213 21.4494C14.6839 20.8868 15 20.1237 15 19.3281V18.5781M5.26904 10.3281C5.2678 9.43858 5.44262 8.55763 5.78343 7.73602C6.12424 6.91441 6.6243 6.16838 7.25477 5.54093C7.88525 4.91347 8.63368 4.41701 9.45693 4.08016C10.2802 3.74331 11.1619 3.57273 12.0514 3.57825C15.7629 3.60585 18.7317 6.69088 18.7317 10.4128V11.0781C18.7317 14.4358 19.4342 16.3842 20.0529 17.4491C20.1196 17.5629 20.1551 17.6923 20.1558 17.8242C20.1565 17.956 20.1224 18.0858 20.0569 18.2002C19.9915 18.3147 19.8971 18.41 19.7831 18.4763C19.6691 18.5426 19.5397 18.5777 19.4078 18.5781H4.59222C4.46034 18.5777 4.33087 18.5426 4.21689 18.4763C4.1029 18.4099 4.00844 18.3147 3.94301 18.2002C3.87759 18.0856 3.84352 17.9559 3.84425 17.824C3.84498 17.6921 3.88048 17.5628 3.94716 17.449C4.56622 16.384 5.26904 14.4356 5.26904 11.0781L5.26904 10.3281Z"
            stroke="#1F8505"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle cx="17.5" cy="5.07812" r="4.5" fill="#EE151D" />
          <path
            d="M16.615 5.80312C16.935 5.52646 17.1867 5.29979 17.37 5.12312C17.5567 4.94312 17.7117 4.75646 17.835 4.56312C17.9583 4.36979 18.02 4.17646 18.02 3.98312C18.02 3.78312 17.9717 3.62646 17.875 3.51312C17.7817 3.39979 17.6333 3.34312 17.43 3.34312C17.2333 3.34312 17.08 3.40646 16.97 3.53312C16.8633 3.65646 16.8067 3.82312 16.8 4.03312H16.25C16.26 3.65312 16.3733 3.36312 16.59 3.16312C16.81 2.95979 17.0883 2.85812 17.425 2.85812C17.7883 2.85812 18.0717 2.95812 18.275 3.15812C18.4817 3.35812 18.585 3.62479 18.585 3.95812C18.585 4.19812 18.5233 4.42979 18.4 4.65312C18.28 4.87312 18.135 5.07146 17.965 5.24812C17.7983 5.42146 17.585 5.62312 17.325 5.85312L17.1 6.05312H18.685V6.52812H16.255V6.11312L16.615 5.80312Z"
            fill="white"
          />
        </svg>
      </Space>
    </a>
  </Dropdown>
    </>
  )



}


export default Drop;
