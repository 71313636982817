import { FetchAndUnmask, getPaginationParams } from "../../../../../../../core/helpers";

export const fetchMdaTableData = (
  status: any,
  mutate: any,
  tableParams: any,
  setTableParams: any,
  startDateData: any,
  endDateData: any
) => {
  const orgId: string | null = FetchAndUnmask("orgId");
  mutate(
    `${status}/${orgId}/${startDateData}/${endDateData}?${getPaginationParams(
      tableParams
    )}`,
    {
      onSuccess: (res) => {
        setTableParams({
          pagination: {
            ...tableParams.pagination,
            total: res?.totalSize,
          },
        });
      },
    }
  );
};

export const fetchMdaTableDataReset = (
  status:any,
  mutate: any,
  tableParams: any,
  setTableParams: any,
  setDateRefreshed: any,
  startDateData: any,
  endDateData: any
) => {
  const orgId: string | null = FetchAndUnmask("orgId");
  mutate(
    `${status}/${orgId}/${startDateData}/${endDateData}?${getPaginationParams({
      pagination: {
        ...tableParams.pagination,
        current: 1,
      },
    })}`,
    {
      onSuccess: (res) => {
        setDateRefreshed(true);
        setTableParams({
          pagination: {
            ...tableParams.pagination,
            current: 1,
            total: res?.totalSize,
          },
        });
      },
    }
  );
};
