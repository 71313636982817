import { Table, Switch, Space, Select, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import {
  filter,
  searchCollection,
} from "../../../../../../../core/assets/icons";
import { isEmpty } from "../../../../../../../core/helpers";
import moment from "moment";

export const AuditTrail = (TableData) => {
  const [search, setSearch] = useState<string>("");

  const filteredData = Array.isArray(TableData.TableData)
    ? TableData.TableData?.filter(
        (item: any) =>
          item?.id?.toLowerCase().includes(search?.toLowerCase()) ||
          item?.createdBy?.toLowerCase().includes(search?.toLowerCase()) ||
          item?.ipAddress?.toLowerCase().includes(search?.toLowerCase()) ||
          item?.location?.toLowerCase().includes(search?.toLowerCase())
      )
    : [];

  const columns = [
    {
      title: "S/N",
      dataIndex: "sn",
      key: "sn",
      sorter: (a, b) => a.sn - b.sn,
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Username",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Process Type",
      dataIndex: "processType",
      key: "processType",
    },
    {
      title: "Operation Type",
      dataIndex: "operationType",
      key: "operationType",
    },
    {
      title: "Date & Time Stamp",
      dataIndex: "dateTime",
      key: "dateTime",
    },
    {
      title: "Device",
      dataIndex: "device",
      key: "device",
    },

    {
      title: "IP-Address",
      dataIndex: "ipAddress",
      key: "ipAddress",
    },

    {
      title: "Location",
      dataIndex: "loaction",
      key: "loaction",
    },
  ];

  const dataSource: any[] = [];
  for (let i = 0; i <= filteredData?.length; i++) {
    if (filteredData[i]) {
      const datad = {
        key: i.toString(),
        sn: i + 1,
        id: filteredData[i].id,
        name: filteredData[i]?.createdBy,
        processType: filteredData[i]?.processType,
        operationType: filteredData[i]?.operationType,
        dateTime: filteredData[i]?.creationDate
          ? moment(filteredData[i]?.creationDate).format("MMMM Do YYYY, h:mm a")
          : "",
        device: filteredData[i].device || "N/A",
        loaction: filteredData[i].location || "N/A",
        ipAddress: filteredData[i].ipAddress || "N/A",
      };
      dataSource.push(datad);
    }
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const processTypeList = Array.from(
    new Set(
      dataSource
        .map((obj: any) => {
          return obj?.processType;
        })
        .filter((processType: any) => processType !== null)
    )
  ).map((processType) => ({
    value: processType,
    label: processType,
  }));

  const operationTypeList = Array.from(
    new Set(
      dataSource
        .map((obj: any) => {
          return obj?.operationType;
        })
        .filter((operationType: any) => operationType !== null)
    )
  ).map((operationType) => ({
    value: operationType,
    label: operationType,
  }));

  const [processType, setProcessType] = useState<string>("");
  const [operationType, setOperationType] = useState<any>("");

  const fetchFilter = () => {
    let filter = `start-date=${TableData.start}&end-date=${TableData.stop}&processType=${processType}&operationType=${operationType}`;
    TableData.mutate(filter, {
      onSuccess: (response: any) => {
        // setProcessType("");
        // setOperationType("");
      },
    });
  };

  const resetFilter = () => {
    TableData.mutate(
      `start-date=${TableData.start}&end-date=${TableData.stop}`,
      {
        onSuccess: (response: any) => {
          setProcessType("");
          setOperationType("");
        },
      }
    );
  };

  return (
    <div className="my-2 mx-4 py-1">
      <div>
        <span className="fw-bolder fs-5"> AUDIT TRAIL REPORT</span>
      </div>

      <div className="d-flex justify-content-center">
        <div className="form-control form-control-solid pe-1 collection-serach">
          <input
            type="text"
            value={search}
            placeholder="Search by Username, Location or IP-Adresss"
            className={"no-outline text-dark fs-8 w-90"}
            onChange={handleSearchChange}
          />

          <img src={searchCollection} alt="search" className="icon-style" />
        </div>
      </div>

      <div className={"mdaDirectoryPage p-0 "}>
        <div className={"mdaDirectoryRow3 p-0"}>
          <div className={"mdaDirectorySelectOptions"}>
            <div className="mx-2">Filter by:</div>
            <Select
              style={{ minWidth: 162 }}
              options={[
                { value: "", label: "Process Type" },
                ...processTypeList,
              ]}
              showSearch
              dropdownMatchSelectWidth={false}
              onChange={(e) => setProcessType(e)}
              placeholder="Process Type"
              value={processType}
            />

            <Select
              style={{ width: 150 }}
              options={[
                { value: "", label: "Operation Type" },
                ...operationTypeList,
              ]}
              dropdownMatchSelectWidth={false}
              onChange={(e) => setOperationType(e)}
              showSearch
              placeholder="Operation Type"
              value={operationType}
            />

            <button onClick={fetchFilter}>
              <span>Apply Filter</span>
              <img src={filter} alt="" />
            </button>

            <div onClick={resetFilter} className="cursor-pointer">
              <span className="fs-9 text-primary fw-bold">Reset Filter</span>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Table
          dataSource={dataSource}
          columns={columns}
          sortDirections={["descend", "ascend"]}
          scroll={{ x: "1000px" }}
        />
      </div>
    </div>
  );
};
