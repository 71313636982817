import { FlapperSpinner } from "react-spinners-kit";
import { Layout } from "../../../layout";
import {
  useMdaActivate,
  useMdaDetails,
  useOagfCollectionAnalyticsServices,
} from "../../../../auth/hooks/useUser";
import { DatePicker, Select, Table } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Download } from "../../../../../componets/download";
import { filter } from "../../../../../../core/assets/icons";
import { getWeekRange } from "../../../../../../core/helpers";
import moment from "moment";
import { useGettop10Organizations } from "../../../Payments/hooks/usePayment";

const { RangePicker } = DatePicker;

const dateFormat = "YYYY/MM/DD";
const TopMda = () => {
  const { isLoading } = useMdaDetails();

  const {
    data: activateData,
    mutate: activateMutate,
    isLoading: activateLoading,
  } = useMdaActivate();

  const {
    data: top10Data,
    mutate: top10Mutate,
    isLoading: isLoadingMutate,
  } = useGettop10Organizations();


  const [dateRange, setDateRange] = useState<any>([]);
  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      setDateRange(dates);
      var date1 = moment(new Date(dates[0]).toISOString()).format("YYYY-MM-DD");
      var date2 = moment(new Date(dates[1]).toISOString()).format("YYYY-MM-DD");
      top10Mutate({ startDate: date1, endDate: date2 });
    } else {
      setDateRange([]);
    }
  };

  useEffect(() => {
    const { startDate, endDate } = getWeekRange();
    setDateRange([dayjs(startDate), dayjs(endDate)]);
    const startDateData = moment(startDate).format("YYYY-MM-DD");
    const endDateData = moment(endDate).format("YYYY-MM-DD");

    top10Mutate({ startDate: startDateData, endDate: endDateData });
  }, []);

  const dataSource: any[] = [];
  const dataSourceForExcel: any[] = [[]];

  const columns = [
    {
      title: "S/N",
      dataIndex: "sn",
      key: "sn",
      width: "30",
    },
    {
      title: "MDA Name",
      dataIndex: "mdaName",
      key: "mdaName",
      width: "180",
    },
    {
      title: "Revenue",
      dataIndex: "revenue",
      key: "revenue",
      width: "80",
    },
  ];

  var totalAmount = 0;

  
if(top10Data){
  for (let i = 0; i < top10Data[0]?.top10Organizations?.length; i++) {
    var tAmount = top10Data[0]?.top10Organizations[i]?.totalRevenue;

    if (tAmount) {
      totalAmount += tAmount;
    }
    const row = {
      sn: i + 1,
      mdaName: top10Data[0]?.top10Organizations[i].registeredName,
      revenue: top10Data[0]?.top10Organizations[i]?.totalRevenue
      ? top10Data[0]?.top10Organizations[i]?.totalRevenue.toLocaleString("en", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : "0.00"
    };
    dataSource.push(row);
  }
}

  return (
    <>
      {isLoading || activateLoading || isLoadingMutate ? (
        <div className="loading-container">
          <FlapperSpinner /> {/* Replace with your loading indicator */}
        </div>
      ) : (
        <div className={"topMda"}>
          <div className={"topMdaBreadcrumb"}>
            <div className="fs-base">Analytics</div>

            <svg
              width="10"
              height="13"
              viewBox="0 0 10 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                fill="#1F8505"
              />
            </svg>

            <div className="fs-base">Collection View</div>

            <svg
              width="10"
              height="13"
              viewBox="0 0 10 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                fill="#1F8505"
              />
            </svg>

            <div className="fs-base">
              Top 10 Performing MDAs By Revenue Collected
            </div>

            <div>
              <span className="data-range-text-overview  me-1 ms-2">
                Date Range:
              </span>

              <RangePicker
                className="py-1 fs-8 fw-bold datepicker w-250px"
                value={dateRange}
                onChange={handleDateRangeChange}
                allowClear
                size={"small"}
              />
            </div>
          </div>

          <div className={"topMdaRow2"}>
            <div>TOP 10 PERFORMING MDAs BY REVENUE COLLECTED</div>

            <div className="d-flex justify-content-end  mb-9 me-4">
              <Download
                dataSourceForExcel={dataSourceForExcel}
                dataSource={dataSource}
                excelFileTitle={"TOP 10 PERFORMING MDAs BY REVENUE COLLECTED"}
                pdfFileTitle={"TOP 10 PERFORMING MDAs BY REVENUE COLLECTED"}
                excelPageTitle={"TOP 10 PERFORMING MDAs BY REVENUE COLLECTED"}
                grandTotalFor={{
                  transactionAmount: "Transaction Amount",
                  processingFee: "Processing Fee",
                  vatProcessingFee: "VAT Processing Fee",
                  totalProcessingFee: "Total Processing Fee",
                  netAmount: "Net Amount",
                  cumulativeNetAmount: "Cumulative Net Amount",
                }}
              />
            </div>

            <div>
              <div>TOTAL:</div>
              <div> &#x20A6;{totalAmount?.toLocaleString()} </div>
            </div>

            <Table
              dataSource={dataSource}
              bordered
              columns={columns}
              scroll={{ x: "600px" }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default TopMda;
