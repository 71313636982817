import htmlToPdfmake from "html-to-pdfmake";
import pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { ErrorToast } from "../componets/toast";
// import { TDocumentDefinitions, PageOrientation } from "pdfmake/interfaces";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export function exportToPDF(data: any, title?: string) {
  if (data.length === 0) {
    ErrorToast("No records are available to generate a PDF");
    return;
  }

  data.forEach(obj => {
    if (obj.hasOwnProperty('key')) {
      delete obj['key'];
    }
  });

  const tableHeader = Object.keys(data[0]).map((word) =>
    word.replace(/([a-z])([A-Z])/g, "$1 $2").toUpperCase()
  );

  const headerHtmlCode = tableHeader
    .map((r) => `<th><span>${r}</span></th>`)
    .join("");

  const recordHtmlCode = data
    .map((res) => {
      const singleRecordHTMLString = Object.entries(res)
        .map((r) => `<td><span>${r[1]}</span></td>`)
        .join("");

      return `<tr>${singleRecordHTMLString}</tr>`;
    })
    .join("");

  const htmlCode = `
    <h1 style="color: #1F8505; margin: 50px; text-align: center">
      ${title?.split('-')[0] ? title.split('-')[0] : ""}
    </h1>
    <h2 style="color: #1F8505; text-align: center">
      ${title?.split('-')[1] ? title.split('-')[1] : ""}
    </h2>
    <table>
      <thead>
        <tr>${headerHtmlCode}</tr>
      </thead>
      <tbody>${recordHtmlCode}</tbody>
    </table>
  `;

  const pdfContent = htmlToPdfmake(htmlCode);

  const font = "14px Helvetica";
  const paperLongSide = Math.ceil(getPixelWidth(tableHeader.join(", "), font));

  const documentDefinition: any = {
    pageSize: {
      width: paperLongSide,
      height: paperLongSide * 0.6,
    },
    pageOrientation: "landscape",
    content: pdfContent,
  };

  pdfMake
    .createPdf(documentDefinition)
    .download(title ? title : "download.pdf");
}

function getPixelWidth(text, font) {
  const canvas: any = document.createElement("canvas");
  const context: any = canvas.getContext("2d");

  context.font = font;

  const metrics = context.measureText(text);

  return metrics.width;
}
