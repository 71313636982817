import React from "react";
import {Tabs} from "antd";
import EditExistingUser from "./EditExistingUser";


const EditExistingUserCard = () => {



    return (
            <div className={'userManagementTab'}>
                <Tabs>
                    <Tabs.TabPane tab="Edit Existing User Details" key="edit-user">
                        <EditExistingUser/>
                    </Tabs.TabPane>
                </Tabs>
            </div>
    );
};

export default EditExistingUserCard;
