import { FlapperSpinner } from "react-spinners-kit";
import { Layout } from "../../../layout";
import {
  useCollectionAnalytics,
  useCollectionAnalyticsServices,
} from "../../../../auth/hooks/useUser";
import { DatePicker, Select } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  DoughnutController,
} from "chart.js";
import { faker } from "@faker-js/faker";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { getWeekRange, isOagfAdmin } from "../../../../../../core/helpers";

const { RangePicker } = DatePicker;

ChartJS.register(CategoryScale, LinearScale, BarElement, DoughnutController);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  },
};

const labels = [
  "Equipment",
  "Fertilizer",
  "Farm Inputs",
  "Chemicals",
  "Drugs",
  "Laboratory",
  "Pesticide",
  "Insecticide",
  "Pesticide",
  "Processing Fee",
];

// const doughnutData = {
//     labels: ["Red"],
//     datasets: [
//         {
//             data: [19],
//             backgroundColor: ["#3EC5E3"],
//             hoverBackgroundColor: ["#3EC5E3"],
//             borderWidth: 1,
//         },
//     ],
// };

const dateFormat = "YYYY/MM/DD";
const MdaCollectionView = () => {
  const navigate = useNavigate();

  const { data: collectionData, mutate, isLoading } = useCollectionAnalytics();

  const {
    data: servicesData,
    mutate: servicesMutate,
    isLoading: isLoadingMutate,
  } = useCollectionAnalyticsServices();

  const [dateRange, setDateRange] = useState<any>([]);
  const [dateRange2, setDateRange2] = useState<any>([]);
  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      setDateRange(dates);
      var date1 = moment(new Date(dates[0]).toISOString()).format("YYYY-MM-DD");
      var date2 = moment(new Date(dates[1]).toISOString()).format("YYYY-MM-DD");
      mutate({ startDate: date1, endDate: date2 });
      servicesMutate({ startDate: date1, endDate: date2 });
    } else {
      setDateRange([]);
    }
  };

  // const handleDateRangeChange2 = (dates) => {
  //   if (dates && dates.length === 2) {
  //     setDateRange2(dates);
  //     var date1 = moment(new Date(dates[0]).toISOString()).format("YYYY-MM-DD");
  //     var date2 = moment(new Date(dates[1]).toISOString()).format("YYYY-MM-DD");
  //     servicesMutate({ startDate: date1, endDate: date2 });
  //   } else {
  //     setDateRange([]);
  //   }
  // };

  useEffect(() => {
    const { startDate, endDate } = getWeekRange();
    setDateRange([dayjs(startDate), dayjs(endDate)]);
    setDateRange2([dayjs(startDate), dayjs(endDate)]);

    const startDateData = moment(startDate).format("YYYY-MM-DD");
    const endDateData = moment(endDate).format("YYYY-MM-DD");

    mutate({ startDate: startDateData, endDate: endDateData });
    servicesMutate({ startDate: startDateData, endDate: endDateData });
  }, []);

  const [serviceName, setServiceName] = useState([]);

  const extractServiceName = () => {
    const serviceName = servicesData?.map((item) => item.serviceName);
    setServiceName(serviceName);
  };

  const [amount, setAmount] = useState([]);

  const extractAmount = () => {
    const amount = servicesData?.map((item) => item.amount);
    setAmount(amount);
  };

  useEffect(() => {
    extractServiceName();
    extractAmount();
  }, [servicesData]);

  const data = {
    labels: serviceName,
    datasets: [
      {
        label: "Top 10 services",
        data: amount,
        borderColor: "#A5CE9B",
        pointRadius: 0,
        backgroundColor: "#A5CE9B", // set the backgroundColor property to transparent to remove the background lines
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      {isLoading || isLoadingMutate ? (
        <div className="loading-container">
          <FlapperSpinner /> {/* Replace with your loading indicator */}
        </div>
      ) : (
        <div className={"collectionView"}>
          <div className={"collectionViewRow1"}>
            <div className={"collectionViewBreadcrumb"}>
              <div>Analytics</div>

              <svg
                width="10"
                height="13"
                viewBox="0 0 10 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                  fill="#1F8505"
                />
              </svg>

              <div onClick={() => navigate("collectionView")}>
                Collection View
              </div>
            </div>

            <div>
              <div className="mx-1">
                {/* <Select
                  style={{ width: 150 }}
                  options={[
                    { value: "Last 30 Days", label: "Last 30 Days" },
                    { value: "Last 60 Days", label: "Last 60 Days" },
                    { value: "Last 90 Days", label: "Last 90 Days" },
                  ]}
                  defaultValue={"Last 30 Days"}
                  dropdownMatchSelectWidth={false}
                  size="large"
                /> */}
              </div>

              <div>
                <span className="data-range-text-overview">Date Range:</span>
                <span className="data-from-text-overview">From</span>

                <RangePicker
                  className="py-1 fs-8 fw-bold datepicker w-250px"
                  value={dateRange}
                  onChange={handleDateRangeChange}
                  allowClear
                  size={"small"}
                />
              </div>
            </div>
          </div>

          <div className={"collectionViewRow2"}>
            <div style={{ background: "#E7F7FB", border: "1px solid #3EC5E3" }}>
              <div onClick={() => navigate("outstanding-payment")}>
                View Report
              </div>
              <div>{/* graph */}</div>
              <div>
                {" "}
                &#x20A6;
                {collectionData?.outstandingInflows?.amount.toLocaleString()}{" "}
              </div>
              <div>
                <div>
                  {collectionData?.outstandingInflows?.transactionCount}
                </div>
                <div>transactions</div>
              </div>
              <div>Outstanding Payments</div>
              <div>
                This represents all transactions that the invoices have been
                generated but have not been processed by an approved PSSP
              </div>
            </div>

            <div style={{ background: "#FDF7E7", border: "1px solid #FBCB38" }}>
              <div onClick={() => navigate("pending-inflows")}>View Report</div>
              <div>{/* graph */}</div>
              <div>
                &#x20A6;
                {collectionData?.pendingInflows?.amount.toLocaleString()}
              </div>
              <div>
                <div>{collectionData?.pendingInflows?.transactionCount}</div>
                <div>transactions</div>
              </div>
              <div>Pending Inflows</div>
              <div>
                This represents all transactions that have been processed by an
                approved PSSP but the funds are not yet settled into the MDA’s
                account
              </div>
            </div>

            <div style={{ background: "#EDF9F1", border: "1px solid #A5CE9B" }}>
              <div onClick={() => navigate("payments-received")}>
                View Report
              </div>
              <div>{/* graph */}</div>
              <div>
                &#x20A6;
                {collectionData?.receivedInflows?.amount.toLocaleString()}
              </div>
              <div>
                <div>{collectionData?.receivedInflows?.transactionCount}</div>
                <div>transactions</div>
              </div>
              <div>Payments Received</div>
              <div>
                This represents all transactions that have been processed by an
                approved PSSP and the funds have successfully been settled into
                the MDA’s account
              </div>
            </div>

            <div style={{ background: "#FDE7E8", border: "1px solid #EE151D" }}>
              <div onClick={() => navigate("rejected-item")}>View Report</div>
              <div>{/* graph */}</div>
              <div>
                &#x20A6;
                {collectionData?.rejectedInflows?.amount.toLocaleString()}
              </div>
              <div>
                <div>{collectionData?.rejectedInflows?.transactionCount}</div>
                <div>transactions</div>
              </div>
              <div>Rejected Items</div>
              <div>
                This represents all outstanding transactions that have been
                truncated so that it can’t undergo payment processing
              </div>
            </div>
          </div>

          <div className={"collectionViewRow3"}>
            <div>Bar Chart for Top 10 Services by Revenue Collected</div>

            <div>
              <div>
                <div className="mx-1">
                  {/* <Select
                    style={{ width: 150 }}
                    options={[
                      { value: "Last 30 Days", label: "Last 30 Days" },
                      { value: "Last 60 Days", label: "Last 60 Days" },
                      { value: "Last 90 Days", label: "Last 90 Days" },
                    ]}
                    defaultValue={"Last 30 Days"}
                    dropdownMatchSelectWidth={false}
                    size="large"
                  /> */}
                </div>

                <div>
                  {/* <span className="data-range-text-overview">Date Range:</span>
                  <span className="data-from-text-overview">From</span>

                  <RangePicker
                    className="py-1 fs-8 fw-bold datepicker w-250px"
                    value={dateRange2}
                    onChange={handleDateRangeChange2}
                    allowClear
                    size={"small"}
                  /> */}
                </div>
              </div>

              <div>
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="24"
                    height="24"
                    transform="translate(0.5 0.5)"
                    fill="#D2E7CD"
                  />
                  <path
                    d="M20.75 14.75V20C20.75 20.1989 20.671 20.3897 20.5303 20.5303C20.3897 20.671 20.1989 20.75 20 20.75H5C4.80109 20.75 4.61032 20.671 4.46967 20.5303C4.32902 20.3897 4.25 20.1989 4.25 20V14.75M8.5625 10.8135L12.5 14.75L16.4375 10.8135M12.5 4.25V14.7472"
                    stroke="#1F8505"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>

            <Bar options={options} data={data} />
          </div>

          <div>{/*<Doughnut data={doughnutData} options={options} />*/}</div>
        </div>
      )}
    </>
  );
};

export default MdaCollectionView;
