import type { FormInstance } from "antd";
import { Button, Form } from "antd";
import { isEmpty } from "core/helpers";
import React, { useEffect, useState } from "react";


const FormSubmitButton = ({
  form,
  submitProp,
  classname,
  otherDisable,
  width,
}: {
  form: FormInstance;
  submitProp?: any;
  classname?: any;
  otherDisable?: boolean;
  width?: any;
}) => {
  const [submittable, setSubmittable] = React.useState(false);

  // Watch all values
  const values = Form.useWatch([], form);

  React.useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
         console.log('Yes');
        setSubmittable(true);
      },
      () => {
        console.log('No');
        setSubmittable(false);
      }
    );
  }, [values, form]);

  const [windowSize, setWindowSize] = useState<{
    width: number;
    height: number;
  }>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  console.log(!submittable );
  
  

  return (
    <Button
      htmlType="submit"
      disabled={!submittable || !!otherDisable}
      className={`${
        width ? width : windowSize.width > 540 ? "w-350px" : "w-250px"
      } ${classname}`}
    >
      {submitProp || <span>Proceed</span>}
    </Button>
  );
};
export { FormSubmitButton };

