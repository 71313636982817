import React, {useState} from 'react';
import '../../../../../../App.css';
import {useNavigate} from "react-router-dom";




const ContractPayment = () => {
    const [selectedOption, setSelectedOption] = useState("contract-single-key-in");

    const navigate = useNavigate();

    const handleSubmit = () => {
        if (selectedOption === "contract-single-key-in") {
            navigate("/admin/payment/tax-payments/contract/single");
        } else if (selectedOption === "contract-bulk-upload") {
            navigate("/admin/payment/tax-payments/contract/bulk");
        }
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };


    return (
        <>
            <div className={'vatPayment'}>
                <div className={'vatPaymentBreadcrumb'}>
                    <div>Payments</div>
                    <svg
                        width="10"
                        height="13"
                        viewBox="0 0 10 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                            fill="#1F8505"
                        />
                    </svg>
                    <div>Tax Payments</div>

                    <svg
                        width="10"
                        height="13"
                        viewBox="0 0 10 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                            fill="#1F8505"
                        />
                    </svg>

                    <div>Contract Payment and Taxes</div>
                </div>

                <div className={'vatPaymentRow1'}>
                    <div className={'payValueDiv'}>
                        <div onClick={() =>  navigate("/admin/payment/tax-payments")}>
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_7037_76293)">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M18 11.8125H9.63312L13.4763 7.96937L12.5 7L7 12.5L12.5 18L13.4694 17.0306L9.63312 13.1875H18V11.8125Z"
                                          fill="#1F8505"/>
                                </g>
                                <rect x="0.75" y="1.25" width="22.5" height="22.5" rx="11.25" stroke="#1F8505"
                                      stroke-width="1.5"/>
                                <defs>
                                    <clipPath id="clip0_7037_76293">
                                        <rect y="0.5" width="24" height="24" rx="12" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>

                        <div>Make Contract Payment with Taxes</div>
                    </div>

                    <div className={'selectMethod'}>Select method of upload</div>

                    <div className={'methodOfUpload'}>
                        <div>
                            <label
                                style={{
                                    backgroundColor: selectedOption === "contract-single-key-in" ? '#EDF9F1' : '#FFFFFF',
                                    border: selectedOption === "contract-single-key-in" ? '1px solid #1F8505' : '1px solid #CCCCCC',
                                }}
                                onClick={() => {
                                    if (selectedOption !== "contract-single-key-in") {
                                        setSelectedOption("contract-single-key-in");
                                    }
                                }}
                            >
                                <div className={'inputDiv'}>
                                    <input
                                        style={{
                                            marginRight: '15px',
                                        }}
                                        type="radio"
                                        value="contract-single-key-in"
                                        checked={selectedOption === "contract-single-key-in"}
                                        onChange={handleOptionChange}
                                    />
                                </div>

                                <div>
                                    <div>
                                        {selectedOption === "contract-single-key-in" ? (
                                            <div>
                                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2.5 22.5L20 32.5L37.5 22.5M2.5 16.25L20 26.25L37.5 16.25L20 6.25L2.5 16.25Z" stroke="#1F8505" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </div>
                                        ) : (
                                            <div>
                                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2.5 22.5L20 32.5L37.5 22.5M2.5 16.25L20 26.25L37.5 16.25L20 6.25L2.5 16.25Z" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </div>
                                        )}
                                    </div>

                                    <div className={'pensionType'}
                                         style={{
                                             fontWeight: selectedOption === "contract-single-key-in" ? 500 : 400,
                                             color: selectedOption === "contract-single-key-in" ? '#1F8505' : '#666666',
                                         }}
                                    >
                                        Single Payment
                                    </div>
                                </div>
                            </label>
                        </div>

                        <div>
                            <label
                                style={{
                                    backgroundColor: selectedOption === "contract-bulk-upload" ? '#EDF9F1' : '#FFFFFF',
                                    border: selectedOption === "contract-bulk-upload" ? '1px solid #1F8505' : '1px solid #CCCCCC',
                                }}
                                onClick={() => {
                                    if (selectedOption !== "contract-bulk-upload") {
                                        setSelectedOption("contract-bulk-upload");
                                    }
                                }}
                            >
                                <div className={'inputDiv'}>
                                    <input
                                        style={{
                                            marginRight: '15px',
                                        }}
                                        type="radio"
                                        value="contract-bulk-upload"
                                        checked={selectedOption === "contract-bulk-upload"}
                                        onChange={handleOptionChange}
                                    />
                                </div>

                                <div>
                                    <div>
                                        {selectedOption === "contract-bulk-upload" ? (
                                            <div>
                                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5 27.5L20 36.25L35 27.5M5 20L20 28.75L35 20M5 12.5L20 21.25L35 12.5L20 3.75L5 12.5Z" stroke="#1F8505" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </div>
                                        ) : (
                                            <div>
                                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5 27.5L20 36.25L35 27.5M5 20L20 28.75L35 20M5 12.5L20 21.25L35 12.5L20 3.75L5 12.5Z" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </div>
                                        )}
                                    </div>

                                    <div className={'pensionType'}
                                         style={{
                                             fontWeight: selectedOption === "contract-bulk-upload" ? 500 : 400,
                                             color: selectedOption === "contract-bulk-upload" ? '#1F8505' : '#666666',
                                         }}
                                    >
                                        Multiple Payment
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>

                    <div className={'vatProceedButton'}>
                        <button onClick={handleSubmit}>
                            Proceed
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContractPayment;
