import { isOagfAdmin } from "../../../../../core/helpers";
import MdaPaymentsReceived from "./MDA/MdaPaymentsReceived";
import OagfPaymentsReceived from "./OAGF/OagfPaymentsReceived";

const PaymentsReceived = () => {
  return (
    <>{isOagfAdmin() ? <OagfPaymentsReceived /> : <MdaPaymentsReceived />}</>
  );
};

export  {PaymentsReceived};
