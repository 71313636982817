import {Download} from "../../../../componets/download";
import {Table} from "antd";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useFormik} from "formik";


const initialValues = {
    roleName: "",
};
export const Edit = () => {
    const [selectedOption, setSelectedOption] = useState("");


    const navigate = useNavigate();

    const formik = useFormik({
        initialValues,
        // validationSchema: Schema,
        onSubmit: async (values) => {},
    });


    const handleOptionChange = (event) => {
        const value = event.target.value;
        setSelectedOption(value);

        // Show the dropdown corresponding to the selected radio button
        // setDropdowns(prevDropdowns => ({
        //     ...prevDropdowns,
        //     [value]: true
        // }));
    };

    const handleCheckboxChange = (event) => {
        // Handle checkbox changes
    };




    return (
        <>
            <div className={'editRoleView'}>
                <div className={"editRoleViewBreadcrumb"}>
                    <div>Setup & Administration</div>

                    <svg width="10" height="13" viewBox="0 0 10 13" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                            fill="#1F8505"/>
                    </svg>

                    <div>Role/Resource Management</div>

                    <svg width="10" height="13" viewBox="0 0 10 13" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                            fill="#1F8505"/>
                    </svg>

                    <div>Edit Role</div>
                </div>

                <div className={'editRoleForm'}>
                    <div className={'editRoleTextDiv'}>
                        <div onClick={() =>  navigate("/admin/setup-administration/role-resource-management")}>
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_7037_76293)">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M18 11.8125H9.63312L13.4763 7.96937L12.5 7L7 12.5L12.5 18L13.4694 17.0306L9.63312 13.1875H18V11.8125Z"
                                          fill="#1F8505"/>
                                </g>
                                <rect x="0.75" y="1.25" width="22.5" height="22.5" rx="11.25" stroke="#1F8505"
                                      stroke-width="1.5"/>
                                <defs>
                                    <clipPath id="clip0_7037_76293">
                                        <rect y="0.5" width="24" height="24" rx="12" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>

                        <div className={'editRoleText'}>EDIT ROLE</div>
                    </div>

                    <div className={'roleName'}>
                        <div className={`mt-1`}>
                            <div className="col-lg-4 col-form-label py-0 input-height">
                                <label>Role Name </label>
                            </div>

                            <div className={`form-control form-control-solid`}>
                                <input
                                    className={`no-outline fs-8 ${
                                        formik.values.roleName  !== ""
                                            ? "text-dark"
                                            : "text-muted"
                                    } `}
                                    {...formik.getFieldProps("roleName")}
                                    placeholder="Role Name"
                                ></input>
                            </div>
                        </div>
                    </div>

                    <div className={'resourceText'}>Resources </div>

                    <div className={'selectResourceText'}>Select resources to assign to the role</div>

                    <div className={'allResourceDiv'}>
                        <div className={'allResourcesText'}>ALL RESOURCES</div>

                        <div className={'editRoleCheckbox'}>
                            <label>
                                <input
                                    style={{marginRight: '15px'}}
                                    type="checkbox"
                                    value="dashboard-cash-summary"
                                    checked={selectedOption === "dashboard-cash-summary"}
                                    onChange={handleOptionChange}
                                />
                                Dashboard Cash Summary
                            </label>

                            <label>
                                <input
                                    style={{marginRight: '15px'}}
                                    type="checkbox"
                                    value="corporate-profile"
                                    checked={selectedOption === "corporate-profile"}
                                    onChange={handleOptionChange}
                                />
                                Corporate Profile
                            </label>

                            <label>
                                <input
                                    style={{marginRight: '15px'}}
                                    type="checkbox"
                                    value="user-management"
                                    checked={selectedOption === "user-management"}
                                    onChange={handleOptionChange}
                                />
                                User Management
                            </label>

                            <label>
                                <input
                                    style={{marginRight: '15px'}}
                                    type="checkbox"
                                    value="workflow-management"
                                    checked={selectedOption === "workflow-management"}
                                    onChange={handleOptionChange}
                                />
                                Workflow Management
                            </label>

                        </div>
                    </div>

                    <div className={'editRolePreviewButton'}>
                        <button>Preview</button>
                    </div>
                </div>
            </div>
        </>
    );
};
