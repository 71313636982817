import React, {useEffect, useState} from "react";
import {FlapperSpinner} from "react-spinners-kit";
import {useMdaActivate, useMdaDetails, useGetRoleList} from "../../../auth/hooks/useUser";
import {useFormik} from "formik";
import {RoleList} from "./RoleList";
import {useNavigate} from "react-router-dom";
import {Create} from "./Create";
import {Edit} from "./Edit";


const initialValues = {
    briefDescription: "",
};

const RoleResourceManagement = () => {
    const [showPage, setShowPage] = useState('table');
    const [selectedOption, setSelectedOption] = useState("");
    const [dropdowns, setDropdowns] = useState({
        // "mda-to
    });


    const navigate = useNavigate();





    const {
        data,
        mutate,
        isLoading,
    } = useGetRoleList();


        useEffect(() => {
        mutate()
    }, [])



    const formik = useFormik({
        initialValues,
        // validationSchema: Schema,
        onSubmit: async (values) => {},
    });


    const dataSource: any[] = [];
    const dataSourceForExcel: any[] = [
        [],
    ];


    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: "30",
        },
        {
            title: "Roles",
            dataIndex: "roles",
            key: "roles",
            width: "180",
        },
        {
            title: "Action",
            dataIndex: "action",
            key: 'action',
            render: (_: any, {action}: any) => (
                <>
                    <button
                        className="edit-button"
                        onClick={() => handleActionClick('edit')}
                    >
                        Edit
                    </button>
                </>
            ),
        },
    ];

    for (let i = 0; i < data?.length; i++) {

        console.log(data[i]);
        
        const row = {
            id: i,
            roles: data[i]?.name,
            action: `Edit`,
        };
        dataSource.push(row);
    }

    const handleActionClick = (view) => {
        setShowPage(view);
        // Handle click event for Action SVG// You can perform further actions here
        // setUser(action);
    };


    const handleOptionChange = (event) => {
        const value = event.target.value;
        setSelectedOption(value);

        // Show the dropdown corresponding to the selected radio button
        setDropdowns(prevDropdowns => ({
            ...prevDropdowns,
            [value]: true
        }));
    };

    const handleCheckboxChange = (event) => {
        // Handle checkbox changes
    };



    return (
        <>
            {isLoading  ? (
                <div className="loading-container">
                    <FlapperSpinner/> {/* Replace with your loading indicator */}
                </div>
            ) : (
                <>
                    <div className={"roleResourceManagement"}>
                        {showPage === 'table' ? (
                            <RoleList dataSourceForExcel={dataSourceForExcel} dataSource={dataSource} columns={columns} handleActionClick={handleActionClick} />
                        ) : showPage === 'edit' ? (
                            <Edit />
                        ) : showPage === 'create' ? (
                            <Create />
                        ) : showPage === 'preview' ? (
                            <div className={'editRoleView'}>
                                <h1>Preview</h1>
                            </div>
                        ) : null}
                    </div>
                </>
            )}
        </>
    );
};

export default RoleResourceManagement;
