import { DatePicker, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { FlapperSpinner } from "react-spinners-kit";
import {
  useMdapayables,
  useMdaPayablesData,
  usePayablesDue,
  usePayablesOverdue,
  usePayablesReportDue,
  usePayablesReportOverdue,
} from "../../../../auth/hooks/useUser";
import dayjs from "dayjs";
import PayablesDue from "./MdaPayablesDue";
import PayablesOverdue from "./MdaPayablesOverdue";
import moment from "moment";
import {
  FormatMoney,
  NoCurrencyFormatMoney,
  getWeekRange,
} from "../../../../../../core/helpers";

const { RangePicker } = DatePicker;

const MdaPayable = () => {
  const [dateRange, setDateRange] = useState<any>([]);
  const { data, mutate, isLoading } = useMdapayables();

  // const {
  //   data: payablesData,
  //   mutate: payablesMutate,
  //   isLoading: payablesDueLoading,
  // } = useMdaPayablesData();

  // const {
  //   data: dataT,
  //   mutate: mutateT,
  //   isLoading: isLoadingT,
  // } = useMdapayables();

  const {
    data: payablesDueData,
    mutate: payablesDueMutate,
    isLoading: payablesDueLoading,
  } = usePayablesDue();

  const {
    data: payablesOverdueData,
    mutate: payablesOverdueMutate,
    isLoading: payablesOverdueLoading,
  } = usePayablesOverdue();

  const {
    data: DueReportData,
    mutate: DueReportMutate,
    isLoading: DueReportLoading,
  } = usePayablesReportDue();

  const {
    data: OverdueReportData,
    mutate: OverdueReportMutate,
    isLoading: OverdueReportLoading,
  } = usePayablesReportOverdue();
  useEffect(() => {
    const { startDate, endDate } = getWeekRange();
    setDateRange([dayjs(startDate), dayjs(endDate)]);
    const startDateData = moment(startDate).format("YYYY-MM-DD");
    const endDateData = moment(endDate).format("YYYY-MM-DD");
    // payablesMutate(`${startDateData}/${endDateData}`);
    payablesDueMutate();
    payablesOverdueMutate();
    DueReportMutate();
    OverdueReportMutate();
    // mutate(false);
    // mutateT(true);
  }, []);

  // const handleDateRangeChange = (dates) => {
  //   if (dates && dates.length === 2) {
  //     setDateRange(dates);
  //     var date1 = moment(new Date(dates[0]).toISOString()).format("YYYY-MM-DD");
  //     var date2 = moment(new Date(dates[1]).toISOString()).format("YYYY-MM-DD");
  //     // payablesMutate({ startDate: date1, endDate: date2 });

  //     payablesDueMutate();
  //     payablesOverdueMutate();
  //   } else {
  //     setDateRange([]);
  //   }
  // };

  return (
    <>
      {isLoading ||
      // isLoadingT ||
      payablesDueLoading ||
      payablesOverdueLoading ||
      OverdueReportLoading ||
      DueReportLoading ? (
        <div className="loading-container">
          <FlapperSpinner /> {/* Replace with your loading indicator */}
        </div>
      ) : (
        <>
          <div className={"mdaPayable"}>
            <div className={"mdaPayableRow1"}>
              <div className={"mdaPayableBreadcrumb"}>
                <div>Analytics</div>

                <svg
                  width="10"
                  height="13"
                  viewBox="0 0 10 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                    fill="#1F8505"
                  />
                </svg>

                <div>Payables</div>
              </div>
              <div>
                {/* <span className="data-range-text-overview">Date Range:</span>
                <RangePicker
                  className="py-1 fs-8 fw-bold datepicker w-250px"
                  value={dateRange}
                  onChange={handleDateRangeChange}
                  allowClear
                  size={"small"}
                /> */}
              </div>
            </div>

            <div className={"mdaPayableRow2"}>
              <div>
                <div>Payable Due</div>
                <div>&lt;=30 Days</div>
                <div>
                  <svg
                    width="259"
                    height="65"
                    viewBox="0 0 259 65"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      x1="4.37114e-08"
                      y1="64.5"
                      x2="259"
                      y2="64.5"
                      stroke="#F4F4F4"
                    />
                    <line
                      x1="4.37114e-08"
                      y1="48.5"
                      x2="259"
                      y2="48.5"
                      stroke="#F4F4F4"
                    />
                    <line
                      x1="4.37114e-08"
                      y1="32.5"
                      x2="259"
                      y2="32.5"
                      stroke="#F4F4F4"
                    />
                    <line
                      x1="4.37114e-08"
                      y1="16.5"
                      x2="259"
                      y2="16.5"
                      stroke="#F4F4F4"
                    />
                    <line
                      x1="4.37114e-08"
                      y1="0.5"
                      x2="259"
                      y2="0.500023"
                      stroke="#F4F4F4"
                    />
                    <path
                      d="M1.5 63.999C21.5 55.999 48.6667 56.6657 52.5 59.999C63.7 66.7988 81.1667 52.3325 86.5 46.4992C98.9 35.6992 111.333 41.9992 116 46.4992C125.2 53.6994 135.5 49.4992 139.5 46.4992C156.7 31.6997 172.667 40.3327 178.5 46.4992C193.7 57.2994 208.167 50.9993 213.5 46.4992C229.9 30.8994 239 29.6661 241.5 30.9995"
                      stroke="#1F8505"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
                <div>
                  <div>
                    {payablesDueData
                      ? payablesDueData[0]?.numberOfTransactions
                      : 0}{" "}
                  </div>

                  <div>Transactions</div>
                </div>
                <div>
                  This represents all MDA payables within a 30day duration.
                </div>
              </div>

              <div>
                <div>Payable Overdue</div>

                <div>&gt;30 Days</div>

                <div>
                  <svg
                    width="259"
                    height="65"
                    viewBox="0 0 259 65"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      x1="4.37114e-08"
                      y1="64.5"
                      x2="259"
                      y2="64.5"
                      stroke="#F4F4F4"
                    />
                    <line
                      x1="4.37114e-08"
                      y1="48.5"
                      x2="259"
                      y2="48.5"
                      stroke="#F4F4F4"
                    />
                    <line
                      x1="4.37114e-08"
                      y1="32.5"
                      x2="259"
                      y2="32.5"
                      stroke="#F4F4F4"
                    />
                    <line
                      x1="4.37114e-08"
                      y1="16.5"
                      x2="259"
                      y2="16.5"
                      stroke="#F4F4F4"
                    />
                    <line
                      x1="4.37114e-08"
                      y1="0.5"
                      x2="259"
                      y2="0.500023"
                      stroke="#F4F4F4"
                    />
                    <path
                      d="M3 45.499C17.5 33.499 48.1667 32.6657 52 35.999C63.2 42.7988 83.1667 34.3325 88.5 28.4992C100.9 17.6992 113.333 23.9992 118 28.4992C127.2 35.6994 137.5 31.4992 141.5 28.4992C158.7 13.6997 174.667 22.3327 180.5 28.4992C195.7 39.2994 210.167 32.9993 215.5 28.4992C231.9 12.8994 241 11.6661 243.5 12.9995"
                      stroke="#EE151D"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>

                <div>
                  <div>
                    {payablesOverdueData
                      ? payablesOverdueData[0]?.numberOfTransactions
                      : 0}
                  </div>

                  <div>Transactions</div>
                </div>

                <div>
                  This represents all MDA payables beyond a 30day duration.
                </div>
              </div>

              <div>
                <div>
                  <div>Total Payables Due</div>

                  <div>
                    <div>
                      <div>
                        <svg
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="36" height="36" rx="5" fill="#D2E7CD" />
                          <path
                            d="M18 20.25V24.75M18 20.25C23.3848 20.25 27.75 18.2353 27.75 15.75M18 20.25C12.6152 20.25 8.25 18.2353 8.25 15.75M18 24.75C12 24.75 8.25 22.5 8.25 20.25V15.75M18 24.75C24 24.75 27.75 22.5 27.75 20.25V15.75M27.75 15.75C27.75 13.2647 23.3848 11.25 18 11.25C12.6152 11.25 8.25 13.2647 8.25 15.75M24 19.3225V23.8226M12 19.3225V23.8226"
                            stroke="#1F8505"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>

                      <div>
                        <div>
                          {payablesDueData
                            ? NoCurrencyFormatMoney(
                                payablesDueData[0]?.totalAmount
                              )
                            : 0}
                        </div>
                        <div>Naira</div>
                      </div>
                    </div>

                    <div>
                      <div>
                        <svg
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="36" height="36" rx="5" fill="#D2E7CD" />
                          <path
                            d="M13.4844 20.6516H8.98438M8.98438 20.6516V25.1516M8.98438 20.6516L12.1664 23.8336C12.9325 24.5997 13.842 25.2074 14.8429 25.622C15.8438 26.0366 16.9166 26.25 18 26.25C19.0834 26.25 20.1562 26.0366 21.1572 25.622C22.1581 25.2074 23.0676 24.5997 23.8337 23.8336M22.5157 15.3484H27.0157M27.0157 15.3484V10.8484M27.0157 15.3484L23.8337 12.1664C23.0676 11.4003 22.1581 10.7926 21.1572 10.378C20.1562 9.96339 19.0834 9.75 18 9.75C16.9166 9.75 15.8438 9.96339 14.8429 10.378C13.842 10.7926 12.9325 11.4003 12.1664 12.1664"
                            stroke="#1F8505"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>

                      <div>
                        <div>
                          {payablesDueData
                            ? payablesDueData[0]?.numberOfTransactions
                            : 0}
                        </div>
                        <div>Transactions</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div>Total Payables Overdue</div>

                  <div>
                    <div>
                      <div>
                        <svg
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="36" height="36" rx="5" fill="#FDE7E8" />
                          <path
                            d="M13.4844 20.6516H8.98438M8.98438 20.6516V25.1516M8.98438 20.6516L12.1664 23.8336C12.9325 24.5997 13.842 25.2074 14.8429 25.622C15.8438 26.0366 16.9166 26.25 18 26.25C19.0834 26.25 20.1562 26.0366 21.1572 25.622C22.1581 25.2074 23.0676 24.5997 23.8337 23.8336M22.5157 15.3484H27.0157M27.0157 15.3484V10.8484M27.0157 15.3484L23.8337 12.1664C23.0676 11.4003 22.1581 10.7926 21.1572 10.378C20.1562 9.96339 19.0834 9.75 18 9.75C16.9166 9.75 15.8438 9.96339 14.8429 10.378C13.842 10.7926 12.9325 11.4003 12.1664 12.1664"
                            stroke="#EE151D"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>

                      <div>
                        <div>
                          {payablesOverdueData
                            ? NoCurrencyFormatMoney(
                                payablesOverdueData[0]?.totalAmount
                              )
                            : 0}
                        </div>
                        <div>Naira</div>
                      </div>
                    </div>

                    <div>
                      <div>
                        <svg
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="36" height="36" rx="5" fill="#FDE7E8" />
                          <path
                            d="M18 20.25V24.75M18 20.25C23.3848 20.25 27.75 18.2353 27.75 15.75M18 20.25C12.6152 20.25 8.25 18.2353 8.25 15.75M18 24.75C12 24.75 8.25 22.5 8.25 20.25V15.75M18 24.75C24 24.75 27.75 22.5 27.75 20.25V15.75M27.75 15.75C27.75 13.2647 23.3848 11.25 18 11.25C12.6152 11.25 8.25 13.2647 8.25 15.75M24 19.3225V23.8226M12 19.3225V23.8226"
                            stroke="#EE151D"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>

                      <div>
                        <div>
                          {payablesOverdueData
                            ? payablesOverdueData[0]?.numberOfTransactions
                            : 0}
                        </div>
                        <div>Transactions</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={"mdaPayableRow3"}>Transaction History</div>

            <div className={"mdaPayableTabs"}>
              <Tabs>
                <Tabs.TabPane tab="Payables Due" key="payables-due">
                  <PayablesDue payablesDueData={DueReportData} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Payables Overdue" key="payables-overdue">
                  <PayablesOverdue payablesOverdue={OverdueReportData} />
                </Tabs.TabPane>
              </Tabs>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MdaPayable;
