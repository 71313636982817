import { DatePicker, Select, Space } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FlapperSpinner } from "react-spinners-kit";
import dayjs from "dayjs";
import moment from "moment";
import { play } from "../../../../../../core/assets/icons";
import {
  FetchAndUnmask,
  FormatDate,
  getStartAndEndOfDay,
  isEmpty,
} from "../../../../../../core/helpers";
import { useMdaTrail } from "../../../../auth/hooks/useUser";
import { Layout } from "../../../layout";
import { AuditTrail } from "./components/AuditTrail";

const { RangePicker } = DatePicker;

const UserReports = () => {
  const navigate = useNavigate();
  const [state, setState] = useState<number>(4);

  const [dateRange, setDateRange] = useState<any>([
    dayjs(new Date()),
    dayjs(new Date()),
  ]);

  const [beginDate, setBeginDate] = useState<any>("");
  const [stopDate, setStopDate] = useState<any>("");
  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      setDateRange(dates);
      const startDate = moment(new Date(dates[0]).toISOString()).format(
        "YYYY-MM-DDTHH:mm:ss"
      );

      const endDate = moment(new Date(dates[1]).toISOString()).format(
        "YYYY-MM-DDTHH:mm:ss"
      );
      setBeginDate(startDate);
      setStopDate(endDate);
      trailMutate(`start-date=${startDate}&end-date=${endDate}`);
    } else {
      setDateRange([]);
    }
  };
  const lazydayUser = JSON.parse(
    FetchAndUnmask("lazyday_user") as string
  );

  const {
    data: trailData,
    mutate: trailMutate,
    isLoading: trailLoading,
  } = useMdaTrail();
  useEffect(() => {
    if (state === 4) {
      const start = getStartAndEndOfDay().startDate;
      const end = getStartAndEndOfDay().endDate;
      setBeginDate(start);
      setStopDate(end);
      trailMutate(`start-date=${start}&end-date=${end}`);
    }
  }, [state]);

  return (
    <>
      <>
        {trailLoading ? (
          <div className="loading-container">
            <FlapperSpinner /> {/* Replace with your loading indicator */}
          </div>
        ) : (
          <div className={"businessPreference bg-admin-body"}>
            <div className={"breadcrumb d-flex justify-content-between"}>
              <div className="d-flex align-items-center">
                <div>Setup & Administration</div>
                <div className="mx-1">
                  <img src={play} />
                </div>
                <div className="mx-1">User/Workflow Reports</div>
              </div>
              {state === 4 && (
                <div className="d-flex align-items-center">
                  <div className="mx-1">
                    <Select
                      style={{ width: 130 }}
                      options={[
                        { value: "Last 30 Days", label: "Last 30 Days" },
                        { value: "Last 60 Days", label: "Last 60 Days" },
                        { value: "Last 90 Days", label: "Last 90 Days" },
                      ]}
                      // onChange={(e) => getAccountNumberDetails(e)}
                      defaultValue={"Last 30 Days"}
                      // value={selectedAcctNum}
                      dropdownMatchSelectWidth={false}
                      size="middle"
                    />
                  </div>

                  <div>
                    <span className="data-range-text-overview">
                      Date Range:
                    </span>
                    <span className="data-from-text-overview">From</span>
                    <Space direction="vertical" size={12}>
                      <RangePicker
                        showTime
                        className="py-2"
                        value={dateRange}
                        onChange={handleDateRangeChange}
                        allowClear
                      />
                    </Space>
                  </div>
                </div>
              )}
            </div>

            <div className="bg-white px-5 py-2">
              <div className="">
                <div className="dashboard-main-headers d-flex px-0 pt-3">
                  {/* Hide other menu oPTIONS */}
                  {/* <div
                    className={
                      state === 1
                        ? "dasboard-header-text-active"
                        : "dasboard-header-text"
                    }
                    onClick={() => setState(1)}
                  >
                    Process Types Report
                  </div>
                  <div
                    className={
                      state === 2
                        ? "dasboard-header-text-active"
                        : "dasboard-header-text"
                    }
                    onClick={() => setState(2)}
                  >
                    User List Report
                  </div>
                  <div
                    className={
                      state === 3
                        ? "dasboard-header-text-active"
                        : "dasboard-header-text"
                    }
                    onClick={() => setState(3)}
                  >
                    User Group Report
                  </div> */}

                  <div
                    className={
                      state === 4
                        ? "dasboard-header-text-active"
                        : "dasboard-header-text"
                    }
                    onClick={() => setState(4)}
                  >
                    Audit Trail Report
                  </div>
                </div>

                <div className={"mdaBalanceSheetPage bg-white p-0"}>
                  <div className={"mdaBalanceSheetRow3"}>
                    <div>
                      <span className="fs-5">
                        {lazydayUser?.registeredName}
                      </span>
                    </div>

                    <div className="my-3 d-flex justify-content-end">
                      <div>
                        <div>
                          <span className="fs-9 text-gray-500 pop">
                            Total Number of Records
                          </span>
                        </div>
                        <div>
                          <span className="fs-9 text-success pop fw-boldest">
                            {isEmpty(trailData)
                              ? 0
                              : state === 1
                              ? 0
                              : state === 2
                              ? 0
                              : state === 3
                              ? 0
                              : state === 4
                              ? trailData?.length
                              : 0}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="borderTwo py-3">
                      <div>
                        <div>Report Date & Time</div>
                        <div>
                          {moment(new Date().toISOString()).format(
                            "D MMM YYYY: h:mm a"
                          )}
                        </div>
                      </div>

                      <div>
                        <div>Administrative Code</div>
                        <div>{lazydayUser?.mdaAdminCode}</div>
                      </div>

                      <div>
                        <div>MDA Email</div>
                        <div> {lazydayUser?.contact?.email} </div>
                      </div>

                      <div>
                        <div>MDA Phone number</div>
                        <div>{lazydayUser?.contact?.phoneNumber}</div>
                      </div>
                    </div>
                  </div>

                  {/* {state === 1 && Corporate()}
                {state === 2 && Accounts()}
                {state === 3 && Team()} */}

                  {state === 4 && (
                    <AuditTrail
                      TableData={trailData}
                      mutate={trailMutate}
                      start={beginDate}
                      stop={stopDate}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default UserReports;
