import { isOagfAdmin } from "../../../../../core/helpers";
import MdaPaymentRejected from "./MDA/MdaPaymentRejected";
import OagfPaymentRejected from "./OAGF/OagfPaymentRejected";

const PaymentRejected = () => {
  return (
    <>{isOagfAdmin() ? <OagfPaymentRejected /> : <MdaPaymentRejected />}</>
  );
};

export { PaymentRejected };
