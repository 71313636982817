import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { lockSimple } from "../../../../core/assets/imgs";
import { useRequestOnboardingSendVerificatoinCode } from "../hooks/useUser";
import { ErrorToast } from "../../../componets/toast";
import { FetchAndUnmask, MaskAndSave } from "../../../../core/helpers";
import { Modal } from "react-bootstrap";
import { infoBlue } from "../../../../core/assets/icons";

const Schema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.?%!#-+=,><^()_/{}`~|:;])(?=.{8,})/,
      "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character."
    ),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.?%!#-+=,><^()_/{}`~|:;])(?=.{8,})/,
      "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character."
    ),
});

let stepJson: any = [];
let stepOneJson;

interface Step1Props {
  handleNextStep: () => void;
  handlePreviousStep: () => void;
}

const StepTwo: React.FC<Step1Props> = ({
  handleNextStep,
  handlePreviousStep,
}) => {
  const { data, mutate, isLoading } =
    useRequestOnboardingSendVerificatoinCode();
  var names: any = [];
  let tt: any;

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [showPasswordC, setShowPasswordC] = useState(false);

  const togglePasswordVisibilityC = () => {
    setShowPasswordC(!showPasswordC);
  };

  useEffect(() => {
    stepJson = FetchAndUnmask("names");

    stepOneJson = stepJson !== null ? JSON.parse(stepJson) : null;

    tt = stepOneJson[1] ? stepOneJson[1] : "";

    names = stepOneJson;
  }, [handleNextStep, data, names, tt]);

  const initialValues = {
    password: tt?.password ? String(tt?.password) : "",
    confirmPassword: tt?.password ? String(tt?.confirmPassword) : "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values) => {
      if (values.password !== values.confirmPassword) {
        ErrorToast("Passwords do not match");
      } else {
        names[1] = values;

        MaskAndSave("names", JSON.stringify(names));

        mutate(stepOneJson["0"].email, {
          onSuccess: (res: any) => {
            handleNextStep();
          },
        });
      }
    },
  });


        const [showConfirm, setConfrim] = useState<any>(false);


    const confrimModal = () => {
    return (
      <Modal
        dialogClassName="  m-auto px-5 modal-card"
        aria-hidden="true"
        show={showConfirm}
        onHide={() => setConfrim(false)}
        animation
        centered
        size="lg"
        backdrop={"static"}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <>
              <div className="m-3 mx-15">
                <div className=" d-flex flex-column align-items-center ">
                  <img src={infoBlue} alt="" className="w-50px h-50px my-5" />

                  <span className="text-center fs-base text-dark">
                    Are you sure you want to submit?
                  </span>
                </div>
                <div className="d-flex justify-content-between mt-10">
                  <button
                    id="kt_password_cancel "
                    type="button"
                    className="btn btn-danger px-7 py-2 text-alt-red h-44 w-110 center-all "
                    onClick={() => setConfrim(false)}
                  >
                    <span className="indicator-label fs-base ">Cancel</span>
                  </button>
                  <button
                    className=" btn btn-success h-44 w-110 center-all"
                    type="button"
                    onClick={() => formik.handleSubmit()}
                  >
                    <span className="indicator-label fs-base ">Submit</span>
                  </button>
                </div>
              </div>

         
          </>
        </Modal.Body>
      </Modal>
    );
  };


  return (
    <>
        <div>
          <span className="form-text">Password</span>

          <div className="mt-1">
            <div className="col-lg-4 col-form-label py-0 input-height">
              {formik.values.password.length !== 0 && (
                <label className="required fw-bold fs-9">Enter Password</label>
              )}
            </div>

            <div className="form-control form-control-solid  ">
              <img
                src={lockSimple}
                alt="confirmPassword"
                className="icon-style"
              />
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter Password * "
                className={"no-outline ps-4 text-dark fs-8"}
                {...formik.getFieldProps("password")}
              />
              <i
                className={`fas ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
                onClick={togglePasswordVisibility}
                style={{
                  marginLeft: "-30px",
                  cursor: "pointer",
                  color: "GrayText",
                }}
              ></i>
            </div>

            <div className="fv-plugins-message-container input-height">
              <div className="fv-help-block fs-9" style={{ color: "red" }}>
                {formik.touched.password &&
                  formik.errors.password &&
                  formik.errors.password}
              </div>
            </div>
          </div>

          <div className="mt-1">
            <div className="col-lg-4 col-form-label py-0 input-height">
              {formik.values.confirmPassword.length !== 0 && (
                <label className="required fw-bold fs-9">
                  Confirm Password
                </label>
              )}
            </div>

            <div className="form-control form-control-solid  ">
              <img
                src={lockSimple}
                alt="confirmPassword"
                className="icon-style"
              />

              <input
                type={showPasswordC ? "text" : "password"}
                placeholder="Confirm Password * "
                className={"no-outline ps-4 text-dark fs-8"}
                {...formik.getFieldProps("confirmPassword")}
              />

              <i
                className={`fas ${showPasswordC ? "fa-eye-slash" : "fa-eye"}`}
                onClick={togglePasswordVisibilityC}
                style={{
                  marginLeft: "-30px",
                  cursor: "pointer",
                  color: "GrayText",
                }}
              ></i>
            </div>

            <div className="fv-plugins-message-container input-height">
              <div className="fv-help-block fs-9" style={{ color: "red" }}>
                {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword &&
                  formik.errors.confirmPassword}
              </div>
            </div>
          </div>

          <div className="row mt-6">
            <div className="login-rem-grid-signup">
              <p>Two-Factor Authentication</p>
            </div>
          </div>

          <div className="row">
            <div className="login-rem-grid-signup">
              <div>
                <input
                  type="checkbox"
                  placeholder="termsCondtion*"
                  className=""
                  {...formik.getFieldProps("termsCondtion")}
                />

                <span
                  className="signup-remeber-text-step2"
                  style={{ marginLeft: "5px" }}
                >
                  Enable google authenticator for more security
                </span>
              </div>
            </div>
          </div>

          <span className="login_text_signup login-text mt-9">
            ?Don’t have the app
          </span>

          <span className="login-text mt-5 google-auth-text">
            Google Authenticator is a free 2FA app. Download it on your mobile
            device by searching for Google Authenticator on Play Store or Apple
            Store
          </span>

          <div className="login-text mt-2">
            <span>
              <span>
                <svg
                  width="13"
                  height="14"
                  viewBox="0 0 13 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 5.40686L11.9995 1.65736L8.25 1.65686M7.49821 6.15865L11.9982 1.65865M10 8.15686V12.1569C10 12.2895 9.94732 12.4166 9.85355 12.5104C9.75979 12.6042 9.63261 12.6569 9.5 12.6569H1.5C1.36739 12.6569 1.24021 12.6042 1.14645 12.5104C1.05268 12.4166 1 12.2895 1 12.1569V4.15686C1 4.02425 1.05268 3.89708 1.14645 3.80331C1.24021 3.70954 1.36739 3.65686 1.5 3.65686H5.5"
                    stroke="#CCCCCC"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>

              <a
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US&pli=1"
                target="blank"
                className="reg-play-store-text m-1"
              >
                Play Store
              </a>
            </span>
            <span>
              <span>
                <svg
                  width="13"
                  height="14"
                  viewBox="0 0 13 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 5.40686L11.9995 1.65736L8.25 1.65686M7.49821 6.15865L11.9982 1.65865M10 8.15686V12.1569C10 12.2895 9.94732 12.4166 9.85355 12.5104C9.75979 12.6042 9.63261 12.6569 9.5 12.6569H1.5C1.36739 12.6569 1.24021 12.6042 1.14645 12.5104C1.05268 12.4166 1 12.2895 1 12.1569V4.15686C1 4.02425 1.05268 3.89708 1.14645 3.80331C1.24021 3.70954 1.36739 3.65686 1.5 3.65686H5.5"
                    stroke="#CCCCCC"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <a
                href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                target="blank"
                className="reg-play-store-text m-1"
              >
                Apple Store
              </a>
            </span>
          </div>

          <nav className={`nav navbar-fixed-signup-bottom `} id="nav">
            <div className="nav-center-signup">
              {/* Nav header */}
              <div className="nav-header-signup">
                <button
                  className="nav-btn-back nav-back-text"
                  id="nav-btn"
                  onClick={() => handlePreviousStep()}
                >
                  <i className="fa fa-solid fa-arrow-left"></i>{" "}
                  <span style={{ marginLeft: "0.2rem" }}>back</span>
                </button>{" "}
                {/* <button className="nav-btn-Proceed" id="nav-btn">
                          Proceed
                        </button> */}
                <button
                  id="kt_password_cancel "
                  type="submit"
                  className={`btn nav-btn-Proceed ${
                    !(formik.isValid && formik.dirty)
                      ? "btn-secondary"
                      : "btn-success"
                  } text-white px-7 py-2`}
                disabled={!(formik.isValid && formik.dirty)}
                 onClick={(e) => setConfrim(true)}
                >
                  {!isLoading && (
                    <span className="indicator-label">Proceed</span>
                  )}
                  {isLoading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please wait...{" "}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </nav>
        </div>
      {confrimModal()}
    </>
    
  );
};

export { StepTwo };
