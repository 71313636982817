import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router-dom";
import {
  checked,
  checkedFilled,
  receipt,
  search as searchIcon,
  userGreen,
  userPlus,
} from "../../../../core/assets/icons";
import { arrowDown } from "../../../../core/assets/imgs";
import { ErrorToast } from "../../../componets/toast";
import { useGetSector } from "../../auth/hooks/useUser";
import { getInvoice, useGetMdabySector } from "../hooks/usePayment";

const SelectMda: React.FC = () => {
  const navigate = useNavigate();
  const [searchWhere, setSearchWhere] = useState<string>("");
  const [dropDown, setDropDown] = useState<boolean>(false);
  const [proceedData, setProceedData] = useState<any>({
    mda: "",
    serviceType: "",
    paymentType: "",
    serviceName: "",
    serviceId: "",
    mdaName: "",
    mdaId: "",
  });
  const [payment, setPaymentType] = useState<any>("");
  const [refNo, setRefNo] = useState<any>("");
  const [data, setData] = useState<any>({
    sector: "",
    organization: "",
    subOrganization: "",
    customFields: [],
  });
  const [selected, setSelected] = useState<any>({});
  const [showSector, setShowSector] = useState<boolean>(false);
  const [showOrganization, setShowOrganization] = useState<boolean>(false);
  const [showSubOrganization, setShowSubOrganization] =
    useState<boolean>(false);
  const [selectedSector, setSelectedSector] = useState<any>({});
  const [selectedOrg, setSelectedOrg] = useState<any>([]);
  const [selectedSubOrg, setSelectedSubOrg] = useState<any>({});
  // const [searchCode, setSearchCode] = useState<any>("");
  // const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [loadingRef, setLoadingRef] = useState<boolean>(false);
  // const [searchResults, setSearchResults] = useState<any>([]);

  const {
    data: getSector,
    mutate: mutateGetSector,
    isLoading: loadingSector,
  } = useGetSector();

  const {
    data: orgList,
    mutate: orgMutate,
    isLoading: isLoadingOrg,
  } = useGetMdabySector();

  useEffect(() => {
    mutateGetSector();
  }, []);

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const filterList = (data: any) => {
    if (searchWhere === "sector") {
      var data = getSector?.filter((listItem: any) => {
        return listItem.description
          .toLowerCase()
          ?.includes(data[searchWhere]?.toLowerCase());
      });
      return data;
    }
    if (searchWhere === "organization") {
      return selectedOrg.filter(
        (listItem: any) =>
          listItem?.registeredName
            ?.toLowerCase()
            ?.includes(data[searchWhere]?.toLowerCase()) ||
          listItem?.mdaCode
            ?.toLowerCase()
            ?.includes(data[searchWhere]?.toLowerCase()) ||
          listItem?.mdaAdminCode
            ?.toLowerCase()
            ?.includes(data[searchWhere]?.toLowerCase())
      );
    }

    if (searchWhere === "subOrganization") {
      return selectedSubOrg.filter((listItem: any) =>
        listItem?.name
          ?.toLowerCase()
          ?.includes(data[searchWhere]?.toLowerCase())
      );
    }
  };

  const Where = (value: string, e: any) => {
    setSearchWhere(value);
    setProceedData({
      mda: "",
      serviceType: "",
      paymentType: "",
    });
    setDropDown(false);
    if (value === "sector") {
      setData({ ...data, sector: e, organization: "", subOrganization: "" });
      setShowSector(true);
      setShowOrganization(false);
      setShowSubOrganization(false);
      setSelected({});
      setPaymentType("revenue");
    }
    if (value === "organization") {
      setData({ ...data, organization: e, subOrganization: "" });
      setShowSector(false);
      setShowOrganization(true);
      setShowSubOrganization(false);
      setSelected({});
      setPaymentType("revenue");
    }
    if (value === "subOrganization") {
      setData({ ...data, subOrganization: e });
      setShowSector(false);
      setShowOrganization(false);
      setShowSubOrganization(true);
      // setSelected({});
      setPaymentType("revenue");
    }
  };

  const showALL = (current: any) => {
    setDropDown(true);
    setSearchWhere(current);
    toggleDropdown();
  };

  const toggleDropdown = () => {
    setDropDown(!dropDown);
  };

  const onClickOutsideListener = () => {
    if (dropDown) {
      toggleDropdown();
      document.removeEventListener("click", onClickOutsideListener);
    }
  };

  useEffect(() => {});

  const updateSector = (value: any) => {
    setProceedData({ mda: "", serviceType: "", paymentType: "" });
    setSelectedSector(value);
    orgMutate(value, {
      onSuccess: (res: any) => {
        setSelectedOrg(res);
      },
    });

    setData({
      ...data,
      sector: value,
      organization: "",
      subOrganization: "",
    });
    setShowSector(false);
    setDropDown(false);
    setSelected({});
  };

  const updateOrganization = (value: any) => {
    setSelectedSubOrg(value?.children);
    setData({
      ...data,
      organization: value.registeredName,
      subOrganization: "",
    });
    setShowOrganization(false);
    setDropDown(false);

    if (value.services.length > 0) {
      setSelected(value);
      chooseMda(value);
    } else {
      setSelected({});
    }
  };

  const updateSubOrganization = (value: any) => {
    setSelected(value);
    setData({
      ...data,
      subOrganization: value?.registeredName,
    });
    setShowSubOrganization(false);
    setDropDown(false);
  };

  const emptyFilledData = () => {
    setData({
      sector: "",
      organization: "",
      subOrganization: "",
    });
    setProceedData({ mda: "", serviceType: "", paymentType: "" });
    setSelected({});
    setPaymentType("");
  };

  const chooseMda = (value: any) => {
    // emptyFilledData();
    setProceedData({
      ...proceedData,
      mdaId: value?.id,
      mdaName: value?.registeredName,
      serviceType: "",
      paymentType: "",
      serviceId: "",
      serviceName: "",
      amount: "",
    });
    setSelected(value);
    setData({
      sector: value?.sector?.code,
      organization: value?.registeredName,
      subOrganization: "",
      //subOrganization: value?.children,
    });
  };

  const Proceed = () => {
    if (refNo.length > 0) {
      setLoadingRef(true);
      getInvoice(refNo)
        .then((response) => {
          if (response?.invoice?.status.toLowerCase() === "paid") {
            ErrorToast("Invoice Already Paid");
            return;
          }
          navigate("/invoice", {
            state: response,
          });
        })
        .catch((err) => {
          if (err.response?.status === 404) {
            ErrorToast("Reference  number not found");
            return;
          }

          ErrorToast(err.response.statusText);
        })
        .finally(() => {
          setLoadingRef(false);
        });
      return;
    }

    navigate("/revenue", {
      state: {
        ...proceedData,
        mdaName: proceedData?.mdaName,
        mdaId: proceedData?.mdaId,
        serviceType: proceedData?.serviceType,
        serviceId: proceedData?.serviceId,
        serviceName: proceedData?.serviceName,
        amount: proceedData?.amount,
        customFields: proceedData?.customFields,
        payComps: proceedData?.paymentComponents,
      },
    });
  };

  return (
    <div>
      <div className="my-10 mb-20">
        <Container>
          <Row className="gy-10">
            <Col xs={12} md={5} lg={5} className="">
              <div className="card border-0 p-8 min-h-349e ">
                <span className="text-dark fs-5 fw-bolder">
                  Search Directory
                </span>
                <>
                  <div className="mt-1">
                    <div className="col-lg-4 col-form-label py-0 input-height">
                      {data?.sector?.length !== 0 && (
                        <label className="fw-bold fs-9">Sector</label>
                      )}
                    </div>

                    <div
                      className={`form-control form-control-solid pe-0 ${
                        data?.sector?.length !== 0 ? "border-success" : ""
                      }`}
                      onClick={() => showALL("sector")}
                    >
                      <img
                        src={receipt}
                        alt=""
                        className="w-1r1 h-1r1 d-none d-lg-inline"
                      />
                      <input
                        type="text"
                        value={data.sector}
                        placeholder="Search Sector *"
                        className={"no-outline ps-2 text-dark fs-8 w-80"}
                        // onChange={(e) => Where("sector", e.target.value)}
                        // disabled={true}
                      />

                      <img src={arrowDown} alt="" className="w-1r1 h-1r1" />
                    </div>

                    {/* <div className="text-end">
                      <span
                        className="text-success fw-bolder fs-9  cursor-pointer"
                        onClick={() => showALL("sector")}
                      >
                        Show All
                      </span>
                    </div> */}

                    {dropDown && searchWhere === "sector" && (
                      <>
                        <div
                          className="drop d-flex flex-column justify-content-center overflow-auto"
                          onMouseLeave={() => {
                            document.addEventListener(
                              "click",
                              onClickOutsideListener
                            );
                          }}
                        >
                          {getSector?.map((value: any, index: any) => {
                            return (
                              <div
                                className={`h-2r mda-border cursor-pointer`}
                                key={index}
                                onClick={() => updateSector(value.description)}

                                //${selected?.code === value?.code ? "bg-warning-alt" : ""}
                              >
                                <div className="fs-9 lh-xxl text-dark ps-2">
                                  {value.description}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}

                    {showSector && data?.sector?.length !== 0 && (
                      <ul className="dd-menu ps-1">
                        {filterList(data)?.map((value: any, index: any) => {
                          return (
                            <li
                              className={`h-2r border-bottom border-gray-light my-1  rounded-1 cursor-pointer`}
                              key={index}
                              onClick={() => updateSector(value)}
                            >
                              <div className="fs-9 lh-xxl text-dark ms-2">
                                {value.description}
                              </div>

                              <li className="divider" />
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                  {isLoadingOrg ? (
                    <div className="shine h5 w5 my-5"></div>
                  ) : (
                    data?.sector?.length !== 0 &&
                    selectedSector.length !== 0 &&
                    !showSector && (
                      <div className="mt-1">
                        <div className="col-lg-12 col-form-label py-0 input-height">
                          {data.organization.length !== 0 && (
                            <label className="fw-bold fs-9">
                              Organization / code
                            </label>
                          )}
                        </div>

                        <div
                          className={`form-control form-control-solid pe-0 ${
                            data.organization.length !== 0
                              ? "border-success"
                              : ""
                          }`}
                        >
                          <img
                            src={userGreen}
                            alt=""
                            className="w-1r1 h-1r1 d-none d-lg-inline"
                          />
                          <input
                            type="text"
                            value={data.organization}
                            placeholder="Search Organization / code"
                            className={"no-outline text-dark ps-2 fs-8 w-80"}
                            onChange={(e) =>
                              Where("organization", e.target.value)
                            }
                          />

                          <img
                            src={searchIcon}
                            alt=""
                            className="w-1r1 h-1r1"
                          />
                        </div>

                        <div className="text-end">
                          <span
                            className="text-success fw-bolder fs-9  cursor-pointer"
                            onClick={() => showALL("organization")}
                          >
                            Show All
                          </span>
                        </div>

                        {dropDown && searchWhere === "organization" && (
                          <>
                            <div
                              className="drop d-flex flex-column justify-content-center overflow-auto"
                              onMouseLeave={() => {
                                document.addEventListener(
                                  "click",
                                  onClickOutsideListener
                                );
                              }}
                            >
                              {selectedOrg?.map((value: any, index: any) => {
                                return (
                                  <div
                                    className={`h-2r mda-border cursor-pointer`}
                                    key={index}
                                    onClick={() => updateOrganization(value)}

                                    //${selected?.code === value?.code ? "bg-warning-alt" : ""}
                                  >
                                    <div className="fs-9 lh-xxl text-dark ps-2">
                                      {value?.mdaAdminCode || value.mdaCode} -{" "}
                                      {value.registeredName}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </>
                        )}

                        {showOrganization && data.organization.length !== 0 && (
                          <ul className="dd-menu ps-1">
                            {filterList(data)?.map((value: any, index: any) => {
                              return (
                                <li
                                  className={`h-2r border-bottom border-gray-light my-1  rounded-1 cursor-pointer }`}
                                  key={index}
                                  onClick={() => updateOrganization(value)}
                                >
                                  <div className="fs-9 lh-xxl text-dark ms-2">
                                    {value?.mdaAdminCode || value.mdaCode} -{" "}
                                    {value.registeredName}
                                  </div>

                                  <li className="divider" />
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    )
                  )}

                  {data?.sector?.length !== 0 &&
                    data.organization.length !== 0 &&
                    selectedOrg.length !== 0 &&
                    !showOrganization && (
                      <div className="mt-1">
                        <div className="col-lg-12 col-form-label py-0 input-height">
                          {data?.subOrganization?.length !== 0 && (
                            <label className=" fw-bold fs-9">
                              Sub-Organization / code
                            </label>
                          )}
                        </div>

                        <div
                          className={`form-control form-control-solid pe-0 ${
                            data.subOrganization.length !== 0
                              ? "border-success"
                              : ""
                          }`}
                        >
                          <img
                            src={userPlus}
                            alt=""
                            className="w-1r1 h-1r1 d-none d-lg-inline"
                          />
                          <input
                            type="text"
                            value={data.subOrganization}
                            placeholder="Search Sub-Organization / code"
                            className={"no-outline text-dark fs-8 w-80 ps-2"}
                            onChange={(e) =>
                              Where("subOrganization", e.target.value)
                            }
                          />

                          <img
                            src={searchIcon}
                            alt="search"
                            className="w-1r1 h-1r1"
                          />
                        </div>

                        <div className="text-end">
                          <span
                            className="text-success fw-bolder fs-9  cursor-pointer"
                            onClick={() => showALL("subOrganization")}
                          >
                            Show All
                          </span>
                        </div>

                        {dropDown && searchWhere === "subOrganization" && (
                          <>
                            <div
                              className="drop d-flex flex-column justify-content-center overflow-auto"
                              onMouseLeave={() => {
                                document.addEventListener(
                                  "click",
                                  onClickOutsideListener
                                );
                              }}
                            >
                              {selectedSubOrg.map((value: any, index: any) => {
                                return (
                                  <div
                                    className={`h-2r mda-border cursor-pointer`}
                                    key={index}
                                    onClick={() => updateSubOrganization(value)}
                                  >
                                    <div className="fs-9 lh-xxl text-dark ps-2">
                                      {value?.mdaAdminCode || value.mdaCode} -{" "}
                                      {value.registeredName}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </>
                        )}

                        {showSubOrganization &&
                          data.subOrganization?.length !== 0 && (
                            <ul className="dd-menu ps-1">
                              {filterList(data)?.map(
                                (value: any, index: any) => {
                                  return (
                                    <li
                                      className={`h-2r border-bottom border-gray-light my-1  rounded-1 cursor-pointer`}
                                      key={index}
                                      onClick={() =>
                                        updateSubOrganization(value)
                                      }
                                    >
                                      <div className="fs-9 lh-xxl text-dark ms-2">
                                        {value.name}
                                      </div>

                                      <li className="divider" />
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          )}
                      </div>
                    )}
                </>
              </div>
            </Col>

            <Col xs={12} md={7} lg={7} className="">
              <div
                className={`card   border-0 p-8 ${
                  Object.keys(selected).length === 0 ? "h-349e" : "min-h-492e"
                } `}
              >
                <span className="text-dark fs-5 fw-bolder">Details</span>

                {Object.keys(selected).length === 0 ? (
                  <div>
                    <div className="d-flex flex-column m-2">
                      <span>{selected?.name}</span>
                      <span className="text-muted fs-9">No Results</span>
                    </div>
                    <hr className="rule text-gray-800 opacity-100" />
                  </div>
                ) : (
                  <div>
                    <div className="d-flex flex-column m-2">
                      <span className="text-muted fs-9">MDA Name</span>
                      <span className="text-muted fs-8">
                        {data?.sector && `${data?.sector}`}
                        {data?.organization && `/ ${data?.organization}`}
                        {data?.subOrganization && `/ ${data?.subOrganization}`}
                        <span className="text-dark fs-9">
                          {selected?.name && `/ ${selected?.name}`}
                        </span>
                      </span>
                    </div>
                    <hr className="rule text-gray-800 opacity-100" />

                    <div className=" d-flex flex-column m-2">
                      <span className="text-muted fs-9">MDA Code</span>
                      <span>{selected?.mdaAdminCode || selected.mdaCode}</span>
                    </div>

                    <hr className="rule text-gray-800 opacity-100" />

                    <div className="m-2">
                      <span className="text-dark fs-8">
                        Choose Payment Type
                      </span>
                    </div>

                    <div className="m-2">
                      <div>
                        <div className="d-flex  flex-column  justify-content-between  mt-5">
                          <>
                            <div
                              className="row min-w-25 ms-2 cursor-pointer"
                              onClick={() => {
                                setRefNo("");
                                setProceedData({
                                  ...proceedData,
                                  serviceType: "",
                                  serviceId: "",
                                });
                                setPaymentType("revenue");
                              }}
                            >
                              <span className="form-check form-check-custom form-check-alt">
                                <img
                                  alt=""
                                  src={`${
                                    payment === "revenue"
                                      ? checkedFilled
                                      : checked
                                  }`}
                                  className="w-1r1 h-1r1"
                                />
                                <span className="text-dark fs-9 ms-2">
                                  Revenue Payment
                                </span>
                              </span>
                            </div>

                            {payment === "revenue" && (
                              <ul className="list-style">
                                {selected?.services?.map(
                                  (value: any, index: any) => {
                                    if (!value.active) {
                                      return null;
                                    }
                                    return (
                                      <div
                                        key={index}
                                        className="d-flex align-items-center h-2r py-2 mx-4 cursor-pointer"
                                        onClick={() =>
                                          setProceedData({
                                            ...proceedData,
                                            ...value,
                                            serviceType: value.description,
                                            serviceId: value.id,
                                            serviceName: value.description,
                                            amount: value.amount,
                                          })
                                        }
                                      >
                                        <span className="form-check form-check-custom ">
                                          <input
                                            type="checkbox"
                                            className="form-check-input w-1r h-1r rounded-1 form-check-input-alt"
                                            name={value.description}
                                            checked={
                                              proceedData?.serviceId ===
                                              value.id
                                            }
                                            value={value.description}
                                            onChange={(e) =>
                                              setProceedData({
                                                ...proceedData,
                                                ...value,
                                                serviceType: e.target.value,
                                                serviceId: value.id,
                                                serviceName: e.target.value,
                                                amount: value.amount,
                                              })
                                            }
                                          />
                                          <span className="text-muted fs-9 ms-3">
                                            {value?.description}
                                          </span>
                                        </span>
                                      </div>
                                    );
                                  }
                                )}
                              </ul>
                            )}

                            <div
                              className="row min-w-25 ms-2 cursor-pointer"
                              onClick={() => {
                                setRefNo("");
                                setProceedData({
                                  ...proceedData,
                                  serviceType: "",
                                });
                                setPaymentType("invoice");
                              }}
                            >
                              <span className="form-check form-check-custom form-check-alt">
                                <img
                                  alt=""
                                  src={`${
                                    payment === "invoice"
                                      ? checkedFilled
                                      : checked
                                  }`}
                                  className="w-1r1 h-1r1"
                                />
                                <span className="text-dark fs-9 ms-2">
                                  E-invoice Payments
                                </span>
                              </span>
                            </div>

                            {payment === "invoice" && (
                              <div className="ms-8 mda-ref">
                                <div className=" ">
                                  <div className="col-lg-8 col-form-label py-0 input-height">
                                    {refNo.length !== 0 && (
                                      <label className="required fw-bold fs-9">
                                        Enter Reference number
                                      </label>
                                    )}
                                  </div>

                                  <div
                                    className={`form-control form-control-solid pe-0 ${
                                      refNo.length === 0 ? "" : "border-success"
                                    }`}
                                  >
                                    <img
                                      src={receipt}
                                      alt=""
                                      className="icon-style"
                                    />

                                    <input
                                      type="text"
                                      placeholder="Enter Reference number*"
                                      className={
                                        "no-outline ps-4 text-dark fs-9"
                                      }
                                      onChange={(e) => setRefNo(e.target.value)}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="btn-pay">
                  <button
                    id="kt_password_cancel "
                    className={`btn ${
                      Object.keys(selected).length === 0
                        ? " btn-secondary"
                        : "btn-success"
                    } text-white px-7 py-2 h-44 w-min-110 rounded-1`}
                    onClick={Proceed}
                    disabled={
                      Object.keys(selected).length === 0 ||
                      (proceedData.serviceType.length === 0 &&
                        refNo.length === 0)
                    }
                  >
                    {!loadingRef && (
                      <span className="indicator-label fs-base">Pay</span>
                    )}

                    {loadingRef && (
                      <span className="indicator-progress d-flex justify-content-center  align-items-center">
                        <span className="d-none d-md-block">
                          Please wait...{" "}
                        </span>
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export { SelectMda };
