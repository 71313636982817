import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  MDAdirectory,
  arrowRight,
  arrowUpCircle,
  einvoice,
  homepageBubles,
  playCircle,
  receiptgeneration,
  revenuepayment,
  userHeroImg,
  welcomeImg,
} from "../../../core/assets/imgs";

import { Modal } from "react-bootstrap";
import Typewriter from "typewriter-effect";

const UserHero: React.FC = () => {
  const [show, setShow] = useState<any>(false);
  const navigate = useNavigate();

  const done = () => {
    navigate("/payment");
  };
  const PageModal = () => {
    return (
      <Modal
        dialogClassName="home-modal"
        aria-hidden="true"
        show={show}
        onHide={() => setShow(false)}
        animation
        centered
        size="lg"
      >
        <div className=" d-flex flex-cloumn justify-content-center align-items-center w-536e h-326e bg-black rounded-1">
          <article className="w-100 h-100">
            <iframe
              className="rounded-1"
              width="100%"
              height="326"
              src="https://www.youtube-nocookie.com/embed/zH1HtWW_3-Q?rel=0&showinfo=0"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; "
              allowFullScreen
            ></iframe>
          </article>
        </div>
      </Modal>
    );
  };
  return (
    <div>
      <header className="hero mt-3">
        <div className="section-center hero-center">
          <div className="d-flex align-items-center  justify-content-center py-3 ">
            <div className="welcome-body">
              <div className="">
                <span className="fs-5 fw-bold text-dark">
                  <span className="text-success">Pay</span> to Federal
                  Government of Nigeria
                </span>
              </div>
            </div>
          </div>

          <div className="hero-info">
            <div className="quick-links">
              <Link to={"/revenue"}>
                <div className="img-div d-flex flex-column align-items-center  justify-content-center">
                  <img src={revenuepayment} alt="MDAdirectory" />
                  <span>
                    Revenue Payments
                    <img
                      src={arrowUpCircle}
                      alt="MDAdirectory"
                      className="arrow-style ms-2"
                    />
                  </span>
                </div>
              </Link>
              <Link to={"/invoice"}>
                <div className="img-div d-flex flex-column align-items-center  justify-content-center">
                  <img src={einvoice} alt="receiptgeneration" />
                  <span>
                    Electronic Invoice Payments
                    <img
                      src={arrowUpCircle}
                      alt="MDAdirectory"
                      className="arrow-style ms-2"
                    />
                  </span>
                </div>
              </Link>
              <Link to={"/mda-directory"}>
                <div className="img-div d-flex flex-column align-items-center  justify-content-center">
                  <img src={MDAdirectory} alt="revenuepayment" />
                  <span>
                    MDA Directory
                    <img
                      src={arrowUpCircle}
                      alt="MDAdirectory"
                      className="arrow-style ms-2"
                    />
                  </span>
                </div>
              </Link>

              <Link to={"/reciepts"}>
                <div className="img-div d-flex flex-column align-items-center  justify-content-center">
                  <img src={receiptgeneration} alt="einvoice" />
                  <span>
                    Resend e-Invoices/Receipts
                    <img
                      src={arrowUpCircle}
                      alt="MDAdirectory"
                      className="arrow-style ms-2"
                    />
                  </span>
                </div>
              </Link>
            </div>
          </div>

          <div className="d-flex align-items-center  justify-content-center pt-5  ">
            <div className="welcome-body">
              <div className="getStarted-root  welcome-text d-flex align-items-center">
                <span className="welcome-text d-none d-lg-block">
                  <img src={userHeroImg} className="userHeroImg" alt="TMS" />
                </span>
              </div>
            </div>
            <div className="w-450px d-lg-flex align-items-center d-none">
              <div className="w-374px userHero ">
                <div className="videoModal w-374px">
                  <div className="h-231e bg-black rounded-1">
                    <article className="w-100 h-100">
                      <iframe
                        className="rounded-1"
                        width="100%"
                        height="100%"
                        src="https://www.youtube-nocookie.com/embed/zH1HtWW_3-Q?rel=0&showinfo=0"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; "
                        allowFullScreen
                        loading="eager"
                      ></iframe>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" d-flex align-items-center  justify-content-center">
            <button
              className="btn btn-success rounded-1 userHome-btn "
              onClick={done}
            >
              <span className="fs-6">
                Pay Now
                <img
                  src={arrowRight}
                  className="icon-style ms-2"
                  alt="arrowRight"
                />
              </span>
            </button>
          </div>
        </div>
      </header>

      {PageModal()}
    </div>
  );
};

export { UserHero };
