import React, {useState} from 'react';
import '../../../../../../App.css';
import {useNavigate} from "react-router-dom";
import Vat from "../../../../../../core/assets/files/Vat.csv";
import {ErrorToast} from "../../../../../componets/toast";
import {Modal, Table} from "antd";
import {useFormik} from "formik";
import * as Yup from "yup";
import { formatMoneyLocal } from 'core/helpers';

const Schema = Yup.object().shape({
    fundingAccount: Yup.string().required('Funding Account is required'),
});

const initialValues = {
    fundingAccount: '',
};


const PayeBulkUpload = () => {
    const [vatFile, setVatFile] = useState<string>();
    const [successModal, setSuccessModal] = useState(false);
    const [fundingAcctModal, setFundingAcctModal] = useState(false);
    const [showPensionPayrollTable, setShowPensionPayrollTable] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);


    const navigate = useNavigate();

    function extractData($event: any) {
        const file = $event.target.files[0];
        setVatFile(file.name);
        if (file.type === "text/csv") {
            const reader = new FileReader();
            reader.readAsText(file);

            reader.onload = (event: any) => {
                const csvString = event.target.result;

                let rows = csvString.split("\n");

                rows = rows.filter((line) => line.trim() !== "");

                const headers = rows[0].trim().split(",");
                const data: any[] = [];

                for (let i = 1; i < rows.length; i++) {
                    let values = rows[i].trim();
                    values = values.split(",");

                    const obj = {};
                    for (let j = 0; j < headers.length; j++) {
                        obj[headers[j].trim()] = values[j].trim();
                        if (j === 3) {
                            let amount = values[j].replace("₦", "");
                            amount = Number(amount);
                            //console.log("amount: ", totalAmount + amount);
                            // totalAmount = totalAmount + amount;
                            // setTotalPayableSum(totalAmount);
                        }
                    }
                    data.push(obj);

                    setDataSource(data);

                    //console.log("dataSource: ", data);
                }
            };
        } else {
            ErrorToast("Invalid File type Select a CSV File");
        }
    }


    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        //console.log("selectedRowKeys changed: ", newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const columns = [
      {
        title: "TaxPayer TIN",
        dataIndex: "taxPayerTin",
        key: "taxPayerTin",
      },
      {
        title: "TaxPayer First Name",
        dataIndex: "tpFirstName",
        key: "tpFirstName",
      },
      {
        title: "TaxPayer Last Name",
        dataIndex: "tpLastName",
        key: "tpLastName",
      },
      {
        title: "TaxPayer Phone Number",
        dataIndex: "tpPhoneNumber",
        key: "tpPhoneNumber",
      },
      {
        title: "TaxPayer Email",
        dataIndex: "tpEmail",
        key: "tpEmail",
      },
      {
        title: "Invoice No",
        dataIndex: "invoiceNo",
        key: "invoiceNo",
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "VAT %",
        dataIndex: "vatPercent",
        key: "vatPercent",
      },
      {
        title: "VAT Amount",
        dataIndex: "vatAmount",
        key: "vatAmount",
        render: (_, data) => (
          <span>{formatMoneyLocal(data?.vatAmount ?? 0)}</span>
        ),
      },
      {
        title: "Pay period",
        dataIndex: "payPeriod",
        key: "payPeriod",
      },
    ];


    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: async (values) => {

        },
    });

    let fundingAccount = [
        {
            id: '',
            description: 'Account number',
        },
        {
            id: '1',
            description: 'First Bank of Nigeria PLC (099***5545)',
        },
    ];

    return (
        <>
            <div className={'vatPayment'}>
                <div className={'vatPaymentBreadcrumb'}>
                    <div>Payments</div>
                    <svg
                        width="10"
                        height="13"
                        viewBox="0 0 10 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                            fill="#1F8505"
                        />
                    </svg>
                    <div>Tax Payments</div>

                    <svg
                        width="10"
                        height="13"
                        viewBox="0 0 10 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                            fill="#1F8505"
                        />
                    </svg>
                    <div>Value Added Tax</div>

                    <svg
                        width="10"
                        height="13"
                        viewBox="0 0 10 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                            fill="#1F8505"
                        />
                    </svg>

                    <div>Multiple Payment</div>
                </div>

                {!showPensionPayrollTable ? (
                    <div className={'vatBulkUploadDiv'}>
                        <div className={'vatBulkUpload'}>
                            <div onClick={() =>  navigate("/admin/payment/tax-payments/vat")}>
                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_7037_76293)">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M18 11.8125H9.63312L13.4763 7.96937L12.5 7L7 12.5L12.5 18L13.4694 17.0306L9.63312 13.1875H18V11.8125Z"
                                              fill="#1F8505"/>
                                    </g>
                                    <rect x="0.75" y="1.25" width="22.5" height="22.5" rx="11.25" stroke="#1F8505"
                                          stroke-width="1.5"/>
                                    <defs>
                                        <clipPath id="clip0_7037_76293">
                                            <rect y="0.5" width="24" height="24" rx="12" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>

                            <div className={'vatUploadText'}>VAT Upload from Excel</div>
                        </div>

                        <div style={{textAlign: "center"}}>
                            <a className={'downloadTemplate'} href={Vat}>
                        <span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M7.5 7.5H8V11H8.5M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z"
                                    stroke="#48B460" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path
                                    d="M7.875 6C8.28921 6 8.625 5.66421 8.625 5.25C8.625 4.83579 8.28921 4.5 7.875 4.5C7.46079 4.5 7.125 4.83579 7.125 5.25C7.125 5.66421 7.46079 6 7.875 6Z"
                                    fill="#48B460"/>
                            </svg>
                        </span>

                                Download a VAT Upload File
                            </a>
                        </div>

                        <div className={'vatFileUploadDiv'}>
                            <div>File</div>

                            <label>
                                <div>Browse...</div>
                                <div>{vatFile ? vatFile : 'No file selected'}</div>
                                <input hidden id="input" type="file" accept="text/csv" onChange={extractData}/>
                            </label>

                            <div>Accepted file types are .csv, .xls, .xlxs</div>
                        </div>

                        <div className={'vatUploadButton'}>
                            <button
                                onClick={() => setShowPensionPayrollTable(true)}
                            >
                                Upload
                            </button>
                        </div>
                    </div>
                    ) : (
                        <div className={'pensionPaymentTable'}>
                            <div className={'pensionPaymentTableRow1'}>
                                <div>
                                    <button onClick={() =>  navigate("/admin/payment/tax-payments/vat")}>
                                        Go back
                                    </button>
                                </div>

                                <div>
                                    <button
                                        onClick={() => setFundingAcctModal(true)}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>

                            <div className={"pensionPaymentTableRow2"}>
                                <Table
                                    rowSelection={rowSelection}
                                    dataSource={[...dataSource]}
                                    columns={columns}
                                    scroll={{ x: "1500px" }}
                                />
                            </div>
                        </div>
                    )}


                <Modal
                    centered
                    style={{ width: "500px", maxWidth: "100%" }}
                    open={fundingAcctModal}
                    onOk={() => setFundingAcctModal(false)}
                    onCancel={() => setFundingAcctModal(false)}
                    footer={null}
                    closable={false}
                >
                    <div className={"tpSuccessModal"}>
                        <div className={'tpSuccessModalRow1'}>
                            <div onClick={() => {
                                setShowPensionPayrollTable(true);
                                setFundingAcctModal(false);
                            }}>
                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_7037_76293)">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M18 11.8125H9.63312L13.4763 7.96937L12.5 7L7 12.5L12.5 18L13.4694 17.0306L9.63312 13.1875H18V11.8125Z"
                                              fill="#1F8505"/>
                                    </g>
                                    <rect x="0.75" y="1.25" width="22.5" height="22.5" rx="11.25" stroke="#1F8505"
                                          stroke-width="1.5"/>
                                    <defs>
                                        <clipPath id="clip0_7037_76293">
                                            <rect y="0.5" width="24" height="24" rx="12" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>

                            <div>Select Funding Account</div>
                        </div>

                        <div className={'selectFundingDiv'}>
                            <div>Account number</div>

                            <div>
                                <div className={`mt-1`}>
                                    <div className={`form-control form-control-solid`}>
                                        <select
                                            className={`no-outline fs-8 ${
                                                formik.values.fundingAccount !== ''
                                                    ? 'text-dark'
                                                    : 'text-muted'
                                            } `}
                                            {...formik.getFieldProps('fundingAccount')}
                                        >
                                             <option value="">Select Account number</option>
                                            {fundingAccount &&
                                                fundingAccount?.map((array: any, i: any) => {
                                                    return (
                                                        <option value={array?.description} key={i}>
                                                            {array?.description}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                    </div>
                                </div>

                                <div></div>
                            </div>
                        </div>

                        <div className={"tpSuccessModalActionButton"}>
                            <button
                                onClick={() => {
                                    setSuccessModal(true);
                                    setFundingAcctModal(false);
                                }}
                            >
                                Proceed
                            </button>
                        </div>
                    </div>
                </Modal>

                <Modal
                    centered
                    style={{ width: "500px", maxWidth: "100%" }}
                    open={successModal}
                    onOk={() => setSuccessModal(false)}
                    onCancel={() => setSuccessModal(false)}
                    footer={null}
                >
                    <div className={"ppSuccessModal"}>
                        <svg style={{ justifySelf: "center" }} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M33.5937 20.3125L22.1354 31.25L16.4062 25.7812M43.75 25C43.75 35.3553 35.3553 43.75 25 43.75C14.6447 43.75 6.25 35.3553 6.25 25C6.25 14.6447 14.6447 6.25 25 6.25C35.3553 6.25 43.75 14.6447 43.75 25Z" stroke="#FBCB38" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                        <div className={"ppSuccessModalTitle"}>Successful!</div>

                        <div className={'ppSuccessModalDesc'}>
                            Your transaction has been sent to the reviewer group for action.
                        </div>

                        <div className={"ppSuccessModalActionButton"}>
                            <button
                                onClick={() => {
                                    navigate("/admin/payment/tax-payments");
                                    setSuccessModal(false);
                                }}
                            >
                                Done
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default PayeBulkUpload;
