import React from "react";
import { Footer } from "../../componets/footer";
import NavBar from "../../componets/nav/NavBar";
import "./terms.scss";

const TermsCondition: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <NavBar />

      <div className="center-all my-5">
        <span className="text-center text-dark fs-1  fw-bolder">
          Terms & Conditions
        </span>
      </div>

      <div className="body-style mt-5 mb-20">
        <li>
          <span className="heading">Definitions</span>
          <h2 className="Poppins">
            In this document, &quot;User Agreement&quot;, &quot;Agreement&quot;,
            &quot;Terms and Conditions of Service&quot; are synonymous and used
            interchangeably. In this Agreement, &quot;you&quot; or
            &quot;your&quot; or &quot;User&quot; refers to any person or entity
            using the service. Unless otherwise stated, &quot;we&quot; or
            &quot;our&quot; or &quot;us&quot; will refer collectively to the
            Office of the Accountant General of the Federation (OAGF), its
            affiliates, directors, officers, employees, agents and partners. FGN
            &quot;Treasury Portal&quot;, “Treasury Portal”, our Services&quot;,
            &quot;the Services&quot;, &quot;the System&quot;, &quot;Treasury
            app&#39; &quot;the platform&quot; are synonymous and used
            interchangeably unless otherwise specified. You and the OAGF are
            jointly referred to as &#39;the Parties&#39; in this document.
          </h2>
        </li>

        <li>
          <span className="heading">Your Contract</span>
          <h2 className="Poppins">
            Your acceptance of these Terms and Conditions of Service is
            established either by:
          </h2>

          <div className="custom-table px-5">
            <div className="custom-table-row">
              <div className="custom-table-cell d-flex">
                <div className="custom-bullet">
                  <span>&#8226;</span>
                </div>
                <div>
                  <span>Installing the FGN Treasury Portal application</span>
                </div>
              </div>
            </div>
            <div className="custom-table-row">
              <div className="custom-table-cell d-flex">
                <div className="custom-bullet ">
                  <span>&#8226;</span>
                </div>
                <div>
                  <span>
                    Using your personal details to log into the FGN Treasury
                    Portal or using any of the services, features and solutions
                    therein, directly or indirectly related.
                  </span>
                </div>
              </div>
            </div>
            <div className="custom-table-row">
              <div className="custom-table-cell d-flex">
                <div className="custom-bullet">
                  <span>&#8226;</span>
                </div>
                <div>
                  <span>
                    Integrating the FGN Treasury Portal", with your third party
                    application for the purpose of sending payment or collection
                    instructions for processing and receiving feedback as to the
                    status of the processed transactions.
                  </span>
                </div>
              </div>
            </div>
          </div>

          <ol>
            <li>
              <span className="heading fw-bold">Your Obligations</span>

              <h2 className="Poppins">
                The FGN Treasury Portal facilitates your transfer and receipt of
                funds. Based on your instructions, you acknowledge that:
              </h2>

              <div className="custom-table px-5">
                <div className="custom-table-row">
                  <div className="custom-table-cell d-flex">
                    <div className="custom-bullet">
                      <span>1. </span>
                    </div>
                    <div>
                      <span>
                        Payments will be applied in favour of the beneficiary
                        supplied by you at the time of upload of payment
                        instructions.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="custom-table-row">
                  <div className="custom-table-cell d-flex">
                    <div className="custom-bullet ">
                      <span>2. </span>
                    </div>
                    <div>
                      <span>
                        You are fully and solely responsible for the
                        completeness, correctness and validity of data supplied
                        on the platform with regards to a beneficiary.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="custom-table-row">
                  <div className="custom-table-cell d-flex">
                    <div className="custom-bullet">
                      <span>3. </span>
                    </div>
                    <div>
                      <span>
                        We are not acting as a trustee, fiduciary or escrow with
                        respect to your funds.
                      </span>
                    </div>
                  </div>
                </div>

                <div className="custom-table-row">
                  <div className="custom-table-cell d-flex">
                    <div className="custom-bullet">
                      <span>4. </span>
                    </div>
                    <div>
                      <span>
                        When you send a payment instruction, until that payment
                        is accepted by the recipient, you remain the owner of
                        those funds, but you will not be able to withdraw those
                        funds or send the funds to any other recipient unless
                        the initial transaction is cancelled.
                      </span>
                    </div>
                  </div>
                </div>

                <div className="custom-table-row">
                  <div className="custom-table-cell d-flex">
                    <div className="custom-bullet">
                      <span>5. </span>
                    </div>
                    <div>
                      <span>
                        You shall fully comply with all applicable Payment
                        Schemes rules and regulations,
                      </span>
                    </div>
                  </div>
                </div>

                <div className="custom-table-row">
                  <div className="custom-table-cell d-flex">
                    <div className="custom-bullet">
                      <span>6. </span>
                    </div>
                    <div>
                      <span>
                        You must not submit, and we shall not accept, any
                        transaction that you know is, or should have known was,
                        illegal or fraudulent.
                      </span>
                    </div>
                  </div>
                </div>

                <div className="custom-table-row">
                  <div className="custom-table-cell d-flex">
                    <div className="custom-bullet">
                      <span>7. </span>
                    </div>
                    <div>
                      <span>
                        You shall at all times, prevent fraud through your
                        action or inaction on the FGN Treasury Portal", and
                        shall provide us with reasonable assistance upon
                        request, for the prevention and detection of fraud or
                        other criminal activity in respect of any transaction.
                      </span>
                    </div>
                  </div>
                </div>

                <div className="custom-table-row">
                  <div className="custom-table-cell d-flex">
                    <div className="custom-bullet">
                      <span>8. </span>
                    </div>
                    <div>
                      <span>
                        You shall be responsible for the conduct of your
                        employees, agents and representatives as it pertains to
                        the use of the FGN Treasury Portal", as contemplated in
                        this Agreement; and you shall indemnify us for any loss
                        we may suffer as a result of any action or inaction of
                        your employees, agents and/or representatives.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li>
              <span className="heading fw-bold"> No Warranty</span>
              <h2 className="Poppins">
                We provide our services "as is" and without any warranty or
                condition, express, implied or statutory. We, specifically
                disclaim any implied warranties of title, merchantability,
                fitness for a particular purpose and non-infringement to the
                full extent permissible by the law. Whilst we shall provide the
                Services with due care and skill, Please be informed that our
                Services may suffer from delays, errors or other unintended
                outcomes. We shall make reasonable efforts to ensure that
                requests for electronic debits and credits and other
                transactional operations are processed in a timely manner but we
                make no representations or warranties regarding the amount of
                time needed to complete processing because our Service is also
                dependent upon other factors outside of our control, one of
                which is the operational efficiency of all stakeholders.
              </h2>
            </li>

            <li>
              <span className="heading fw-bold"> Limitation of Liability</span>
              <h2 className="Poppins">
                We shall only be liable for your direct loss or damage, proven
                to be a foreseeable result of our breach of this agreement. We
                will not be liable for any loss or damage arising as a result of
                unauthorised access to the service if (a) You intentionally or
                negligently failed to take reasonable precautions to protect
                your security, access codes, login details or any device used to
                access the service, (b) You failed to promptly notify us that
                the service was being accessed in an unauthorised way after
                becoming aware of it, or (c) You acted fraudulently. In no event
                shall the Office of the Accountant General of the Federation be
                liable for loss of income, profits, business, opportunity,
                contracts or any indirect, special, incidental or consequential
                damages arising out of or in connection with our platform, our
                service, or this Agreement.
              </h2>
            </li>

            <li>
              <span className="heading fw-bold"> Indemnification</span>
              <h2 className="Poppins">
                You agree that if you are in breach of this Agreement or the
                documents it incorporates by reference, or if you violate any
                law or the rights of a third party in connection with your use
                of the service, we shall not be responsible for any damage,
                loss, liability or third party claim you incur wholly or partly
                by your breach or violation. You agree to indemnify and hold the
                OAGF, harmless from any claim or demand (including attorneys'
                fees) made by you or any third party arising wholly or partly
                from your breach of this Agreement or the documents it
                incorporates by reference, or your violation of any law or the
                rights of a third party relating to your use of the Service.
              </h2>
            </li>
          </ol>
        </li>

        <li>
          <span className="heading">Support Services</span>

          <h2 className="Poppins">
            The FGN Treasury Portal has a dedicated support unit manned by
            highly experienced and professional personnel. Customer support
            issues are managed through a Customer Relationship Management (CRM)
            system. Customer complaints made through our dedicated email and
            telephone lines are logged and monitored until a resolution is
            achieved. All support related enquiries should be routed via the
            Contact details as displayed on the 'Contact' section of the
            platform which may be updated from time to time.
          </h2>
        </li>

        <li>
          <span className="heading">Use Policy</span>
          <ol>
            <li>
              <span className="heading fw-bold">Acceptable Use Policy</span>
              <h2 className="Poppins">
                The following activities constitute a violation of the
                Acceptable use of the Treasury Portal; The Treasury Portal must
                not be used in any way that causes, or may cause, damage to the
                Treasury Portal or impairment of the availability or
                accessibility of Treasury Portal or in any way which is
                unlawful, illegal, fraudulent or harmful, or in connection with
                any unlawful, illegal, fraudulent or harmful purpose or
                activities. This Treasury Portal must not be used to copy,
                store, host, transmit, send, use, publish or distribute any
                material which consists of (or is linked to) any spyware,
                computer virus, Trojan, or other malicious computer software.
                You must not conduct any systematic or automated data collection
                activities on or in relation to this website. This includes
                scraping, data mining, data extraction, data harvesting,
                screenshots, etc.
              </h2>
            </li>

            <li>
              <span className="heading fw-bold">Restricted Access</span>
              <h2 className="Poppins">
                Access to certain areas of this website may be restricted. OAGF
                reserves the right to restrict access to certain areas of the
                Treasury Portal, or at our discretion, the entire Portal. OAGF
                may change or modify this policy without notice. If the
                application provides you with a user ID and password to enable
                you to access restricted areas of this website or other content
                or services, you must ensure that the user ID and password are
                kept confidential. You alone are responsible for your password
                and user ID security. Your user ID and password may be disabled
                at our sole discretion without notice or explanation.
              </h2>
            </li>
          </ol>
        </li>

        <li>
          <span className="heading">Warranty and Service Disruption</span>
          <h2 className="Poppins">
            We warrant that the service will in all material respects, deliver
            on the agreed terms herein. Should the service be disrupted to such
            an extent that there is likely to be an adverse effect to the
            service provided, we will endeavour to notify you of such within a
            reasonable time. In the event of any service delay or failure, we
            shall take necessary steps to ensure speedy service restoration and
            reduce to the barest minimum the extent of such service failures.
            However, we shall not be liable to you for any loss or damage.
          </h2>
        </li>

        <li>
          <span className="heading">
            Trademarks and other Intellectual Proprietary rights
          </span>
          <h2 className="Poppins">
            The FGN Treasury Portal, all logos, services or other content on the
            platform are the intellectual property of OAGF and may not be
            copied, imitated or used, in whole or in part, without the prior
            written permission of the OAGF. Neither this Agreement, nor your use
            of the Treasury Portal will convey title or any interest or rights
            in OAGF’s intellectual property rights.
          </h2>
        </li>

        <li>
          <span className="heading">Assignability</span>
          <h2 className="Poppins">
            You may not transfer any rights or obligations you may have under
            this Agreement without our prior written consent. We reserve the
            right to transfer this Agreement or any of our rights and/or
            obligations under this Agreement. You may be able to continue to use
            the platform after such an assignment, if you consent to it.
          </h2>
        </li>

        <li>
          <span className="heading">Legal Compliance</span>
          <h2 className="Poppins">
            You shall comply with all applicable Nigerian laws, and regulations,
            regarding your use of our Services. If any specific term or
            condition violates the law, that term alone shall stand severed or
            amended as far as is necessary to comply with the law.
          </h2>
        </li>

        <li>
          <span className="heading">Notices and Amendment</span>
          <ol>
            <li>
              <span className="heading fw-bold">Notices</span>
              <h2 className="Poppins">
                You agree that these Terms and Conditions constitute "an
                Agreement duly signed or executed by "you" under any applicable
                law or regulation. To the fullest extent permitted by applicable
                law, this Agreement and any other agreements, notices or other
                documents regarding your account and/or your use of the Service,
                may be provided to you electronically and you agree to receive
                all Notices from the OAGF in electronic form. You may print a
                copy of any Notice and retain it for your records. All Notices
                in either electronic or paper format will be considered to be in
                "writing and to have been received and shall become effective
                48hours after being posted on the Treasury Portal.
              </h2>
            </li>
            <li>
              <span className="heading fw-bold">Notice Procedure</span>

              <div className="custom-table px-5">
                <div className="custom-table-row">
                  <div className="custom-table-cell d-flex">
                    <div className="custom-bullet">
                      <span>&#8226;</span>
                    </div>
                    <div>
                      <span>
                        Notices by us to you may be placed on the Treasury
                        Portal or sent to your email address registered with us.
                      </span>
                    </div>
                  </div>
                </div>

                <div className="custom-table-row">
                  <div className="custom-table-cell d-flex">
                    <div className="custom-bullet">
                      <span>&#8226;</span>
                    </div>
                    <div>
                      <span>
                        A notice by email shall be deemed received by the other
                        party, once the email is sent, unless the sending party
                        is aware that the email was not received.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li>
              <span className="heading fw-bold">Amendment</span>
              <h2 className="Poppins">
                We may amend this Agreement at any time by notice to you or by
                posting the amended terms on the Treasury Portal. All amended
                terms shall be effective immediately (or on any other date as we
                may determine) after publishing it on the platform.
              </h2>
            </li>
          </ol>
        </li>

        <li>
          <span className="heading">Extended Non-use</span>
          <h2 className="Poppins">
            Your non-use of our platform for an extended period does not
            terminate this contract. Any monies due and payable during or after
            the period of your passivity shall remain payable. Termination or
            non-use shall not relieve the continuing obligations under this
            Agreement,
          </h2>
        </li>

        <li>
          <span className="heading">General</span>

          <div className="custom-table px-5">
            <div className="custom-table-row">
              <div className="custom-table-cell d-flex">
                <div className="custom-bullet">
                  <span>1. </span>
                </div>
                <div>
                  <span>
                    You represent that you have the capacity to enter into this
                    Agreement.
                  </span>
                </div>
              </div>
            </div>
            <div className="custom-table-row">
              <div className="custom-table-cell d-flex">
                <div className="custom-bullet ">
                  <span>2. </span>
                </div>
                <div>
                  <span>
                    That you are an adult, eligible to operate services on the
                    Treasury Portal.
                  </span>
                </div>
              </div>
            </div>
            <div className="custom-table-row">
              <div className="custom-table-cell d-flex">
                <div className="custom-bullet">
                  <span>3. </span>
                </div>
                <div>
                  <span>
                    If any provision of this Agreement is held to be invalid or
                    unenforceable, such provision shall be struck out and the
                    remaining provisions shall be enforceable.
                  </span>
                </div>
              </div>
            </div>

            <div className="custom-table-row">
              <div className="custom-table-cell d-flex">
                <div className="custom-bullet">
                  <span>4. </span>
                </div>
                <div>
                  <span>
                    Headings are for reference purposes only and in no way
                    define, limit, construe or describe the scope or extent of
                    such section(s).
                  </span>
                </div>
              </div>
            </div>

            <div className="custom-table-row">
              <div className="custom-table-cell d-flex">
                <div className="custom-bullet">
                  <span>5. </span>
                </div>
                <div>
                  <span>
                    Our failure to act with respect to a breach by you or others
                    does not amount to a waiver of our right to act with respect
                    to subsequent or similar breaches.
                  </span>
                </div>
              </div>
            </div>

            <div className="custom-table-row">
              <div className="custom-table-cell d-flex">
                <div className="custom-bullet">
                  <span>6. </span>
                </div>
                <div>
                  <span>
                    The non-specification of a particular legal or equitable
                    remedy shall not be construed as a waiver, prohibition or
                    limitation of any legal or equitable remedies in the event
                    of a breach of any of the clauses.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </li>

        <li>
          <span className="heading">Entire Agreement</span>
          <h2 className="Poppins">
            This agreement and any documents referred to herein constitute the
            entire agreement between the parties and supersede any and all prior
            agreements between the parties, whether oral or written, with
            respect to the subject matter thereof.
          </h2>
        </li>

        <li>
          <span className="heading">Applicable Law</span>
          <h2 className="Poppins">
            This Agreement shall be governed by and interpreted according to the
            laws of the Federal Republic of Nigeria and shall be subject to the
            exclusive jurisdiction of Nigerian courts.
          </h2>
        </li>

        <li>
          <span className="heading">Improvement and Changes</span>
          <h2 className="Poppins">
            The Treasury Portal functions are subject to continuous
            technological improvement and consequently may change. All changes
            to Treasury Portal functions and services shall be published by
            Treasury Portal from time to time. All such published changes shall
            form part of this Agreement.
          </h2>
        </li>

        <li>
          <span className="heading">Force Majeure</span>

          <h2 className="Poppins">
            OAGF shall not be in breach of its obligations under this Agreement
            or be responsible for any delay in carrying out its obligations if
            performance is prevented or delayed wholly or in part as a
            consequence of force majeure. Force majeure means any circumstance
            beyond the reasonable control of the OAGF including but not limited
            to acts of war, state or national emergency, strike, rebellion,
            insurrection, government sanctions, actions of regulatory or
            supervisory authorities, accident, power failure, internet and
            communication link failure, fire, earthquake, flood, storm,
            tornadoes, hurricane, epidemic or pandemic, collapse of buildings,
            fire, explosion, or any other act of God or any technical failure
            caused by devices, matters or materials.
          </h2>
        </li>
      </div>

      <Footer />
    </div>
  );
};

export { TermsCondition };
