import React, { useState, useEffect, useCallback } from "react";
import "../../../App.css";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { HashLink, NavHashLink } from "react-router-hash-link";
import { WhicHome, checkIsHome, checkIsMdaHome } from "../../../core/helpers";
import { home } from "../../../core/assets/icons";
import { NewOAGFLogoSvg1 as logo } from "../../../core/assets/imgs";
import { isUserLoggedIn } from "../../routing/PrivateRoutes";

const NavBar = () => {
  const [sidebar, setSidebar] = useState(false);
  const [navText, setNav] = useState("");
  const navigate = useNavigate();
  // const [active, setActive] = useState('Home');
  // const [activeSet, setActiveSet] = useState('');

  const { pathname } = useLocation();

  const isHome = checkIsHome(pathname, window.location.hash);
  const isMdaHome = checkIsMdaHome(pathname, window.location.hash);

  const [y, setY] = useState(window.scrollY);

  const handleNavigation = useCallback(
    (e: any) => {
      const window = e.currentTarget;

      if (window.pageYOffset > 80) {
        setNav("navbar-fixed");
      } else {
        setNav("");
      }
      setY(window.scrollY);
    },
    [y]
  );

  // const onActiveMenu = (menu: string) => {
  //   setActive(menu);
  //   console.log(active);
  // };

  // useEffect(() => {
  //   onActiveMenu(activeSet)
  // },[activeSet, setActiveSet] )

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  const redirecLogin = () => {
    navigate("/auth/login");
  };
  const backToDashboard = () => {
    navigate("/admin");
  };
  const redirecSignup = () => {
    navigate("/auth/register");
  };

  const redirectHome = () => {
    setSidebar(false);
    navigate("/");
  };
  const redirectMda = () => {
    setSidebar(false);
    navigate("/mda-home");
  };

  const WhereToNavigate = () => {
    if (WhicHome() === "mdaHome") {
      return "/mda-home";
    } else {
      return "/";
    }
  };

  const sideBarDash = () => {
    setSidebar(false);
    backToDashboard();
  };

  return (
    <div>
      <nav className={`nav ${navText}`} id="nav">
        <div className="nav-center">
          {/* Nav header */}
          <div className="nav-header">
            <img src={logo} className="nav-logo" alt="nav logo" />
            <button
              className="nav-btn"
              id="nav-btn"
              onClick={() => setSidebar(!sidebar)}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>

          <div className="nav-link-container">
            {isHome && (
              <ul className="nav-links mb-0">
                <li>
                  <Link to={WhereToNavigate()}>Home</Link>
                </li>
                {isMdaHome ? (
                  <>
                    <li>
                      <HashLink to={"/mda-home/#benefits"}>Benefits</HashLink>
                    </li>

                    <li>
                      <HashLink to={"/mda-home/#about"}>About</HashLink>
                    </li>

                    <li>
                      <Link to={"/help-center"}>Help Center</Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <HashLink to={"/#about"}>About</HashLink>
                    </li>
                    {/* <li>
                          <Link to={"/help-center"}>Help Center</Link>
                        </li> 
                    */}
                    <li>
                      <HashLink to={"/#help"}>Help Center</HashLink>
                    </li>
                  </>
                )}
              </ul>
            )}
          </div>

          <span className="nav-button-container align-items-center">
            {isMdaHome && (
              <>
                {isUserLoggedIn() ? (
                  <button className="nav-btn-link" onClick={backToDashboard}>
                    Dashboard
                  </button>
                ) : (
                  <>
                    <button className="nav-btn-link" onClick={redirecLogin}>
                      Login
                    </button>
                    <button className="nav-btn-link-2" onClick={redirecSignup}>
                      Register
                    </button>
                  </>
                )}
              </>
            )}

            {/* <button className="nav-btn-link-2" onClick={redirecSignup}>
              Register
            </button> */}

            {!isHome && (
              <>
                <div
                  className="d-flex align-items-end cursor-pointer"
                  onClick={
                    WhicHome() === "userHome" ? redirectHome : redirectMda
                  }
                >
                  <img src={home} alt="home" className="home-icon" />
                </div>
              </>
            )}

            {!isMdaHome && isHome && (
              <>
                <button
                  className="btn btn-white w-min-110 h-45px rounded-1 border-success"
                  onClick={redirectMda}
                >
                  <span className="text-success fs-8 d-flex">
                    Go To MDA Portal
                  </span>
                </button>
              </>
            )}
          </span>
        </div>
      </nav>

      {/* siderbar */}
      <aside
        className={sidebar ? "sidebar show-sidebar" : "sidebar"}
        id="sidebar"
      >
        <div>
          <button
            className="close-btn"
            id="close-btn"
            onClick={() => setSidebar(!sidebar)}
          >
            <i className="fas fa-times"></i>
          </button>

          <ul className="sidebar-links">
            <li
              onClick={WhicHome() === "userHome" ? redirectHome : redirectMda}
            >
              <Link to={"/"}>Home</Link>
            </li>
            <li onClick={() => setSidebar(!sidebar)}>
              <HashLink to={"/#about"}>About</HashLink>
            </li>
            {/* <li onClick={() => setSidebar(!sidebar)}>
              <Link to={"#"}>Live Chat</Link>
            </li>{" "} */}

            {!isMdaHome && isHome && (
              <li onClick={() => setSidebar(!sidebar)}>
                <Link to={"/mda-home"}> Go To MDA Portal</Link>
              </li>
            )}

            {isMdaHome && (
              <>
                {isUserLoggedIn() ? (
                  <>
                    <li onClick={() => sideBarDash()}>
                      <a> Dashboard</a>
                    </li>
                  </>
                ) : (
                  <>
                    <li onClick={() => redirecLogin()}>
                      <a> Login</a>
                    </li>

                    <li onClick={() => redirecSignup()}>
                      <a> Register</a>
                    </li>
                  </>
                )}
              </>
            )}
          </ul>
          {/*  social icons*/}
          {/* <ul className="social-icons">
            <a href="#" className="social-icon">
              <i className="fab fa-facebook"></i>
            </a>{" "}
            <a href="#" className="social-icon">
              <i className="fab fa-linkedin"></i>
            </a>{" "}
            <a href="#" className="social-icon">
              <i className="fab fa-youtube"></i>
            </a>{" "}
            <a href="#" className="social-icon">
              <i className="fab fa-instagram"></i>
            </a>
          </ul> */}
        </div>
      </aside>
    </div>
  );
};

export default NavBar;
