import { ErrorToast } from "../../app/componets/toast";
import { clearStoredUser } from "../../app/pages/auth/user-storage";
// import { useQueryClient } from "react-query";
// import { queryKeys } from "../../app/react-query/constants";

// export function ErrorHandler(err: any) {
//   //const queryClient = useQueryClient();
//   let message = "";

//   if (err.response) {
//     if (err.response?.status ==== 401) {
//       //Handle the unauthorized error for this specific query
//       clearStoredUser();
//       //queryClient.setQueriesData(queryKeys.user, null);
//       window.location.href = "/auth/login";
//     }
//     const errMessage = err.response.data;
//     message = errMessage.message ? errMessage.message : "Something went wrong.";
//   } else {
//     message = err.message;
//   }
//   ErrorToast(message);
// }

export function ErrorHandler(err: any) {
  //const queryClient = useQueryClient();

  if (err.response) {
    if (err.response.status === 401) {
      //Handle the unauthorized error for this specific query
      // clearStoredUser();
      //queryClient.setQueriesData(queryKeys.user, null);
      ErrorToast(err.response?.data?.message || "Please login to continue");
      // window.location.href = "/auth/login";
    }
    if (err.response.status === 417) {
      ErrorToast(err.response?.data?.message);
    }
    if (err.response.status === 402 || err.response.status === 405) {
      ErrorToast(err.response?.data?.message || err.response.data.detail);
    }
    if (err.response.status === 400) {
      ErrorToast(err.response?.data?.message);
    }
    if (err.response.status === 404) {
      ErrorToast(err?.response?.data?.title || err?.response?.data?.message);
    }
    if (
      err.response.status === 503 ||
      err.response.status === 501 ||
      err.response.status === 504 ||
      err.response.status === 405 ||
      err.response.status === 500 ||
      err.response.status === 403
    ) {
      ErrorToast(
        err.response?.data?.message ||
          "Error in network connection, try later..."
      );
    } else {
      ErrorToast(err.response?.data?.message || "Internal server error");
    }
  } else {
    ErrorToast(err.response?.data?.message || "Network Error!");
  }
}
